import uiHelper from "../pages/module/uiHelper";
import UsersService from "../pages/module/Users.service";
import OrderService from "../services/orderService";
import SyncDeclare from "../SyncDeclare";

export default class OrderController{
    static getHeader(){
        return OrderService.getHeader();
    }
    static getCustomHeader(){
        return OrderService.getCustomHeader(SyncDeclare.ShopVariable.shopid);
    }
    static addHeader(custom:any){
        return OrderService.addHeader(SyncDeclare.ShopVariable.shopid, custom);
    }
}