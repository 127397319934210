import React, { useState, useEffect } from 'react';
import Select from 'react-select';

// Static CSS
import '../App.css';

// Declare Modules
import Assets from './module/Assets';

import uiHelper from './module/uiHelper';
import SyncDeclare from '../SyncDeclare';
import ShopsService from './module/Shops.service';
import TeamsService from './module/Teams.service';
import OrdersService from './module/Orders.service';
import PayoutService from './module/Payout.service';
import { useHistory } from 'react-router-dom';
import { setMultiMediaBuyerList } from '../utils/tools';

function PaymentDashboard() {
  // Static Assets
  const logo = Assets.logo;
  const Shop = SyncDeclare.ShopVariable;
  const skipColumn = ['raw_data','item_cost','ads_spent','shopify_cost','others','updatedAt','createdAt'];
  const history = useHistory();
  const Monthly = getMonthDate();

  // Declare Variable
  let [loading, setLoading]:any = useState('sk-loading');
  let [loadingHistory, setLoadingHistory]:any = useState('sk-loading');
  let [LoadingTransaction, setLoadingTransaction]:any = useState('sk-loading');
  let [ReportProductList, setReportProductList]:any[] = useState([]);
  let [AssignProductToUser, setAssignProductToUser]:any[] = useState([]);
  let [TeamList, setTeamList]:any[] = useState([]);
  let [MultiTeamList, setMultiTeamList]:any[] = useState([]);
  let [ItemList, setItemList]:any[] = useState([]);
  let [UserItemList, setUserItemList]:any[] = useState([]);
  let [TransactionHistory, setTransactionHistory]:any[] = useState([]);
  let [Reports, setReports]:any = useState({});
  let [ReportsHistory, setReportsHistory]:any = useState({});
  let [History, setHistory]:any = useState({});
  let [ReportsTotal, setReportsTotal]:any = useState([]);
  let [RangeDate, setRangeDate]:any = useState('');
  let [RecalculateBtn, setRecalculateBtn]:any = useState(true);
  let [StartDate, setStartDate]:any = useState(Monthly.start);
  let [EndDate, setEndDate]:any = useState(Monthly.end);
  let [TopEmployee,setTopEmployee]:any[] = useState([]);
  let [NetProfit,setNetProfit]:any[] = useState(0);
  let [EmployeeShare,setEmployeeShare]:any[] = useState(0);
  let [SelectedUserForMediaBuyer,setSelectedUserForMediaBuyer]:any[] = useState({});
  let [SelectedUserForOther,setSelectedUserForOther]:any[] = useState({});
  let mainRef:any;

  async function LoadTeamMembers(){
    const teamMembers:any = await ShopsService.teamList(Shop.shopid);
    setTeamList(teamMembers);
    setMultiTeamList(setMultiMediaBuyerList(teamMembers));
  }
  async function LoadItemList(){
    const items:any = await OrdersService.listItems(Shop.shopid);
    setItemList(items);
  }
  async function LoadTransactionHistory(){
    const list = await PayoutService.getTransactionHistory(Shop.shopid);
    setTransactionHistory(list);
  }
  async function LoadReportsByProduct(status:string,startDate:string,endDate:string){
    setLoading('sk-loading');
    SyncDeclare.TemplateHeader('', '', false);
    const ProductList:any = await PayoutService.getReportByProduct(Shop.shopid,status,startDate,endDate);
    setReportProductList(ProductList);
    let formatForMorris = []
    for(let product of ProductList){
        formatForMorris.push({ label: product.item_name, value: product.total })
    }
    uiHelper.morris('morris-donut-chart',formatForMorris);
    setLoading('');
  }
  function FilterCustomSearch(e:any){
    let dom = e.target.parentNode.parentNode;
    let sd = dom.querySelector('#payout_startdate').value;
    let ed = dom.querySelector('#payout_enddate').value;
    LoadReportsByProduct('',sd,ed);
  }
  function SetUserProduct(lists:any){
    let list = [];
    for(let key in lists){
        list.push({label: key, value: key})
    }
    // for(let user of TeamList){
    //     if(user.email === name){
    //         let productList = (user.itemlist || '').split('|');
    //         for(let product of productList){
    //             let match = ItemList.filter((item:any)=>{
    //                 return item.item_name === product;
    //             });
    //             list.push({label: match[0].item_name, value: match[0].item_name})
    //         }
    //     }
    // }
    setUserItemList(list);
  }
  async function CalculateValues(e:any){
    setLoading('sk-loading');
    setRecalculateBtn(true);
    let dom = e.target.parentNode.parentNode;
    let sd = dom.querySelector('#calculate_payout_startdate').value;
    let ed = dom.querySelector('#calculate_payout_enddate').value;
    if(sd!="" && ed!="" && SelectedUserForMediaBuyer.email){
        const name = SelectedUserForMediaBuyer.email || "";
        setRangeDate(sd+'|'+ed);
        SetUserProduct(name);
        await GetDatabaseValues('',name,sd,ed);
    }else{
        uiHelper.toastr.error("Please provide the following fields.")
    }
    setLoading('');
  }
  function ReCalculateValues(e:any){
    e.preventDefault();
    let dom = e.target;
    let label = dom.querySelector('#payout_label').value;
    let displaycost = dom.querySelector('#payout_display_cost').value;
    let itemcost = dom.querySelector('#payout_item_cost').value;
    let adspent = dom.querySelector('#payout_adspent').value;
    let shopify = dom.querySelector('#payout_shopify_cost').value;
    let others = dom.querySelector('#payout_others').value;
    let bill_date = dom.querySelector('#payout_bill_date').value;
    let share_percent = dom.querySelector('#payout_share_percent').value;
    let productsRequired = 0;
    let productCostRequired = 0;
    for(let products in Reports.item_total){
        if(displaycost.includes(products)){
            productCostRequired++;
        }
        productsRequired++;
    }
    if(AssignProductToUser.length>0 && itemcost!="" && adspent!="" && shopify!="" && others!="" && productCostRequired === productsRequired){
        let reports = Reports;
        reports.label = label;
        reports.item_cost = displaycost;
        reports.display_cost = displaycost;
        reports.adspent = adspent;
        reports.shopifycost = shopify;
        reports.others = others;
        reports.billed_date = bill_date;
        reports.share_percent = share_percent;
        AssignToFields(reports);
        setRecalculateBtn(false);
    }else{
        uiHelper.toastr.error("Please provide the following fields.")
    }
  }
  async function GetReportsWithPaid(product:string, email:string, startdate:string, enddate:string){
    let reports:any = await PayoutService.getReportsWithPaid(Shop.shopid,product,email,startdate, enddate);
    LoadBarChart(reports[0],reports[1]);
    setReportsTotal(reports[0]);
    return true;
  }
  async function GetDatabaseValues(product:string, email:string, startdate:string, enddate:string){
    // let reports:any = await OrdersService.reportsShare(Shop.shopid,product,email,startdate, enddate);
    let reports:any = await PayoutService.calculate(Shop.shopid,product,email,startdate, enddate);
    reports.email = email;
    AssignToFields(reports);
    SetUserProduct(reports.item_total);
    await GetReportsWithPaid('',email,startdate,enddate);
    return true;
  }
  function LoadBarChart(reports1:any, reports2:any){
    let format1 = PrepareValueForBarChart(reports1);
    let format2 = PrepareValueForBarChart(reports2);
    let format2New = [];
    for(let data of format1.labels){
        let column = format2.labels;
        let values = format2.values;
        let index = column.indexOf(data);
        if(index>=0){
            format2New.push(values[index]);
        }else{
            format2New.push(0);
        }
    }
    let dom:any = DomDashboard();
    uiHelper.barChart2(dom.querySelector('#barChart'), format1.labels, format1.values, format2New);
  }
  function PrepareValueForBarChart(reports:any[]){
    let labels = [];
    let values = [];
    for(let obj of reports){
        if(obj.order_status){
            labels.push(obj.order_status);
            values.push(obj.order_total);
        }
    }
    return {labels,values};
}
  function AssignToFields(reports:any){
    let productList = (reports.display_cost || '').split('|');
    let sum = 0, rts_sum = 0;
    for(let key in reports.item_total){
        let itemTotal = reports.item_total;
        let filter = productList.filter((item:any)=>{
            return ('|'+item).includes('|'+key+':');
        });
        if(filter.length>0){
            sum += Number(('|'+filter[0]).replace('|'+key+':','')) * itemTotal[key];
        }
    }
    for(let key in reports.item_rts_total){
        let itemTotal = reports.item_rts_total;
        let filter = productList.filter((item:any)=>{
            return ('|'+item).includes('|'+key+':');
        });
        if(filter.length>0){
            rts_sum += Number(('|'+filter[0]).replace('|'+key+':','')) * itemTotal[key];
        }
    }
    reports.rts_rate_percent = Math.floor(reports.rts_rate * 100);
    reports.product_cost = sum;
    reports.rts_rate_cost = rts_sum*reports.rts_rate;
    reports.net_profit = CalculateNetProfit(
        [
            reports.delivered_value || 0
        ],
        [
            reports.delivered_shipping_cost || 0,
            reports.cod_fee || 0,
            reports.vat_fee || 0,
            reports.returned_shipping_cost || 0,
            reports.product_cost,
            reports.adspent || 0
        ]
    );
    reports.share_percent = (reports.share_percent || 20)/100; //reports.net_profit > 400000 ? 0.2 : 0.15;
    reports.share = ((reports.net_profit) * reports.share_percent) - reports.rts_rate_cost - (reports.shopifycost || 0) - (reports.others || 0);
    setReports(reports);
    let dom:any = DomDashboard();
    dom.querySelector('#payout_item_cost').value = sum;
    dom.querySelector('#payout_product_cost').value = currencyFormat(reports.product_cost);
    dom.querySelector('#payout_net_profit').value = currencyFormat(reports.net_profit);
    dom.querySelector('#payout_share').value = currencyFormat(reports.share);
    dom.querySelector('#share_percent').innerHTML = (reports.share_percent*100);
  }
  function CalculateNetProfit(reportsPositive:any, reportsNegative:any){
    let sum_of_positive = 0;
    let sum_of_negative = 0;
    if(reportsPositive.length>0 && reportsNegative.length>0){
        for(let value of reportsPositive){
            sum_of_positive+= Number(value);
        }
        for(let value of reportsNegative){
            sum_of_negative+= Number(value);
        }
    }
    return sum_of_positive - sum_of_negative;
  }
  async function submiValues(){
    setLoading('sk-loading');
    let dom:any = document.querySelector('#PayoutDashboard')
    let sd = dom.querySelector('#calculate_payout_startdate').value;
    let ed = dom.querySelector('#calculate_payout_enddate').value;
    if(Reports.label && Reports.label.length>0){
        if(Reports.delivered_value>0){
            Reports.overalltotal = ReportsTotal;
            const response:any = await PayoutService.saveReports(Shop.shopid,Reports.label,Reports.item_cost,Reports.adspent,Reports.shopifycost,Reports.others,Reports,Reports.email,sd,ed,Reports.billed_date);
            if(response.status == 'success'){
                await GetDatabaseValues('',Reports.email,sd,ed);
                uiHelper.toastr.success(response.message)
            }else{
                uiHelper.toastr.error(response.message)
            }
        }else{
            uiHelper.toastr.error('Unable to process this payout.')
        }
    }else{
        uiHelper.toastr.error('Please provide the following fields.')
    }
    setLoading('');
  }
  async function submiValuesOther(){
    setLoading('sk-loading');
    let dom:any = document.querySelector('#PayoutDashboard')
    let date = dom.querySelector('#other_calculate_date').value;
    // let emailIndex = dom.querySelector('#other_mediabuyer').value;
    let label = dom.querySelector('#other_payout_label').value;
    let share = dom.querySelector('#other_payout_amount').value;
    let additional = dom.querySelector('#other_payout_additional').value;
    let expenses = dom.querySelector('#other_payout_expenses').value;
    if(label && label.length>0){
        if(share>0){
            let NewReport:any = {
                share: share,
                others: expenses,
                additional_amount: additional,
                label: label,
                email: setSelectedUserForOther.email || ""
            }
            const response:any = await PayoutService.saveReportsOthers(Shop.shopid,label,(NewReport),NewReport.email,date,date);
            if(response.status == 'success'){
                uiHelper.toastr.success(response.message)
            }else{
                uiHelper.toastr.error(response.message)
            }
        }else{
            uiHelper.toastr.error('Unable to process this payout.')
        }
    }else{
        uiHelper.toastr.error('Please provide the following fields.')
    }
    setLoading('');
  }
  function SetProductUser(e:any){
    const dom:any = DomDashboard();
    let items = [];
    for(let product of e){
        items.push(product.label+':'+product.amount);
    }
    dom.querySelector('#payout_display_cost').value = items.join('|');
    setAssignProductToUser(e);
  }
  function CheckProductUser(reports:any){
    let lists = [];
    let _itemList = reports.item_total || [];
    let _itemcostList = (reports.item_cost || '').split('|');
    for(let key in _itemList){
        let filter = _itemcostList.filter((item:any)=>{
            return item.includes(key);
        });
        lists.push({
            name: key,
            total: _itemList[key], 
            cost: Number((filter[0] || '0').replace(key+':',''))
        })
    }
    SyncDeclare.PayoutMediaBuyer(lists);
  }
  function CheckProductUserForRTS(reports:any){
    let lists = [];
    let _itemList = reports.item_rts_total || [];
    let _itemcostList = (reports.item_cost || '').split('|');
    for(let key in _itemList){
        let filter = _itemcostList.filter((item:any)=>{
            return item.includes(key);
        });
        lists.push({
            name: key,
            total: _itemList[key], 
            cost: Number((filter[0] || '0').replace(key+':',''))
        })
    }
    SyncDeclare.PayoutMediaBuyer(lists,'RTS');
  }
  function GoToSOA(e:any){
    history.push("/payout-soa");
  }
  async function Export(transid:any){
    setLoading('sk-loading');
    const response:any = await ShopsService.exportOrderPaidBy(transid);
    uiHelper.toastr.info('Downloading '+response.file);
    window.open('https://admin.tumia.shop/client/exports/'+response.file);
    setLoading('');
  }
  function currencyFormat(num:any) {
    return 'PHP ' + (Number(num || 0).toFixed(2)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  function FormatRow(row:any){
    let formatRow = [];
    for(const column in row){
        if(skipColumn.indexOf(column)<0){
            if(['createdAt','updatedAt'].indexOf(column)>=0){
                formatRow.push({column, value: row[column] != null ? uiHelper.format.fullDateWithTime(row[column]) : ""});
            }else{
                formatRow.push({column, value: row[column]});
            }
        }
    }
    return formatRow;
  }
  function FormatHeaders(row:any){
    let formatRow = [];
    if(row.length>0){
        let row1 = row[0]
        for(const column in row1){
            if(skipColumn.indexOf(column)<0){
                formatRow.push(column);
            }
        }
    }
    return formatRow;
  }
  function View(transaction:any){
    setLoadingHistory('sk-loading');
    setReportsHistory(JSON.parse(transaction.raw_data || '{}'));
    setHistory(transaction);
    const dom:any = DomDashboard();
    dom.querySelector('#bill_date').value = transaction.billed_date || '';
    setLoadingHistory('');
  }
  async function UpdatePayout(e:any){
    const domParent:any = DomDashboard();
    if(domParent){
        const billdate = domParent.querySelector('#bill_date').value;
        const response:any = await PayoutService.update(History.payout_transid, billdate);
        if(response.status == 'success'){
            LoadTransactionHistory();
            uiHelper.toastr.success(response.message);
        }else{
            uiHelper.toastr.error(response.message);
        }
    }

  }
  function getMonthDate(){
    const today = new Date();
    const start = new Date(today.getFullYear(),today.getMonth(),1);
    const end = new Date(today.getFullYear(),today.getMonth()+1,0);
    return {start:uiHelper.format.fullDate(start), end: uiHelper.format.fullDate(end)};
  }
  async function LoadTopEarner(e:any){
    setLoadingTransaction('sk-loading');
    const response:any = await PayoutService.getHistoryList(Shop.shopid,StartDate,EndDate);
    setTopEmployee(response);
    let netProfit = 0;
    let employeeShare = 0;
    for(let list of response){
        netProfit+=list.net_profit;
        employeeShare+=list.share;
    }
    setNetProfit(netProfit);
    setEmployeeShare(employeeShare);
    setLoadingTransaction('');
  }
  function LoadMultipleSelect(){
    const dom:any = DomDashboard();
    uiHelper.multipleSelect(dom.querySelector('.select2_demo_2'))
  }
  function DomDashboard(){
    return document.querySelector('#PayoutDashboard');
  }
  // Declare Hooks
  useEffect(() => {
    // verify id
    if(SyncDeclare.ShopVariable.shopid==0){
      window.location.href="/payout";
    }
    // Load Function Once
    LoadTeamMembers();
    LoadItemList();
    LoadTransactionHistory();
    LoadTopEarner({});
    // LoadMultipleSelect()
    // LoadReportsByProduct('','','');
    setTimeout(()=>{
        SyncDeclare.TemplateHeader('', '', true);
    },100)
  }, []);
  // Template
  return (
    <div className="" id="PayoutDashboard" ref={(node:any)=> mainRef = node}>
    {/* start */}
    <div className='row'>
        <div className="col-lg-12">
            <div className="tabs-container sub-navigation">
                <ul className="nav nav-tabs">
                    <li className="active"><a data-toggle="tab" href="#tab-1" aria-expanded="true">Transaction</a></li>
                    <li className=""><a data-toggle="tab" href="#tab-2" aria-expanded="true">Media Buyer</a></li>
                    <li className=""><a data-toggle="tab" href="#tab-3" aria-expanded="true">Other</a></li>
                    <li className=""><a data-toggle="tab" href="#tab-4" aria-expanded="false">History</a></li>
                </ul>
                <div className="tab-content">
                    <div id="tab-1" className="tab-pane active">
                        <div className="panel-body">
                            {/* start */}
                            <div className='row mg-25'>
                                <div className="col-sm-3">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-title">
                                            <h5>Filter</h5>
                                        </div>
                                        <div className='ibox-content'>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label>Date Start</label>
                                                        <input type="date" id="calculate_startdate" value={StartDate} onChange={(e)=>setStartDate(e.target.value)} name="start_date" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Date End</label>
                                                        <input type="date" id="calculate_enddate" value={EndDate} onChange={(e)=>setEndDate(e.target.value)} name="end_date" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>&nbsp;</label>
                                                        <button type="button" className="btn btn-primary" onClick={LoadTopEarner}> <i className="fa fa-check-circle"></i> Search</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-title">
                                            <span className="label label-info pull-right">Profit</span>
                                            <h5>Net Profit</h5>
                                        </div>
                                        <div className="ibox-content">
                                            <h2 className="no-margins">{currencyFormat(NetProfit)}</h2>
                                            <small>{StartDate} - {EndDate}</small>
                                        </div>
                                    </div>
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-title">
                                            <span className="label label-info pull-right">Employee</span>
                                            <h5>Share</h5>
                                        </div>
                                        <div className="ibox-content">
                                            <h2 className="no-margins">{currencyFormat(EmployeeShare)}</h2>
                                            <small>{StartDate} - {EndDate}</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-9">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-title">
                                            <h5>History</h5>
                                        </div>
                                        <div className={`ibox-content ${LoadingTransaction}`}>
                                            <div className="sk-spinner sk-spinner-wave">
                                                <div className="sk-rect1"></div>
                                                <div className="sk-rect2"></div>
                                                <div className="sk-rect3"></div>
                                                <div className="sk-rect4"></div>
                                                <div className="sk-rect5"></div>
                                                Loading
                                            </div>
                                            <table className="table table-striped">
                                                <thead>
                                                <tr key={-1}>
                                                    <th key={-1}>#</th>
                                                    <th>Name</th>
                                                    <th>Group</th>
                                                    <th>Net Profit</th>
                                                    <th>Share</th>
                                                    <th>Billed Date</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    TopEmployee.map((employee:any,employeeKey:number)=>{
                                                        return(
                                                            <tr>
                                                                <td>{employeeKey+1}</td>
                                                                <td>{employee.name}</td>
                                                                <td>{employee.group}</td>
                                                                <td className="text-navy">{currencyFormat(employee.net_profit)}</td>
                                                                <td className="text-navy">{currencyFormat(employee.share)}</td>
                                                                <td>{employee.billed_date}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end */}
                        </div>
                    </div>
                    <div id="tab-2" className="tab-pane">
                        <div className="panel-body">
                            {/* start */}
                            <div className="row mg-25">
                                <div className="col-sm-4">
                                    {/* start ibox */}
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-title">
                                            <h5>Payout Information</h5>
                                            <span className='pull-right'><a onClick={GoToSOA}>Go to SOA</a></span>
                                        </div>
                                        <div className="ibox-content">

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label>Date Start</label>
                                                        <input type="date" id="calculate_payout_startdate" name="start_date" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Date End</label>
                                                        <input type="date" id="calculate_payout_enddate" name="end_date" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Media Buyer</label>
                                                        <Select
                                                            onChange={(e:any,event:any)=>{
                                                                setSelectedUserForMediaBuyer(e.value);
                                                            }}
                                                            isMulti={false}
                                                            options={MultiTeamList} 
                                                            id="calculate_mediabuyer"
                                                            className='forward-div'
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>&nbsp;</label>
                                                        <button type="button" className="btn btn-primary" onClick={CalculateValues}> <i className="fa fa-check-circle"></i> Calculate</button>
                                                    </div>
                                                </div>
                                        </div>
                                        </div>
                                    </div>
                                    {/* end ibox */}
                                    {/* start ibox */}
                                    <div className="ibox float-e-margins">

                                        <div className={`ibox-content ${loading}`}>
                                            <div className="sk-spinner sk-spinner-wave">
                                                <div className="sk-rect1"></div>
                                                <div className="sk-rect2"></div>
                                                <div className="sk-rect3"></div>
                                                <div className="sk-rect4"></div>
                                                <div className="sk-rect5"></div>
                                                Loading
                                            </div>
                                            <div className="row">
                                                <div className='col-sm-12' style={{height: "250px"}}>
                                                    <canvas id="barChart" height="100"></canvas>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end ibox */}
                                </div>
                                <div className="col-sm-8">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-title">
                                            <h5>Payout Information</h5>
                                        </div>
                                        <div className={`ibox-content ${loading}`}>
                                            <div className="sk-spinner sk-spinner-wave">
                                                <div className="sk-rect1"></div>
                                                <div className="sk-rect2"></div>
                                                <div className="sk-rect3"></div>
                                                <div className="sk-rect4"></div>
                                                <div className="sk-rect5"></div>
                                                Loading
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 untouch">
                                                    <div className="form-group">
                                                        <label>Delivered Value:</label>
                                                        <input type="text" id="payout_delivered_value" value={currencyFormat(Reports.delivered_value)} disabled name="delivered_value" className="form-control"/>
                                                    </div>
                                                    <div className="form-group ">
                                                        <label>Delivered Shipping Cost:</label>
                                                        <input type="text" id="payout_delivered_shipping_cost" value={currencyFormat(Reports.delivered_shipping_cost)} disabled name="delivered_shipping_cost" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>COD Fee:</label>
                                                        <input type="text" id="payout_cod_fee" value={currencyFormat(Reports.cod_fee)} disabled name="cod_fee" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>VAT Fee:</label>
                                                        <input type="text" id="payout_vat_fee" value={currencyFormat(Reports.vat_fee)} disabled name="vat_fee" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Returned Shipping Cost:</label>
                                                        <input type="text" id="payout_returned_shipping_cost" value={currencyFormat(Reports.returned_shipping_cost )} disabled name="returned_shipping_cost" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Product Cost:</label>
                                                        <input type="text" id="payout_product_cost" value={currencyFormat(Reports.product_cost)} disabled name="product_cost" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Net Profit:</label>
                                                        <input type="text" id="payout_net_profit" value={currencyFormat(Reports.net_profit)} disabled name="net_profit" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Share(<span id="share_percent">20</span>%): <i className="fa fa-question-circle" data-toggle="modal" data-target="#payout_formula"></i></label>
                                                        <input type="text" id="payout_share" value={currencyFormat(Reports.share)} disabled name="share" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>RTS Rate (<span className={Reports.rts_rate_percent < 30 ? "text-navy" : "text-warning"}>{Reports.rts_rate_percent || 0}%</span>): &nbsp;
                                                        <small className="text-warning">{Reports.rts_rate_percent}% of the total RTS</small>
                                                        &nbsp;<i className="fa fa-question-circle" onClick={()=>CheckProductUserForRTS(Reports)} data-toggle="modal" data-target="#payout_mediabuyer_itemcost"></i>
                                                        </label>
                                                        <input type="text" id="payout_share" value={currencyFormat(Reports.rts_rate_cost)} disabled name="share" className="form-control"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 untouch">
                                                    <form onSubmit={ReCalculateValues}>
                                                        <div className="form-group">
                                                            <label>Label Name:</label>
                                                            <input type="text" id="payout_label" name="payout_label" className="form-control"/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Product Item:</label>
                                                            <Select
                                                                onChange={(e:any,event:any)=>{
                                                                    if(event.action === "select-option"){
                                                                        let amount:any = window.prompt('Enter the amount for '+e[e.length-1].label);
                                                                        let val = 0;
                                                                        if(!isNaN(Number(amount))){
                                                                            val = Number(amount);
                                                                        }
                                                                        e[e.length-1].amount = val;
                                                                    }
                                                                    SetProductUser(e);
                                                                }}
                                                                isMulti={true}
                                                                options={UserItemList}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Display Cost: <i className="fa fa-question-circle" onClick={()=>CheckProductUser(Reports)} data-toggle="modal" data-target="#payout_mediabuyer_itemcost"></i></label>
                                                            <input type="text" id="payout_display_cost" disabled name="payout_item_cost" className="form-control"/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Item Cost:</label>
                                                            <input type="text" id="payout_item_cost" disabled  value={(Reports.product_cost)} name="payout_item_cost" className="form-control"/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>FB Ad Spent:</label>
                                                            <input type="text" id="payout_adspent" name="start_date" className="form-control"/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>LadiPage Cost:</label>
                                                            <input type="text" id="payout_shopify_cost" name="end_date" className="form-control"/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Other Expenses:</label>
                                                            <input type="text" id="payout_others" name="end_date" className="form-control"/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Share Percent:</label>
                                                            <input type="number" id="payout_share_percent" name="share_percent" className="form-control"/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Bill Date:</label>
                                                            <input type="month" id="payout_bill_date" name="bill_date" className="form-control"/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>&nbsp;</label>
                                                            <button type="submit" className="btn btn-primary"> <i className="fa fa-check-circle"></i> Recalculate</button>
                                                            <button type="button" className="btn btn-primary pull-right" onClick={submiValues} disabled={RecalculateBtn}> <i className="fa fa-check-circle"></i> Submit</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end */}
                        </div>
                    </div>
                    <div id="tab-3" className="tab-pane">
                        <div className="panel-body">
                            {/* start */}
                            <div className="row mg-25">
                                <div className="col-sm-8">
                                    {/* start ibox */}
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-title">
                                            <h5>Payout Information</h5>
                                        </div>
                                        <div className="ibox-content">

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label>Date</label>
                                                        <input type="month" id="other_calculate_date" name="start_date" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Media Buyer</label>
                                                        <Select
                                                            onChange={(e:any,event:any)=>{
                                                                setSelectedUserForOther(e.value);
                                                            }}
                                                            isMulti={false}
                                                            options={MultiTeamList} 
                                                            id="other_mediabuyer"
                                                            className='forward-div'
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Label Name:</label>
                                                        <input type="text" id="other_payout_label" name="other_payout_label" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Payroll Amount:</label>
                                                        <input type="text" id="other_payout_amount" name="other_payout_amount" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Additional Payment:</label>
                                                        <input type="text" id="other_payout_additional" name="other_payout_additional" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Other Expenses:</label>
                                                        <input type="text" id="other_payout_expenses" name="other_payout_expenses" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>&nbsp;</label>
                                                        <button type="button" className="btn btn-primary pull-right" onClick={submiValuesOther}> <i className="fa fa-check-circle"></i> Submit</button>
                                                    </div>
                                                </div>
                                        </div>
                                        </div>
                                    </div>
                                    {/* end ibox */}
                                </div>
                            </div>
                            {/* end */}
                        </div>
                    </div>
                    <div id="tab-4" className="tab-pane">
                        <div className="panel-body">
                            {/* start */}
                            <div className='row mg-25'>
                                <div className="col-sm-6">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-title">
                                            <h5>Payout History</h5>
                                            <div className="ibox-tools">
                                                <button className="btn btn-primary btn-xs" onClick={(e)=>LoadTransactionHistory()}>Refresh</button>
                                            </div>
                                        </div>
                                        <div className="ibox-content custom-table">
                                            <table className="table table-striped">
                                                <thead>
                                                <tr key={-1}>
                                                    <th key={-1}>#</th>
                                                    <th>Transaction #</th>
                                                    <th>Name</th>
                                                    <th>Label</th>
                                                    <th>Date Range</th>
                                                    <th>Billed Date</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        TransactionHistory.map((order:any, transactionIndex:any)=>{
                                                            return(
                                                                <tr key={transactionIndex}>
                                                                    <td>{(transactionIndex+1)}</td>
                                                                    {
                                                                        FormatRow(order).map((object:any, i:any)=>{
                                                                            return(
                                                                            <td data-toggle="tooltip" data-placement="left" title={object.column} key={i}>
                                                                                {
                                                                                    object.column == 'payout_transid'?
                                                                                    <a href={"https://admin.tumia.shop/client/billing/?id="+object.value} target='blank'>{object.value}</a>
                                                                                    :object.value
                                                                                }
                                                                            </td>
                                                                            )
                                                                        })
                                                                    }
                                                                    <td>
                                                                        <button className='btn btn-outline btn-primary' onClick={()=>View(order)}>View</button>&nbsp;
                                                                        <button className='btn btn-outline btn-info' onClick={()=>Export(order.payout_transid)}>Export</button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-title">
                                            <h5>Transaction Information</h5>
                                        </div>
                                        <div className={`ibox-content ${loadingHistory}`}>
                                            <div className="sk-spinner sk-spinner-wave">
                                                <div className="sk-rect1"></div>
                                                <div className="sk-rect2"></div>
                                                <div className="sk-rect3"></div>
                                                <div className="sk-rect4"></div>
                                                <div className="sk-rect5"></div>
                                                Loading
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 untouch">
                                                    <div className="form-group">
                                                        <label>Delivered Value:</label>
                                                        <input type="text" id="payout_delivered_value" value={currencyFormat(ReportsHistory.delivered_value)} disabled name="delivered_value" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Delivered Shipping Cost:</label>
                                                        <input type="text" id="payout_delivered_shipping_cost" value={currencyFormat(ReportsHistory.delivered_shipping_cost)} disabled name="delivered_shipping_cost" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>COD Fee:</label>
                                                        <input type="text" id="payout_cod_fee" value={currencyFormat(ReportsHistory.cod_fee)} disabled name="cod_fee" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>VAT Fee:</label>
                                                        <input type="text" id="payout_vat_fee" value={currencyFormat(ReportsHistory.vat_fee)} disabled name="vat_fee" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Returned Shipping Cost:</label>
                                                        <input type="text" id="payout_returned_shipping_cost" value={currencyFormat(ReportsHistory.returned_shipping_cost )} disabled name="returned_shipping_cost" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Product Cost:</label>
                                                        <input type="text" id="payout_product_cost" value={currencyFormat(ReportsHistory.product_cost)} disabled name="product_cost" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Net Profit:</label>
                                                        <input type="text" id="payout_net_profit" value={currencyFormat(ReportsHistory.net_profit)} disabled name="net_profit" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Share({(ReportsHistory.share_percent || 0.2)*100}%): <i className="fa fa-question-circle" data-toggle="modal" data-target="#payout_formula"></i></label>
                                                        <input type="text" id="payout_share" value={currencyFormat(ReportsHistory.share)} disabled name="share" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>RTS Rate (<span className={ReportsHistory.rts_rate_percent < 30 ? "text-navy" : "text-warning"}>{ReportsHistory.rts_rate_percent || 0}%</span>): &nbsp;
                                                        <small className="text-warning">{Reports.rts_rate_percent}% of the total RTS</small>
                                                        &nbsp;<i className="fa fa-question-circle" onClick={()=>CheckProductUserForRTS(ReportsHistory)} data-toggle="modal" data-target="#payout_mediabuyer_itemcost"></i>
                                                        </label>
                                                        <input type="text" id="payout_share" value={currencyFormat(ReportsHistory.rts_rate_cost)} disabled name="share" className="form-control"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 untouch">
                                                    <div className="form-group">
                                                        <label>Label Name:</label>
                                                        <input type="text" id="payout_label" disabled value={ReportsHistory.label || ""} name="payout_label" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Item Cost: <i className="fa fa-question-circle" onClick={()=>CheckProductUser(ReportsHistory)} data-toggle="modal" data-target="#payout_mediabuyer_itemcost"></i></label>
                                                        <input type="text" id="payout_item_cost" disabled value={(ReportsHistory.item_cost)} name="end_date" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>FB Ad Spent:</label>
                                                        <input type="text" id="payout_adspent" disabled value={currencyFormat(ReportsHistory.adspent)} name="start_date" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Shopify Cost:</label>
                                                        <input type="text" id="payout_shopify_cost" disabled value={currencyFormat(ReportsHistory.shopifycost)} name="end_date" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Other Expenses:</label>
                                                        <input type="text" id="payout_others" disabled value={currencyFormat(ReportsHistory.others)} name="end_date" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Bill Date:</label>
                                                        <input type="month" id="bill_date" name="bill_date" className="form-control"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <button type="submit" className="btn btn-primary" onClick={UpdatePayout}> <i className="fa fa-check-circle"></i> Update</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end */}
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
    {/* end */}
      
      
    </div>
  );
}

export default PaymentDashboard;
