import React, { useState, useEffect } from 'react';

// Static CSS
import '../App.css';

// Declare Modules
import Assets from './module/Assets';

import SyncDeclare from '../SyncDeclare';
import uiHelper from './module/uiHelper';
import UsersService from './module/Users.service';
import ShopsService from './module/Shops.service';
import OrdersService from './module/Orders.service';
import PayoutService from './module/Payout.service';
import ProductsService from './module/Products.service';
import UserController from '../controller/userController';

function DashboardV1() {
  // Static Assets
  const logo = Assets.logo;
  const skipColumn = ['id','shopid','creator_code'];
  const MaxRows = 1000;
  const Monthly = getMonthDate();

  // Declare Variable
  let [TotalList, setTotalList]:any = useState(0);
  let [PageNumber, setPageNumber]:any = useState(0);
  let [UploadState, setUploadState]:any = useState('');
  let [UploadDate, setUploadDate]:any = useState('');
  let [PaginationList, setPaginationList]:any[] = useState([]);
  let [SearchBy, setSearchBy]:any = useState('');
  let [Search, setSearch]:any = useState('');
  let [SearchProduct, setSearchProduct]:any = useState('');
  let [FilterStatus, setFilterStatus]:any = useState('');
  let [StartDate, setStartDate]:any = useState(Monthly.start);
  let [EndDate, setEndDate]:any = useState(Monthly.end);
  let [Shop, setShop]:any = useState(SyncDeclare.ShopVariable);
  let [Data,SetData]:any[] = useState([]);
  let [ShopList,setShopList]:any[] = useState([]);
  let [ProductList,setProductList]:any[] = useState([]);
  let [StatusList,setStatusList]:any[] = useState([]);
  let [OrderList,setOrderList]:any[] = useState([]);
  let [TopProductList,setTopProductList]:any[] = useState([]);
  let [TopEmployee,setTopEmployee]:any[] = useState([]);
  let [UserActivity,setUserActivity]:any[] = useState([]);
  let [mainDom, setMainDom]:any = useState();
  let mainRef:any;
  let [TriggerOnce,SetTriggerOnce] = useState(true);

  function OpenSideBar(id:string){
    let scholar = Data.filter(function(s:any){return s.id === id});
    SyncDeclare.SideBar.LoadData(scholar[0]);
    // mainRef.
  }
  async function LoadUser(){
    // const users = new Users();
    // const users = await UsersService.getMe();
    LoadShops();
    LoadUserActivity();
  }
  async function LoadUserActivity(){
    const response = await UserController.activity();
    setUserActivity(response);
  }
  async function LoadShops(){
    SyncDeclare.TemplateHeader('', '',false);
    setUploadState('sk-loading');
    const list:any = await ShopsService.list();
    setShopList(list);
    if(list.length>0){
      let shop = list[0];
      setShop(shop);
      await LoadProductList(Shop.shopid);
      await getLastUploadDate(Shop.shopid);
      await LoadReports(Shop,StartDate,EndDate);
      await LoadOrdersList(0,Shop.shopid);
    //   await TotalOrders(Shop.shopid);
      await LoadTopProducts(Shop.shopid);
      await LoadTopEarner(Shop.shopid);
    }
    setUploadState('');
  }
  async function LoadReports(shop:any,startDate:any, endDate:any){
    const total:any = await OrdersService.getReports(shop.shopid, '', startDate, endDate);
    const reportsTotal:any = await OrdersService.getReportsTotal(shop.shopid,'','', '');
    const reports:any = await PayoutService.getReportsWithPaid(shop.shopid,'','',startDate, endDate);
    let labels = [];
    let values = [];
    for(let obj of reportsTotal){
        if(obj.order_status){
            labels.push(obj.order_status);
            values.push(obj.order_total);
        }
    }
    setStatusList(labels);
    let dom:any = document;
    dom.querySelector('#dashboard_delivered_value').innerHTML = (total.delivered_value || 0).toFixed(2);
    dom.querySelector('#dashboard_delivered_shipping_cost').innerHTML = (total.delivered_shipping_cost || 0).toFixed(2);
    dom.querySelector('#dashboard_cod_fee').innerHTML = (total.cod_fee || 0).toFixed(2);
    dom.querySelector('#dashboard_vat_fee').innerHTML = (total.vat_fee || 0).toFixed(2);
    dom.querySelector('#dashboard_returned_shipping_cost').innerHTML = (total.returned_shipping_cost || 0).toFixed(2);
    LoadBarChart(reports[0],reports[1]);
    // uiHelper.barChart(dom.querySelector('#dashboard_barChart'), labels, values);
    return true;
  }
  async function LoadOrdersList(pageIndex:Number, shopid:Number){
    setOrderList([]);
    const orders:any = await OrdersService.listByUser('', FilterStatus, SearchBy, Search, shopid, pageIndex, MaxRows, StartDate, EndDate, 'true');
    if(orders.length>0){
      setOrderList(orders);
    }
    return true;
  }
  async function LoadProductList(shopid:Number){
    const items:any = await OrdersService.listItemsByUser(shopid);
    setProductList(items);
  }
  async function getLastUploadDate(shopid:Number){
    const uploadDate:any = await ShopsService.getUploadDate(shopid);
    if(uploadDate.length>0){
      setUploadDate(uiHelper.format.fullDateWithTime(uploadDate));
    }else{
      setUploadDate('');
    }
  }
  async function FilteredSearch(e:any){
    setUploadState('sk-loading');
    setPageNumber(0);
    const dom:any = document;
    const startDate = dom.getElementById('filter_startdate').value || '';
    const endDate = dom.getElementById('filter_enddate').value || '';
    await LoadReports(Shop,startDate,endDate);
    await LoadOrdersList(0,Shop.shopid);
    // await TotalOrders(Shop.shopid)
    await LoadTopProducts(Shop.shopid);
    await LoadTopEarner(Shop.shopid);
    setUploadState('');
  }
  async function TotalOrders(shopid:Number){
    const total:any = await OrdersService.listTotalByUser('','', FilterStatus, SearchBy, Search, shopid, StartDate, EndDate);
    setTotalList(total);
    let List = [];
    let pages = (total/MaxRows);
    for(let page = 0; page<(pages);page++){
        List.push(page);
    }
    setPaginationList(List);
    return true;
  }
  async function Pagination(status:string, value?:any){
    setUploadState('sk-loading');
    let page = 0;
    if(status=='next'){
        page = parseInt(PageNumber) + 1;
        if(page<PaginationList.length){
            setPageNumber(page);
        }else{
            page = PageNumber;
        }
        await LoadOrdersList(page*MaxRows,Shop.shopid);
    }else if(status=='prev'){
        page = 0;
        if(PageNumber>=0){
            page = PageNumber - 1;
            setPageNumber(page);
        }
        await LoadOrdersList(page*MaxRows,Shop.shopid);
    }else if(status=='selected'){
        setPageNumber(value);
        page = parseInt(value);
    }
    await LoadOrdersList(page*MaxRows,Shop.shopid);
    setUploadState('');
  }
  function FormatRow(row:any){
    let formatRow = [];
    for(const column in row){
        if(skipColumn.indexOf(column)<0){
            if(['createdAt','updatedAt'].indexOf(column)>=0){
                formatRow.push({column, value: row[column] != null ? uiHelper.format.fullDateWithTime(row[column]) : ""});
            }else{
                formatRow.push({column, value: row[column]});
            }
        }
    }
    return formatRow;
  }
  function FormatHeaders(row:any){
    let formatRow = [];
    if(row.length>0){
        let row1 = row[0]
        for(const column in row1){
            if(skipColumn.indexOf(column)<0){
                formatRow.push(column);
            }
        }
    }
    return formatRow;
  }
  function getMonthDate(){
    const today = new Date();
    const start = new Date(today.getFullYear(),today.getMonth(),1);
    const end = new Date(today.getFullYear(),today.getMonth()+1,0);
    return {start:uiHelper.format.fullDate(start), end: uiHelper.format.fullDate(end)};
  }
  function LoadBarChart(reports1:any, reports2:any){
    let format1 = PrepareValueForBarChart(reports1);
    let format2 = PrepareValueForBarChart(reports2);
    let format2New = [];
    for(let data of format1.labels){
        let column = format2.labels;
        let values = format2.values;
        let index = column.indexOf(data);
        if(index>=0){
            format2New.push(values[index]);
        }else{
            format2New.push(0);
        }
    }
    let dom:any = document.querySelector('#Dashboard');
    uiHelper.barChart2(dom.querySelector('#dashboard_barChart'), format1.labels, format1.values, format2New);
  }
  async function LoadTopProducts(shopid: Number){
    const response:any = await ProductsService.totalList(shopid,StartDate, EndDate);
    setTopProductList(response);
    
  }
  async function LoadTopEarner(shopid:Number){
    const response = await PayoutService.getHistoryList(shopid,StartDate,EndDate);
    setTopEmployee(response);
  }
  function PrepareValueForBarChart(reports:any[]){
    let labels = [];
    let values = [];
    for(let obj of reports){
        if(obj.order_status){
            labels.push(obj.order_status);
            values.push(obj.order_total);
        }
    }
    return {labels,values};
}
    function currencyFormat(num:any) {
    return 'PHP ' + (Number(num || 0).toFixed(2)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  // Declare Hooks
  useEffect(() => {
    // Load Function Once
    // setMainDom(document.getElementById('Dashboard'));
    // LoadData();
    LoadUser();
  }, []);
  // Template
  return (
    <div className="" id="Dashboard" ref={(node:any)=> mainRef = node}>
      <div className='row'>
        <div className='col-sm-8 m-b-xs'>
            <div className="ibox">
                <div className="ibox-content">
                    <div className="row">
                        <div className="col-sm-3 m-b-xs">
                            <label>Date Start</label>
                            <input type="date" id="filter_startdate" name="filter_startdate" value={StartDate} onChange={(e)=>setStartDate(e.target.value)} placeholder="Start Date" className="form-control"/>
                        </div>
                        <div className="col-sm-3 m-b-xs">
                            <label>Date End</label>
                            <input type="date" id="filter_enddate" name="filter_enddate" value={EndDate} onChange={(e)=>setEndDate(e.target.value)} placeholder="End Date" className="form-control"/>
                        </div>
                        <div className="col-sm-3 m-b-xs">
                            <label>Status</label>
                            <select name="status" id="filter_status" className="form-control input-sm" onChange={(e)=>setFilterStatus(e.target.value)}>
                                <option value="" key="">All</option>
                                {
                                    StatusList.map((status: any, statusIndex: number)=>{
                                        return(<option value={status} key={statusIndex}>{status}</option>)
                                    })
                                }
                                </select>
                        </div>
                        <div className="col-sm-3 m-b-xs">
                            <label>&nbsp;</label>
                            <button type="button" className="form-control btn btn-primary" onClick={FilteredSearch}> <i className="fa fa-check-circle"></i> Filter</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ibox">
                <div className={`ibox-content ${UploadState}`}>
                    <div className="sk-spinner sk-spinner-wave">
                        <div className="sk-rect1"></div>
                        <div className="sk-rect2"></div>
                        <div className="sk-rect3"></div>
                        <div className="sk-rect4"></div>
                        <div className="sk-rect5"></div>
                        Loading
                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                        <h2>Shop Reports - {Shop.name}</h2>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-8' style={{height: "200px"}}>
                            <canvas id="dashboard_barChart" height="100"></canvas>
                        </div>
                        <div className="col-sm-2">
                            <ul className="stat-list">
                                <li>
                                    <small>Delivered Value: </small>
                                    <br/><span className='text-navy' id="dashboard_delivered_value">PHP 0.00</span>
                                </li>
                                <li>
                                    <small>Total Shipping Cost: </small>
                                    <br/><span className='text-navy' id="dashboard_delivered_shipping_cost">PHP 0.00</span>
                                </li>
                                <li>
                                    <small>COD Fee: </small>
                                    <br/><span className='text-navy' id="dashboard_cod_fee">PHP 0.00</span>
                                </li>
                                <li>
                                    <small>VAT Fee: </small>
                                    <br/><span className='text-navy' id="dashboard_vat_fee">PHP 0.00</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-2">
                            <ul className="stat-list">
                                <li>
                                    <small>Returned: </small>
                                    <br/><span className='text-navy' id="dashboard_returned_shipping_cost">PHP 0.00</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ibox float-e-margins">
                <div className="ibox-title">
                    <h5>Employee Total Earn</h5>
                </div>
                <div className={`ibox-content ${UploadState}`}>
                    <div className="sk-spinner sk-spinner-wave">
                        <div className="sk-rect1"></div>
                        <div className="sk-rect2"></div>
                        <div className="sk-rect3"></div>
                        <div className="sk-rect4"></div>
                        <div className="sk-rect5"></div>
                        Loading
                    </div>
                    <table className="table table-hover no-margins">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Group</th>
                            <th>Net Profit</th>
                            <th>Share(20%)</th>
                            <th>Billed Date</th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                                TopEmployee.map((employee:any,employeeKey:number)=>{
                                    return(
                                        <tr>
                                            <td>{employeeKey+1}</td>
                                            <td>{employee.name}</td>
                                            <td>{employee.group}</td>
                                            <td className="text-navy">{currencyFormat(employee.net_profit)}</td>
                                            <td className="text-navy">{currencyFormat(employee.share)}</td>
                                            <td>{employee.billed_date}</td>
                                        </tr>
                                    )
                                })
                            }
                        
                        </tbody>
                    </table>
                </div>
            </div>
            {/* Start */}
            <div className="ibox float-e-margins">
                <div className="ibox-title">
                    <h5>User Activity</h5>
                </div>
                <div className={`ibox-content ${UploadState}`}>
                    <div className="sk-spinner sk-spinner-wave">
                        <div className="sk-rect1"></div>
                        <div className="sk-rect2"></div>
                        <div className="sk-rect3"></div>
                        <div className="sk-rect4"></div>
                        <div className="sk-rect5"></div>
                        Loading
                    </div>
                    <table className="table table-hover no-margins">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Device</th>
                            <th>Last Login</th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                                UserActivity.map((activity:any,activityKey:number)=>{
                                    return(
                                        <tr key={activityKey}>
                                            <td>{activityKey+1}</td>
                                            <td>{activity.name}</td>
                                            <td>{activity.device}</td>
                                            <td>{uiHelper.format.fullDateWithTime(activity.updatedAt || activity.createdAt)}</td>
                                        </tr>
                                    )
                                })
                            }
                        
                        </tbody>
                    </table>
                </div>
            </div>
            {/* End */}
        </div>
        <div className='col-sm-4 m-b-xs'>
            <div className="ibox">
                <div className="ibox-title">
                    <h5>Top Delivered on the Month of {StartDate} - {EndDate}</h5>
                </div>
                <div className={`ibox-content ${UploadState}`}>
                    <div className="sk-spinner sk-spinner-wave">
                        <div className="sk-rect1"></div>
                        <div className="sk-rect2"></div>
                        <div className="sk-rect3"></div>
                        <div className="sk-rect4"></div>
                        <div className="sk-rect5"></div>
                        Loading
                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <table className="table table-hover no-margins">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Totals</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        TopProductList.map((product:any,productKey:any)=>{
                                            return(
                                                <tr>
                                                    <td>{productKey+1}</td>
                                                    <td>{product.item_name}</td>
                                                    <td className="text-navy">{product.delivered_total}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardV1;
