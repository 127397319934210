import { Requests } from "./Request";

export default class ProductsService{
    static add(itemname:string, label:string, productcost:Number, adsspent:Number,shopid: Number){
        const body = {
            itemname: itemname,
            label: label,
            product_cost: productcost,
            ads_spent: adsspent,
            id: shopid
        }
        return Requests.Post('/products/config/add',body, true);
    }
    static update(itemname:string, label:string, productcost:Number, adsspent:Number,shopid: Number){
        const body = {
            itemname: itemname,
            label: label,
            product_cost: productcost,
            ads_spent: adsspent,
            id: shopid
        }
        return Requests.Post('/products/config/save',body, true);
    }
    static set(itemname:string, label:string, productcost:Number, adsspent:Number,shopid: Number){
        const body = {
            itemname: itemname,
            label: label,
            product_cost: productcost,
            ads_spent: adsspent,
            id: shopid
        }
        return Requests.Post('/products/config/set',body, true);
    }
    static list(shopid: Number, itemname: string){
        return Requests.Get('/products/config/list?id='+shopid+'&label='+itemname, true);
    }
    static totalList(shopid: Number, startdate: string, enddate: string){
        const body = {
            id: shopid,
            startdate: startdate,
            enddate: enddate
        }
        return Requests.Post('/products/total/list', body, true);
    }
}