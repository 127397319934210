import uiHelper from "../pages/module/uiHelper";
import UpsellService from "../services/upsellService";
import SyncDeclare from "../SyncDeclare";

export default class UpsellController{
    static async add(e:any){
        const dom = e.target;
        const name = dom.querySelector('#upsell-name').value;
        const response = {
            msg: 'Upsell name required.',
            statusCode: 400
        }
        if(name && name.length > 0){
            const upsellResponse:any  = await UpsellService.add(SyncDeclare.ShopVariable.shopid, name);
            response.msg = upsellResponse.message;
            response.statusCode = upsellResponse.statusCode;
        }
        if(response.statusCode == 400){
            uiHelper.toastr.error(response.msg);
            return false;
        }else{
            uiHelper.toastr.success(response.msg);
            return true;
        }
    }
    static async list(){
        const id = SyncDeclare.ShopVariable.shopid || 0;
        let response = [];
        if(id != 0){
            const lists:any  = await UpsellService.getList(id);
            response = lists;
        }
        return response;
    }
    static async ReactSelectList(){
        const lists = await this.list();
        const response = [];
        for(let list of lists){
            response.push({label: list.name, value: list.uuid,total: 0});
        }
        return response;
    }
    static async remove(uuid:string){
        const id = SyncDeclare.ShopVariable.shopid || 0;
        if(id != 0 && uuid){
            const response:any  = await UpsellService.remove(id, uuid);
            if(response.statusCode == 400){
                uiHelper.toastr.error(response.message);
                return false;
            }else{
                uiHelper.toastr.success(response.message);
                return true;
            }
        }
        return uiHelper.toastr.error('Something went wrong.');;
    }
    static async listByUser(email:string){
        const id = SyncDeclare.ShopVariable.shopid || 0;
        let response = [];
        if(id != 0){
            const lists:any  = await UpsellService.getListByUser(id,email);
            response = lists;
        }
        return response;
    }
}