import React, { useState, useEffect } from 'react';

// Static CSS
import '../App.css';

// Declare Modules
import Assets from './module/Assets';

import SyncD from '../SyncDeclare';
import uiHelper from './module/uiHelper';
import TeamsService from './module/Teams.service';
import SyncDeclare from '../SyncDeclare';
import { useHistory } from 'react-router-dom';

function Teams() {
  // Static Assets
  const logo = Assets.logo;
  const history = useHistory();

  // Declare Variable
  let [TeamList,setTeamList]:any[] = useState([]);
  let mainRef:any;

  async function LoadTeams(){
    const list = await TeamsService.list();
    setTeamList(list);
    SyncDeclare.TemplateHeader('', 'Customize what your members can edit and access. You can add up to 15 members. ');
  }
  function openTeams(team:any){
    SyncDeclare.TeamVariable = team;
    // localStorage.setItem("team", JSON.stringify(team));
    history.push("/teams-members");
  }
  // Declare Hooks
  useEffect(() => {
    // Load Function Once
    LoadTeams();
  }, []);
  // Template
  return (
    <div className="" id="Teams" ref={(node:any)=> mainRef = node}>
      <div className="row">
        <div className="col-lg-12">
            <button type="button" className="btn btn-w-m btn-primary" data-toggle="modal" data-target="#teamsCreate">Create Team</button>
        </div>
      </div>
      <div className="row wrapper wrapper-content animated fadeInRight">
          {
            TeamList.map((team:any, index:number)=>{
              return(
                <div className="col-lg-3" key={index}>
                  <div className="ibox">
                    <div className="ibox-title">
                        <span className="text-muted small pull-right">Created: <i className="fa fa-clock-o"></i>12.06.2014</span>
                        <h5>Team: {team.name}</h5>
                    </div>
                    <div className="ibox-content">
                        <div className="team-members text-center">
                            <a href="#"><img alt="member" className="img-circle" src={Assets.teamIcon}/></a>
                        </div>
                        <h4>Information</h4>
                        <p>
                            It is a long established fact that a reader will be distracted by the readable content
                            of a page when looking at its layout. The point of using Lorem Ipsum is that it has.
                        </p>
                        <div className="row  m-t-sm">
                            <div className="col-sm-12 text-center">
                            <button type="button" className="btn btn-w-m btn-primary btn-outline" onClick={()=>openTeams(team)}>Open</button>
                            </div>
                        </div>

                    </div>
                  </div>
                </div>
              )
              })
            }
        </div>




    </div>
  );
}

export default Teams;
