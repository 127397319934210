

// Static CSS
import { useEffect, useState } from 'react';
import '../App.css';
import SyncDeclare from '../SyncDeclare';
import ShopsService from '../pages/module/Shops.service';
import uiHelper from '../pages/module/uiHelper';
import UsersService from '../pages/module/Users.service';

function ProfileModals() {
    // Set Variables
    const Shop = SyncDeclare.ShopVariable;
    let [User,setUser]:any = useState({
        "userinfoid":7,
        "firstname":"",
        "middlename":"",
        "lastname":"",
        "address":"",
        "profilePic":"",
        "permissionid":"",
        "createdAt":"",
        "updatedAt":"",
        "email":"",
        "roles":[]
    });
    // Static Assets

    function UserInfo(details:any){
        setUser(details);
    }
    async function changePassword(e:any){
        const dom:any = document.getElementById('ProfileModal_ChangePassword');
        const old = dom.querySelector('#old_password').value;
        const npass = dom.querySelector('#new_password').value;
        const cpass = dom.querySelector('#new_password2').value;
        if(npass === cpass){
            const response:any = await UsersService.changePassword(User.email,old,npass);
            if(response.status === 'error'){
                uiHelper.toastr.error(response.message);
            }
            else{
                uiHelper.toastr.success('Password has been changed.');
            }
        }else{
            uiHelper.toastr.error('Password not match.');
        }
    }
    SyncDeclare.Modals.Profile.UserInfo = UserInfo;
    // Declare Hooks
    useEffect(() => {
        // Load Function Once
        setTimeout(()=>{

        },100)
    },[]);
  // Template
  return (
    <div id="modalTemplate">
        <div className="modal inmodal fade" id="ProfileModal_ChangePassword" role="dialog"  aria-hidden="true">
            <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                            <h2>Change Password</h2>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div className="form-group">
                                        <label>Old Password</label>
                                        <input type="password" id="old_password" name="old_password" placeholder="Enter Old Password" className="form-control"/>
                                    </div>
                                    <div className="form-group">
                                        <label>New Password</label>
                                        <input type="password" id="new_password" name="new_password" placeholder="Enter New Password" className="form-control"/>
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm Password</label>
                                        <input type="password" id="new_password2" name="new_password2" placeholder="Enter Confirm Password" className="form-control"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-white" data-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-success" onClick={changePassword}>Submit</button>
                        </div>
                    </div>
            </div>
        </div>
    </div>
  );
}

export default ProfileModals;
