import { Requests } from "../pages/module/Request";
import SyncDeclare from "../SyncDeclare";

export default class OrderService{
    static getHeader(){
        return Requests.Get('/orders/headers', true);
    }
    static getCustomHeader(shopid: Number){
        return Requests.Get('/orders/headers/get?id='+shopid, true);
    }
    static addHeader(shopid: Number, custom:any){
        const body = {
            id: shopid,
            custom: JSON.stringify(custom)
        }
        return Requests.Post('/orders/headers/add', body, true);
    }
}