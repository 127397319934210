
import Checker from './Checker';
import CheckerDashboard from './CheckerDashboard';
import CompareDashboard from './CompareDashboard';
import Dashboard from './Dashboard';
import DashboardV1 from './Dashboard.v1';
import OrdersDashboardV1 from './OrderDashboard.v1';
// import FacebookDashboard from './FacebookDashboard';
import Orders from './Orders';
import OrdersDashboard from './OrdersDashboard';
import Payment from './Payment';
import PaymentDashboard from './PaymentDashboard';
import PayoutDashboard from './PayoutDashboard';
import PaymentSOA from './PayoutSOA';
import ProfileDashboard from './ProfileDashboard';
import Shops from './Shops';
import ShopsDashboard from './ShopsDashboard';
import Teams from './Teams';
import TeamsMember from './TeamsMember';
import TemplateDashboard from './Template';

const RouterList = [
    {title: 'Dashboard', path: '/', icon: 'fa fa-th-large', source: Dashboard, permission: 0},
    {title: 'Dashboard', path: '/', icon: 'fa fa-th-large', source: DashboardV1, permission: 5},
    // {title: 'Orders', path: '/orders', icon: 'fa fa-table', source: Orders, permission: 1},
    {title: 'Orders', path: '/orders', icon: 'fa fa-shopping-cart', source: OrdersDashboard, permission: 1, hide: false},
    {title: 'Orders Splits', path: '/orders-splits', icon: 'fa fa-leaf', source: OrdersDashboardV1, permission: 6},
    {title: 'Fast Check', path: '/fast-checker', icon: 'fa fa-fast-forward', source: CheckerDashboard, permission: 3},
    {title: 'Deep Check ', path: '/deep-check', icon: 'fa fa-check', source: Checker, permission: 3},
    {title: 'Compare Check ', path: '/compare-check', icon: 'fa fa-adjust', source: CompareDashboard, permission: 3},
    // {title: 'Shop', path: '/shop', icon: 'fa fa-shopping-cart', source: Shops, permission: 1},
    {title: 'Shop', path: '/shop', icon: 'fa fa-shopping-cart', source: ShopsDashboard, permission: 1, hide: false},
    {title: 'Teams', path: '/teams', icon: 'fa fa-users', source: Teams, permission: 1},
    {title: 'Team Members', path: '/teams-members', icon: 'fa fa-users', source: TeamsMember, permission: 1, hide: true},
    // {title: 'Payout', path: '/payout', icon: 'fa fa-rub', source: Payment, permission: 1, hide: false},
    {title: 'Payout', path: '/payout', icon: 'fa fa-rub', source: PaymentDashboard, permission: 1, hide: false},
    {title: 'Payout | SOA', path: '/payout-soa', icon: 'fa fa-rub', source: PaymentSOA, permission: 1, hide: true},
    {title: 'Payout | History', path: '/payout-history', icon: 'fa fa-money', source: PayoutDashboard, permission: 4, hide: false},
    {title: 'Template', path: '/template', icon: 'fa fa-newspaper-o', source: TemplateDashboard, permission: 2, hide: false},
    {title: 'Profile Management', path: '/profile', icon: 'fa fa-users-o', source: ProfileDashboard, permission: 3, hide: true},
    // {title: 'Facebook', path: '/facebook', icon: 'fa fa-facebook-square', source: FacebookDashboard, permission: 1, hide: true},
]

export default RouterList;