

// Static CSS
import { useEffect, useState } from 'react';
import '../App.css';
import SyncDeclare from '../SyncDeclare';
import Assets from '../pages/module/Assets';
import OrdersService from '../pages/module/Orders.service';
import ShopsService from '../pages/module/Shops.service';
import TeamsService from '../pages/module/Teams.service';
import uiHelper from '../pages/module/uiHelper';
import UsersService from '../pages/module/Users.service';
import Select from 'react-select';
import UpsellController from '../controller/upsellController';
import UserController from '../controller/userController';
import { UpdateStateWithObject } from '../utils/tools';

function ShopModals() {
    // Set Variables
    const Shop = SyncDeclare.ShopVariable;
    const [UpSellList, setUpSellList]:any[] = useState([]);
    const [UpSellUser, setUpSellUser]:any[] = useState([]);
    const [UserPages, setUserPages]:any[] = useState([]);
    const [userDetails, setUserDetails] = useState({
        address: "",
        email: "",
        firstname: "",
        itemlist: "",
        lastname: "",
        membertype: "",
        middlename: "",
        profilePic: "",
        teamname: ""
    });
    // Static Assets
    const logo = 'assets/img/main-logo.png';
    let domModal:any;
    async function Load(){
        setUpSellList(await UpsellController.ReactSelectList());
    }
    async function LoadUserUpsell(email:string){
        let list:any = await UpsellController.listByUser(email);
        for(let key in list){
            if(list[key].upsell && list[key].upsell !== 'null'){
                list[key].upsell = typeof list[key].upsell === 'string' ? JSON.parse(list[key].upsell || '[]') : list[key].upsell;
            }else{
                list[key].upsell = [];
            }
        }
        setUpSellUser(list);
    }
    async function UserInfo(details:any){
        await LoadUserUpsell(details.email);
        await setUserDetails(details);
        await Load();
        // await LoadPages();
        
    }
    async function LoadPages(){
        const lists = await UserController.pages();
        setUserPages(lists);console.log(lists)
    }
    function userProductListFormat(productList:string){
        if(productList && productList.length>0){
          return productList.split('|');
        }
        return [];
    }
    async function removeProductAssign(itemname:string){
        const response:any = await ShopsService.assignProductRemove(itemname, Shop.shopid);
        if(response.status == 'error'){
          uiHelper.toastr.error(response.message);
        }else{
          uiHelper.toastr.success(response.message);
        //   LoadTeamMembers();
        }
    }
    async function UpdateUserDetails(e:any){
        const response:any = await UserController.update(userDetails,JSON.stringify(UpSellUser));
        if(response.statusCode == 200){
            uiHelper.toastr.success(response.message);
        }else{
            uiHelper.toastr.error(response.message);
        }
    }
    function DefaultValueOfUpsell(productName:string, obj:any){
        let index = '-1';
        for(let itemKey in obj){
            if(obj[itemKey].product === productName){
                index = itemKey;
            }
        }
        return index;
    }
    // SyncDeclare.ProductConfigList = LoadOrdersConfig;
    SyncDeclare.Modals.ShopDashboard.UserInfo = UserInfo;
    // Declare Hooks
    useEffect(() => {
        // Load Function Once
    },[]);
  // Template
  return (
    <div id="modalTemplate">
        <div className="modal inmodal fade" id="ShopModal" role="dialog"  aria-hidden="true">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                        <img alt="Profile Picture" className="img-circle" src={uiHelper.assets.getProfilePic(userDetails.profilePic,Assets.profileIcon)} style={{"width":"100px","height":"100px"}}/>
                        <h4 className="modal-title">{userDetails.firstname} {userDetails.lastname}</h4>
                        <small className="font-bold">{userDetails.teamname} [ {userDetails.membertype} ]</small>
                    </div>
                    <div className="modal-body">
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input type="text" id="first_name" name="first_name" value={userDetails.firstname} onChange={(e:any)=>{UpdateStateWithObject(e,userDetails,'firstname',setUserDetails)}} placeholder="First Name" className="form-control"/>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input type="text" id="last_name" name="last_name" value={userDetails.lastname} onChange={(e:any)=>{UpdateStateWithObject(e,userDetails,'lastname',setUserDetails)}} placeholder="Last Name" className="form-control"/>
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className="form-group">
                                    <label>Email Address</label>
                                    <input type="email" id="email_address" name="email_address" value={userDetails.email} placeholder="Email Address" className="form-control"/>
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className="form-group">
                                    <label>Address</label>
                                    <input type="email" id="address" name="address" value={userDetails.address} onChange={(e:any)=>{UpdateStateWithObject(e,userDetails,'address',setUserDetails)}} placeholder="Address" className="form-control"/>
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className="form-group">
                                    <label>Access</label>
                                    <Select
                                        onChange={(e:any,event:any)=>{
                                            // const ulist = UpSellUser;
                                            // if(event.action === "select-option"){
                                            //     let amount:any = window.prompt('Enter the total for '+e[e.length-1].label);
                                            //     let val = 0;
                                            //     if(amount !== null && !isNaN(Number(amount))){
                                            //         val = Number(amount);
                                            //         e[e.length-1].total = val;
                                            //         ulist[filterID] = {
                                            //             product: products,
                                            //             upsell: e
                                            //         };
                                            //         setUpSellUser([...ulist]);
                                            //     }
                                            // }else{
                                            //     ulist[filterID] = {
                                            //         product: products,
                                            //         upsell: e
                                            //     };
                                            //     setUpSellUser([...ulist]);
                                            // }
                                        }}
                                        // value={(filterID >= 0 ? UpSellUser[filterID].upsell : [])}
                                        isMulti={true}
                                        options={UserPages} 
                                        id={"mediabuyer_access"}
                                        className='forward-div'
                                        menuPortalTarget={document.body} 
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>Product Name</th>
                                        <th>Upsell</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                        userProductListFormat(userDetails.itemlist).map((products:string,productKey:any)=>{
                                            let filterID:any = DefaultValueOfUpsell(products,UpSellUser);
                                            return(
                                                <tr key={productKey}>
                                                    <td>{products}&nbsp;</td>
                                                    <td>{
                                                        <Select
                                                            onChange={(e:any,event:any)=>{
                                                                const ulist = UpSellUser;
                                                                if(event.action === "select-option"){
                                                                    let amount:any = window.prompt('Enter the total for '+e[e.length-1].label);
                                                                    let val = 0;
                                                                    if(amount !== null && !isNaN(Number(amount))){
                                                                        val = Number(amount);
                                                                        e[e.length-1].total = val;
                                                                        ulist[filterID] = {
                                                                            product: products,
                                                                            upsell: e
                                                                        };
                                                                        setUpSellUser([...ulist]);
                                                                    }
                                                                }else{
                                                                    ulist[filterID] = {
                                                                        product: products,
                                                                        upsell: e
                                                                    };
                                                                    setUpSellUser([...ulist]);
                                                                }
                                                            }}
                                                            value={(filterID >= 0 ? UpSellUser[filterID].upsell : [])}
                                                            isMulti={true}
                                                            options={UpSellList} 
                                                            id={"calculate_mediabuyer_"+productKey}
                                                            className='forward-div'
                                                            menuPortalTarget={document.body} 
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        />
                                                        }
                                                    </td>
                                                    <td><button className="btn btn-primary btn-outline"><i className="fa fa-trash" onClick={(e)=>removeProductAssign(products)}></i></button></td>
                                                </tr>
                                            );
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={UpdateUserDetails}>Update</button>
                        <button type="button" className="btn btn-white" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default ShopModals;
