import {Requests} from "./Request";

export default class UsersService{
    static getMe(){
        return Requests.Get('/users/me', true);
    }
    static findByEmail(email:string){
        return Requests.Get('/users/find?email='+email, true);
    }
    static changePassword(email:string, opassword:string, password:string){
        return Requests.Post('/users/change/password',{email,opassword,password},true);
    }
    static update(shopid:Number,email:string,firstname:string,lastname:string,address:string,products:any){
        return Requests.Post('/users/update',{shopid,email,firstname,lastname,address,products},true);
    }
    static getPages(){
        return Requests.Get('/users/pages', true);
    }
    static getActivity(id: Number){
        return Requests.Get('/users/activity?id='+id, true);
    }
}