import React, { useState, useEffect } from 'react';

// Static CSS
import '../App.css';

// Declare Modules
import Assets from './module/Assets';

import uiHelper from './module/uiHelper';
import SyncDeclare from '../SyncDeclare';
import ShopsService from './module/Shops.service';
import TeamsService from './module/Teams.service';
import OrdersService from './module/Orders.service';

function Checker() {
  // Static Assets
  const logo = Assets.logo;
  const Shop = SyncDeclare.ShopVariable;
  const skipColumn = ['id','shopid','creator_code'];
  const maxRows = 1000;
  const mediaBuyerShare = 20/100;
  const Pagination:any = {
    Index: 2,
    List: []
  };

  // Declare Variable
  let [OrderList,setOrderList]:any[] = useState([]);
  let [listNoTracking,setListNoTracking]:any[] = useState([]);
  let [listWithTracking,setListWithTracking]:any[] = useState([]);
  let [uploadState,setUploadState]:any = useState('');
  let [pageNumber, setPageNumber]:any[] = useState(0);
  let [productList, setProductList]:any[] = useState([]);
  let [filterStatus,setFilterStatus]:any = useState('');
  let [checkOrderTime,setCheckOrderTime]:any = useState('');
  let [checkOrderEndTime,setCheckOrderEndTime]:any = useState('');
  let [shopList, setShopList]:any = useState([]);
  let [showDuplicates, setShowDuplicates]:any = useState([]);
  let [showDuplicateTitle, setShowDuplicatesTitle]:any = useState('');
  let [uploadDate, setUploadDate]:any = useState('');
  let mainRef:any;

  async function LoadOrders(shopid: Number, phoneNumbers:any[], itemname: string, datestart: string, dateend: string){
    setUploadState('sk-loading');
    setShowDuplicates([]);
    const list:any = await OrdersService.deepChecksList(shopid, phoneNumbers,itemname,datestart,dateend);
    let newList:any = [];
    let noTracking:any = [];
    let withTracking:any = [];
    for(let data of phoneNumbers){
        let dups = CheckDuplicates(list, data);
        if(dups.length>0){
            withTracking.push({
                phone: data,
                duplicates: dups,
                status: 'Has Tracking'
            });
        }else{
            noTracking.push({
                phone: data,
                duplicates: [],
                status: 'No Tracking'
            });
        }
    }

    if(list.length==0){
        uiHelper.toastr.info('No data found.');
        // newList=[];
    }
    withTracking.sort((a:any,b:any)=>{
        return b.duplicates.length - a.duplicates.length
    });
    setListNoTracking(noTracking);
    setListWithTracking(withTracking);
    setOrderList(noTracking);
    setUploadState('');
  }
  function CheckDuplicates(phoneNumbers:any[], checkPhone:any){
    let formatPhone = checkPhone.replace(/\+63|\+0|\+| +/g,'');
    let duplicates = [];
    for(let data of phoneNumbers){
        if((data.receiver_cellphone || '').includes(formatPhone)){
            duplicates.push(data)
        }
    }
    return duplicates;
  }
  async function filterByParameters(e:any){
    setPageNumber(0);
    const dom:any = DomTarget();
    // let shopIndex:any = dom.querySelector('#choose-shop').value;
    if(checkOrderTime=='' || checkOrderEndTime==''){
        uiHelper.toastr.info('Please set date.');
        return;
    }
    // if(shopIndex != -1){
        let product:any = dom.querySelector('#check-product').value;
        let rawData:any = dom.querySelector('textarea').value;
        let formatData:any = rawData.split('\n');
        if(formatData.length>0){
            LoadOrders(SyncDeclare.ShopVariable.shopid,formatData,product,checkOrderTime,checkOrderEndTime);
        }
    // }
  }
  function formatTD(row:any){
      let formatRow = [];
      for(const column in row){
          if(skipColumn.indexOf(column)<0){
            if(['createdAt','updatedAt'].indexOf(column)>=0){
                formatRow.push({column, value: row[column] != null ? uiHelper.format.fullDateWithTime(row[column]) : ""});
            }else if(['duplicates'].indexOf(column)>=0){
                formatRow.push({column, value: row[column].length});
            }else{
                formatRow.push({column, value: row[column]});
            }
          }
      }
      return formatRow;
  }
  async function LoadShops(){
    const list = await ShopsService.list();
    setShopList(list);
    const time:any = await OrdersService.getOrderTimeLimit();
    setCheckOrderTime(time.date);
  }
  async function LoadProducts(){
    // let index = e.target.value;
    setProductList([]);
    // if(index >= 0){
        // let data = shopList[index];
        let data = SyncDeclare.ShopVariable;
        setFilterStatus(data)
        // const listOfItems = await OrdersService.listItems(data.shopid);
        const listOfItems = await OrdersService.listItemsByUser(data.shopid);
        setProductList(listOfItems);
        getLastUploadDate(data.shopid);
    // }
  }
  function DomTarget(){
    return document.getElementById('ChecksDashboard')
  }
  async function getLastUploadDate(id:any){
    const uploadDate:any = await ShopsService.getUploadDate(id);
    if(uploadDate.length>0){
      setUploadDate(uiHelper.format.fullDateWithTime(uploadDate));
    }else{
      setUploadDate('');
    }
  }
  function showDulicateOrders(data:any, index:any){
    if(data.duplicates && data.duplicates.length>0){
        setShowDuplicatesTitle(data.phone)
        setShowDuplicates(data.duplicates);
    }else{
        setShowDuplicatesTitle('')
        setShowDuplicates([]);
    }
    buttonSelected(index);
  }
  function setColumns(data:any){
    let setColumns = [];
    if(data && data.length>0){
        let columns = data[0];
        for(const column in columns){
            if(skipColumn.indexOf(column)<0){
                setColumns.push(column);
            }
        }
    }
    return setColumns;
  }
  function LoadDateRange(e:any){
    const dom = e.target;
    if(dom){
        let newDate = uiHelper.format.SubtractDate(dom.value);
        setCheckOrderTime(newDate)
        setCheckOrderEndTime(dom.value)
    }
  }
  function changeStatus(e:any){
    let choose = e.target.value;
    if(choose=='1'){
        setOrderList(listWithTracking);
    }else{
        setOrderList(listNoTracking);
    }
  }
  function sortBy(e:any){
    let dom = e.target;
    let list = OrderList;
    let up = 'fa-sort-up';
    let down = 'fa-sort-down';
    if(list.length>0){
        let remove = '';
        let add = '';
        if(dom.querySelector('i.fa-sort-up')){
            add = down;
            remove = up;
        }else{
            add = up;
            remove = down;
        }
        list.sort((a:any,b:any)=>{
            return a.duplicates.length - b.duplicates.length
        });
        dom.querySelector('i').classList.remove(remove);
        dom.querySelector('i').classList.add(add);
        setOrderList(list)
    }
  }
  function buttonSelected(i:any){
    const dom:any = DomTarget();
    const btn = dom.querySelectorAll('button.btn-show');
    if(btn.length>0){
        for(let key=0;key<btn.length;key++){
            btn[key].classList.remove('btn-selected');
        }
        i.target.classList.add('btn-selected');
    }
  }
  // Declare Hooks
  useEffect(() => {
    // Load Function Once
    if(SyncDeclare.ShopVariable.shopid==0){
    //   window.location.href="/orders";
    }
    // LoadShops();
    LoadProducts();
    setTimeout(()=>{
        SyncDeclare.TemplateHeader('', '', false);
    },100)
  }, []);
  useEffect(() => {

  }, [OrderList]);
  // Template
  return (
    <div className="" id="ChecksDashboard" ref={(node:any)=> mainRef = node}>
        <div className='row'>
            <div className='col-sm-12 m-b-xs'>
                <div className="ibox">
                    <div className="ibox-content">
                        <div className='row'>
                            <div className='col-sm-12'>
                            <h2>Checker ( Deep Scan )</h2>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6' style={{height: "280px"}}>
                                <textarea className='form-control' style={{"height": "100%"}}></textarea>
                            </div>
                            <div className="col-sm-3">
                                {/* <div className="form-group">
                                    <label>Shop:</label>
                                    <select name="status" id="choose-shop" className="form-control" onChange={LoadProducts}>
                                        <option value="-1" key={-1}>Choose Shop</option>
                                        {
                                            shopList.map((shop: any, index: number)=>{
                                                return(<option value={index} key={index}>{shop.name}</option>)
                                            })
                                        }
                                    </select>
                                </div> */}
                                <div className="form-group">
                                    <label>Product:</label>
                                    <select name="status" id="check-product" className="form-control" >
                                        {
                                            productList.map((product: any, index: number)=>{
                                                return(<option value={product.item_name} key={index}>{product.item_name}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label>Date</label>
                                    <input type="date" id="date_start" onChange={LoadDateRange} placeholder="Product Name" className="form-control"/>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label>&nbsp;</label>
                                    <button type="button" className="form-control btn btn-primary" onClick={filterByParameters}> <i className="fa fa-check-circle"></i> Check</button>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <p>Add your phone number in the <span className='text-warning'>checker text box</span>. <br/>You can do <span className='text-warning'>multiple</span> at once by adding the new phone number on the <span className='text-warning'>next line</span>.
                                    <br/>Choose what shop and product to check. Click Check Button.
                                    <br/>The check will start at <span className='text-warning'>{checkOrderTime}</span> up to {checkOrderEndTime ? checkOrderEndTime : 'latest date'}.</p><br/>

                                    <span className="text-muted">Date Uploaded: {uploadDate}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-sm-6">
                <div className="ibox float-e-margins">
                    <div className="ibox-title">
                        <h5>Check Results</h5>
                    </div>
                    <div className={`ibox-content ${uploadState}`}>
                        <div className="sk-spinner sk-spinner-wave">
                            <div className="sk-rect1"></div>
                            <div className="sk-rect2"></div>
                            <div className="sk-rect3"></div>
                            <div className="sk-rect4"></div>
                            <div className="sk-rect5"></div>
                            Loading
                        </div>
                        <div className="tabs-container">
                            <ul className="nav nav-tabs">
                                <li className="active"><a data-toggle="tab" href="#tab-1"> No Tracking ( {listNoTracking.length} )</a></li>
                                <li className=""><a data-toggle="tab" href="#tab-2">Has Tracking ( {listWithTracking.length} )</a></li>
                            </ul>
                            <div className="tab-content">
                                <div id="tab-1" className="tab-pane active">
                                    <div className="panel-body">
                                        <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing {OrderList.length} entries</div>
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                <tr key={-1}>
                                                    <th key={-1}>#</th>
                                                    <th key={0}>Phone</th>
                                                    <th key={1}>Instance(s)</th>
                                                    <th key={2}>Status</th>
                                                    <th key={3}>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        listNoTracking.map((order:any, index:any)=>{
                                                            return(
                                                                <tr key={index}>
                                                                    <td>{(index+1)+(pageNumber*maxRows)}</td>
                                                                    {
                                                                        formatTD(order).map((object:any, i:any)=>{
                                                                            return(<td data-toggle="tooltip" data-placement="left" title={object.column} key={i}>
                                                                                {object.value}
                                                                            </td>)
                                                                        })
                                                                    }
                                                                    <td></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div id="tab-2" className="tab-pane">
                                    <div className="panel-body">
                                        <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing {listWithTracking.length} entries</div>
                                        <table className="table table-striped">
                                                <thead>
                                                <tr key={-1}>
                                                    <th key={-1}>#</th>
                                                    <th key={0}>Phone</th>
                                                    <th key={1}>Instance(s)</th>
                                                    <th key={2}>Status</th>
                                                    <th key={3}>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        listWithTracking.map((order:any, index:any)=>{
                                                            return(
                                                                <tr key={index}>
                                                                    <td>{(index+1)+(pageNumber*maxRows)}</td>
                                                                    {
                                                                        formatTD(order).map((object:any, i:any)=>{
                                                                            return(<td data-toggle="tooltip" data-placement="left" title={object.column} key={i}>
                                                                                {object.value}
                                                                            </td>)
                                                                        })
                                                                    }
                                                                    <td><button className="form-control btn btn-primary btn-show" onClick={(e)=>showDulicateOrders(order, e)}>Show</button></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div className="col-sm-6">
                <div className="ibox float-e-margins">
                    <div className="ibox-title">
                        <h5>Showing Instance for Phone # {showDuplicateTitle}</h5>

                        {/* <div className="ibox-tools">
                            <a className="dropdown-toggle" data-toggle="dropdown" href="#">
                                <i className="fa fa-wrench"></i>
                            </a>
                            <ul className="dropdown-menu dropdown-user">
                                <li><a href="#">Config option 1</a>
                                </li>
                                <li><a href="#">Config option 2</a>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                    <div className={`ibox-content ${uploadState}`}>
                        <div className="sk-spinner sk-spinner-wave">
                            <div className="sk-rect1"></div>
                            <div className="sk-rect2"></div>
                            <div className="sk-rect3"></div>
                            <div className="sk-rect4"></div>
                            <div className="sk-rect5"></div>
                            Loading
                        </div>
                        <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing {showDuplicates.length} entries</div>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                <tr key={-1}>
                                    <th key={-1}>#</th>
                                    {
                                        setColumns(showDuplicates).map((col:string, index:any)=>{
                                            return(<th key={index}>{col}</th>)
                                        })
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        showDuplicates.map((order:any, index:any)=>{
                                            return(
                                                <tr key={index}>
                                                    <td>{(index+1)+(pageNumber*maxRows)}</td>
                                                    {
                                                        formatTD(order).map((object:any, i:any)=>{
                                                            return(<td data-toggle="tooltip" data-placement="left" title={object.column} key={i}>
                                                                {object.column === 'waybill_number' ? <a href={"https://www.jtexpress.ph/trajectoryQuery?flag=1&waybillNo="+object.value} target="_blank">{object.value}</a> : object.value}
                                                            </td>)
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>




    </div>
  );
}

export default Checker;
