import React, { useState, useEffect } from 'react';
import Select from 'react-select';

// Static CSS
import '../App.css';

// Declare Modules
import Assets from './module/Assets';

import uiHelper from './module/uiHelper';
import SyncDeclare from '../SyncDeclare';
import ShopsService from './module/Shops.service';
import TeamsService from './module/Teams.service';
import OrdersService from './module/Orders.service';
import UpsellController from '../controller/upsellController';

function ShopsDashboard() {
  // Static Assets
  const logo = Assets.logo;
  const Shop = SyncDeclare.ShopVariable;
  const Users:any = SyncDeclare.Users;

  // Declare Variable
  let [TeamList,setTeamList]:any[] = useState([]);
  let [TeamListMember,setTeamListMember]:any[] = useState([]);
  let [AssignTeams,setAssignTeams]:any[] = useState([]);
  let [itemList, setItemList]:any[] = useState([]);
  let [productLists, setProductLists]:any[] = useState([]);
  let [shopOrderStatus, setShopOrderStatus]:any[] = useState([]);
  let [statusList, setStatusList]:any[] = useState([]);
  let [uploadState,setUploadState]:any[] = useState('sk-loading');
  let [UploadLoadingPercent,setUploadLoadingPercent]:any[] = useState('0%');
  let [downloadLink,setDownloadLink]:any[] = useState('');
  let [uploadDate,setUploadDate]:any[] = useState('');
  let [reportStatus,setReportStatus]:any[] = useState({delivered:0,delivering:0,forreturn:0,intransit:0,returned:0, total:0});
  let [UpsellList,setUpsellList]:any = useState([]);
  let mainRef:any;

  async function LoadShops(){
    SyncDeclare.TemplateHeader('['+Shop.name+']', 'Add a team to be able to access this shop. ');
    const teamlist = await TeamsService.list();
    setTeamList(teamlist);
    const upload:any = await ShopsService.status(Shop.shopid);
    const assignList:any = await ShopsService.assignList(Shop.shopid);
    setAssignTeams(assignList);
    const statuslist:any = await OrdersService.listStatus(Shop.shopid);
    setStatusList(statuslist);
    if(upload[0].status === 'pending'){
      setUploadState('sk-loading');
      setUploadLoadingPercent( Math.floor(eval(upload[0].loading)*100)+'%' )
    }else{
      setUploadState('');
    }
    const items:any = await OrdersService.listItems(Shop.shopid);
    setItemList(items);
    LoadTeamMembers();
    LoadUpsell();
  }
  async function LoadTeamMembers(){
    const teamMembers:any = await ShopsService.teamList(Shop.shopid);
    setTeamListMember(teamMembers);
  }
  async function upload(event:any){
    setUploadState('sk-loading');
    event.preventDefault();
    let file = new FormData(event.target);
    try{
      const xlsx:any = await ShopsService.upload(file, Shop.shopid);
      if(xlsx.length>0){
        uiHelper.toastr.info('File is now being process.');
      }
      console.log(xlsx);
      alert('Processing '+xlsx.length);
    }catch(e){
      console.log('Error in upload', e)
      uiHelper.toastr.error("There's an error encountered while processing the file. Please refresh and upload file again.")
    }
  }
  async function assignTeam(){
    const teamid = mainRef.querySelector('#teamsAssign').value;
    let teamDetails:any = {};
    for(const team of TeamList){
      if(team.teamsid == teamid){
        teamDetails = team;
      }
    }
    let dups = false;
    for(const team of AssignTeams){
      if(team.teamsid == teamid){
        dups = true;
      }
    }
    if(!dups){
      const teamAssign = [...AssignTeams, teamDetails];
      setAssignTeams(teamAssign);
      const assignResponse:any = await ShopsService.assignTeam(Shop.shopid, parseInt(teamid));
      if(assignResponse.statusCode==200){
        uiHelper.toastr.success('Assign Successfully.');
      }
    }
  }
  async function productAssign(e:any){
    const productToBeAssign:string = e.target.parentNode.querySelector('.productList').value;
    const emailToBeAssign:string = e.target.parentNode.querySelector('.emailToBeAssign').value;
    const response:any = await ShopsService.productAssign(productToBeAssign,emailToBeAssign,Shop.shopid);
    if(response.status == 'error'){
      uiHelper.toastr.error(response.message);
    }else{
      uiHelper.toastr.success(response.message);
      LoadTeamMembers();
    }
  }
  async function removeAssign(team:any){
    const removeResponse:any = await ShopsService.assignRemove(Shop.shopid, parseInt(team.teamsid));
    if(removeResponse.statusCode==200){
      uiHelper.toastr.success('Remove Successfully.');
      let teamDetails = [];
      for(const team of AssignTeams){
        if(team.teamsid != team.teamsid){
          teamDetails.push(team)
        }
      }
      setAssignTeams(teamDetails);
      LoadTeamMembers();
    }
    // console.log(removeResponse)
  }
  async function removeProductAssign(itemname:string){
    const response:any = await ShopsService.assignProductRemove(itemname, Shop.shopid);
    if(response.status == 'error'){
      uiHelper.toastr.error(response.message);
    }else{
      uiHelper.toastr.success(response.message);
      LoadTeamMembers();
    }
  }
  function userProductListFormat(productList:string){
    if(productList && productList.length>0){
      return productList.split('|');
    }
    return [];
  }
  async function LoadStatusCount(){
    const status:any = await OrdersService.listStatus(Shop.shopid);
    setShopOrderStatus(status);
  }
  async function GetReportStatus(){
    const reports:any = await OrdersService.reportStatus(Shop.shopid);
    let delivered = {total: reports.delivered, percentage: ((reports.delivered/reports.total)*100).toFixed(2)};
    let delivering = {total: reports.delivering, percentage: ((reports.delivering/reports.total)*100).toFixed(2)};
    let forreturn = {total: reports.forreturn, percentage: ((reports.forreturn/reports.total)*100).toFixed(2)};
    let intransit = {total: reports.intransit, percentage: ((reports.intransit/reports.total)*100).toFixed(2)};
    let returned = {total: reports.returned, percentage: ((reports.returned/reports.total)*100).toFixed(2)};
    setReportStatus({delivered,delivering,forreturn,intransit,returned, total: reports.total});
  }
  async function exportToXLSX(e:any){
    e.preventDefault();
    setDownloadLink('');
    let form = e.target;
    let parent = form.parentNode;
    parent.classList.add("sk-loading");
    let dateStart = form.querySelector('#export_start').value;
    let endDate = form.querySelector('#export_end').value;
    let itemname = form.querySelector('#export_item').value;
    let emailIndex = form.querySelector('#export_email').value;
    let status = form.querySelector('#export_status').value;
    let split = form.querySelector('#export_split').value;
    let user = (TeamListMember[emailIndex] || {email: "",itemlist: ""});
    let email = user.email;
    if(!user.itemlist.includes(itemname)){
      uiHelper.toastr.info('Item not available to this media buyer. Switch to All.');
    }
    let exportResponse:any = await ShopsService.exportOrder(Shop.shopid,status,email,itemname,Number(split || 0),dateStart,endDate);
    if(exportResponse.status == 'success'){
      uiHelper.toastr.success('File Created.');
      setDownloadLink('https://admin.tumia.shop/client/exports/'+exportResponse.file);
      console.log('File Link: https://admin.tumia.shop/client/exports/'+exportResponse.file);
    }else{
      uiHelper.toastr.error('Unable to create the file. '+exportResponse.message)
    }
    parent.classList.remove("sk-loading");
  }
  async function updateProductItemName(e:any){
    e.preventDefault();
    const domForm = e.target;
    const oldItemName = domForm.querySelector('select').value;
    const newItemName = domForm.querySelector('input').value;
    if(newItemName.length>0 && oldItemName.length>0){
        // setProductList(newProductList);
        const response:any = await OrdersService.productUpdate(SyncDeclare.ShopVariable.shopid, oldItemName, newItemName)
        if(response.status == 'success'){
            uiHelper.toastr.success(response.message);
            const items:any = await OrdersService.listItems(Shop.shopid);
            setItemList(items);
        }else{
            uiHelper.toastr.error(response.message);
        }
    }
  }
  function getProductItem(e:any){
    const select = e.target || '';
    const itemName = select.value || '';
    const domParent:any =  select.parentNode.parentNode;
    const domInput = domParent.querySelector('input');
    domInput.value = itemName;
  }
  async function getLastUploadDate(){
    const uploadDate:any = await ShopsService.getUploadDate(Shop.shopid);
    if(uploadDate){
      setUploadDate(uiHelper.format.fullDateWithTime(uploadDate));
    }else{
      setUploadDate('');
    }
  }
  function shopModal(user:any){
    SyncDeclare.Modals.ShopDashboard.UserInfo(user);
  }
  async function LoadUpsell(){
    setUpsellList(await UpsellController.list());
  }
  async function RemoveUpsell(e:any){
    await UpsellController.remove(e.uuid);
    LoadUpsell();
  }
  async function UpsellControlleradd(e:any){
    e.preventDefault();
    await UpsellController.add(e);
    LoadUpsell();
  }
  async function importStart(e:any){
    e.preventDefault();
    const response = await ShopsService.importStart();
    console.log('start import',response)
    uiHelper.toastr.info('Import User Message: '+JSON.stringify(response))
  }
  async function importStartOrders(e:any){
    e.preventDefault();
    const response = await ShopsService.importStartOrders();
    console.log('start import',response)
    uiHelper.toastr.info('Import Payout Message: '+JSON.stringify(response))
  }
  async function importStartPayout(e:any){
    e.preventDefault();
    const response = await ShopsService.importStartPayout();
    console.log('start import',response)
    uiHelper.toastr.info('Import Payout Orders Message: '+JSON.stringify(response))
  }
  // Declare Hooks
  useEffect(() => {
    // verify id
    if(SyncDeclare.ShopVariable.shopid==0){
      window.location.href="/shop";
    }
    // Load Function Once
    LoadShops();
    LoadStatusCount();
    GetReportStatus();
    getLastUploadDate();

  }, []);
  // Template
  return (
    <div className="" id="ShopsDashboard" ref={(node:any)=> mainRef = node}>
      <div className="row wrapper wrapper-content animated fadeInRight">
        <div className="col-lg-3">
          <div className="ibox">
            <div className="ibox-title">
                <span className="text-muted small pull-right">Date: <i className="fa fa-clock-o"></i>{uploadDate}</span>
                <h5>Upload JNT File</h5>
            </div>
            <div className={`ibox-content`}>
            {/* <div className={`ibox-content ${uploadState}`}> */}
              <div className="sk-spinner sk-spinner-wave">
                  <div className="sk-rect1"></div>
                  <div className="sk-rect2"></div>
                  <div className="sk-rect3"></div>
                  <div className="sk-rect4"></div>
                  <div className="sk-rect5"></div>
                  extracting
              </div>
              <form method="post" id="lazy-upload" onSubmit={upload}>
                  <div className="fileinput fileinput-new input-group" data-provides="fileinput">
                      <div className="form-control" data-trigger="fileinput">
                          <i className="glyphicon glyphicon-file fileinput-exists"></i>
                          <span className="fileinput-filename"></span>
                      </div>
                      <span className="input-group-addon btn btn-default btn-file">
                          <span className="fileinput-new">Select file</span>
                          <span className="fileinput-exists">Change</span>
                          <input type="file" name="csvFILE" id="csvfile" accept=".xls,.xlsx" required/>
                      </span>
                      <a href="#" className="input-group-addon btn btn-default fileinput-exists"
                          data-dismiss="fileinput">Remove</a>
                  </div>
                  <div className="form-group">
                      <i style={{"color": "red"}}>Upload JnT Xlsx Only</i><br/><br/>
                      <input type="submit" value="Submit" className="btn btn-primary"/><br/><br/>
                      <div className="progress progress-striped active" style={{"display":"none"}}>
                        <div style={{"width": "0%"}} role="progressbar" className="progress-bar">
                            <span className="sr-only">0% Complete (success)</span>
                        </div>
                      </div>
                      <div>
                          <img src="./assets/img/pointdownindex.gif" width="100px" style={{"display": "none"}} className="show-finger-gif"/><br/>
                          <span id="link-download" className="show-finger-gif"></span>
                          <span style={{"display": "none", "color": "green"}} className="show-finger-gif">The link will expire after a minute.</span>
                          <span style={{"display": "none"}} className="show-invalid-text text-danger">Invalid JnT File. Please upload from the JnT file only!</span>
                      </div>
                  </div>
                      <div className="progress progress-striped active">
                          <div style={{"width": UploadLoadingPercent}} role="progressbar" className="progress-bar progress-bar-info">
                              <span className="sr-only">{UploadLoadingPercent} Complete (success)</span>
                          </div>
                      </div>
                      <div className="m-t-sm small">{UploadLoadingPercent} Complete (success)</div>
              </form>

            </div>
          </div>
          <div className="ibox">
            <div className="ibox-title">
                <span className="text-muted small pull-right">Total: {reportStatus.total}</span>
                <h5>Reports</h5>
            </div>
            <div className="ibox-content">
                <div>
                    <span>Delivered</span>
                    <small className="pull-right">{reportStatus.delivered.total}</small>
                </div>
                <div className="progress progress-small">
                    <div style={{width: reportStatus.delivered.percentage+'%'}} className="progress-bar"></div>
                </div>
                <div>
                    <span>Delivering</span>
                    <small className="pull-right">{reportStatus.delivering.total}</small>
                </div>
                <div className="progress progress-small">
                    <div style={{width: reportStatus.delivering.percentage+'%'}} className="progress-bar"></div>
                </div>
                <div>
                    <span>In transit</span>
                    <small className="pull-right">{reportStatus.intransit.total}</small>
                </div>
                <div className="progress progress-small">
                    <div style={{width: reportStatus.intransit.percentage+'%'}} className="progress-bar"></div>
                </div>
                <div>
                    <span>For return</span>
                    <small className="pull-right">{reportStatus.forreturn.total}</small>
                </div>
                <div className="progress progress-small">
                    <div style={{width: reportStatus.forreturn.percentage+'%'}} className="progress-bar"></div>
                </div>
                <div>
                    <span>Returned</span>
                    <small className="pull-right">{reportStatus.returned.total}</small>
                </div>
                <div className="progress progress-small">
                    <div style={{width: reportStatus.returned.percentage+'%'}} className="progress-bar"></div>
                </div>
            </div>
          </div>
          {/* Start */}
          <div className='ibox'>
            <div className="ibox-title">
                <h5>Update Product Name</h5>
            </div>
            <div className={`ibox-content `}>
            {/* <div className={`ibox-content ${uploadState}`}> */}
              <div className="sk-spinner sk-spinner-wave">
                  <div className="sk-rect1"></div>
                  <div className="sk-rect2"></div>
                  <div className="sk-rect3"></div>
                  <div className="sk-rect4"></div>
                  <div className="sk-rect5"></div>
                  extracting
              </div>
              <form role="form" onSubmit={updateProductItemName}>
                  <div className="form-group">
                      <label>Product List:</label>
                      <select name="productList" id="side-bar-productlist" className="form-control input-sm" onChange={getProductItem}>
                        <option value="">Select</option>
                          {
                              itemList.map((productItem: any, index: number)=>{
                                  return(<option value={productItem.item_name} key={index}>{productItem.item_name}</option>)
                              })
                          }
                      </select>
                  </div>
                  <div className='form-group'>
                      <label>Product Name:</label>
                      <div className="input-group" style={{"zIndex": 0}}>
                          <input type="text" placeholder="Product name" name="productname" id="modal-productconfig-itemname" className="form-control input-sm"/>
                          <div className="input-group-btn">
                              <button className="btn btn-lg btn-primary btn-sm" type="submit">
                                  Update
                              </button>
                          </div>
                      </div>
                  </div>
              </form>
                {/* <div className={`alert ${teamCreateError.type} alert-dismissable`} style={{display: teamCreateError.errordisplay}}>
                    <button aria-hidden="true" data-dismiss="alert" className="close" type="button">×</button>
                    {teamCreateError.message}
                </div> */}
            </div>
          </div>
          {/* End */}
          {/* Start */}
          <div className='ibox'>
            <div className="ibox-title">
                <h5>Import Data to New Table</h5>
            </div>
            
            <div className={`ibox-content `}>
            {/* <div className={`ibox-content ${uploadState}`}> */}
              <div className="sk-spinner sk-spinner-wave">
                  <div className="sk-rect1"></div>
                  <div className="sk-rect2"></div>
                  <div className="sk-rect3"></div>
                  <div className="sk-rect4"></div>
                  <div className="sk-rect5"></div>
                  extracting
              </div>
              <div className='form-group'>
                  <label>Import?:</label><br/>
                  
                <form role="form" onSubmit={importStart}>
                  <button className="btn btn-lg btn-primary btn-sm" type="submit">
                      Start Import User
                  </button>
                </form>
                  &nbsp;
                  <form role="form" onSubmit={importStartOrders}>
                    <button className="btn btn-lg btn-primary btn-sm" type="submit">
                        Start Import Order
                    </button>
                  </form>
                  &nbsp;
                  <form role="form" onSubmit={importStartPayout}>
                    <button className="btn btn-lg btn-primary btn-sm" type="submit">
                        Start Import Payout
                    </button>
                  </form>
              </div>
                {/* <div className={`alert ${teamCreateError.type} alert-dismissable`} style={{display: teamCreateError.errordisplay}}>
                    <button aria-hidden="true" data-dismiss="alert" className="close" type="button">×</button>
                    {teamCreateError.message}
                </div> */}
            </div>
          </div>
          {/* End */}
          {/* Start */}
          <div className='ibox'>
            <div className="ibox-title">
                <h5>Upsell</h5>
            </div>
            <div className={`ibox-content ${uploadState}`}>
              <div className="sk-spinner sk-spinner-wave">
                  <div className="sk-rect1"></div>
                  <div className="sk-rect2"></div>
                  <div className="sk-rect3"></div>
                  <div className="sk-rect4"></div>
                  <div className="sk-rect5"></div>
                  extracting
              </div>
              <form role="form" onSubmit={UpsellControlleradd}>
                  <div className='form-group'>
                      <label>Upsell Name:</label>
                      <div className="input-group" style={{"zIndex": 0}}>
                          <input type="text" placeholder="Product name" name="upsell-name" id="upsell-name" className="form-control input-sm"/>
                          <div className="input-group-btn">
                              <button className="btn btn-lg btn-primary btn-sm" type="submit">
                                  Add
                              </button>
                          </div>
                      </div>
                  </div>
                  <div className='form-group'>
                      <label>List:</label>
                      <dl className="small m-b-none">
                          <dd>
                            <ul className="">
                              {
                                UpsellList.map((upsell:any,upsellKey:any)=>{
                                  return(<li key={upsellKey}>{upsell.name}&nbsp;<i className="fa fa-trash" onClick={(e)=>RemoveUpsell(upsell)}></i></li>);
                                })
                              }
                            </ul>
                          </dd>
                      </dl>
                  </div>
              </form>
                {/* <div className={`alert ${teamCreateError.type} alert-dismissable`} style={{display: teamCreateError.errordisplay}}>
                    <button aria-hidden="true" data-dismiss="alert" className="close" type="button">×</button>
                    {teamCreateError.message}
                </div> */}
            </div>
          </div>
          {/* End */}
        </div>
        <div className="col-lg-3">
          <div className="ibox">
            <div className="ibox-title">
                <h5>Assigned To</h5>
            </div>
            <div className={`ibox-content ${uploadState}`}>
              <div className="sk-spinner sk-spinner-wave">
                  <div className="sk-rect1"></div>
                  <div className="sk-rect2"></div>
                  <div className="sk-rect3"></div>
                  <div className="sk-rect4"></div>
                  <div className="sk-rect5"></div>
                  extracting
              </div>
              <div className="row">
                <div className="col-lg-12">

                  <select className="form-control" id="teamsAssign">
                    {
                      TeamList.map((team:any,index:number)=>{
                        return(<option value={team.teamsid} key={index}>{team.name}</option>)
                      })
                    }

                  </select>
                  <button type="button" className="btn btn-w-m btn-primary btn-block m-t" onClick={assignTeam}>Assign</button>
                </div>
                <div className="col-lg-12 m-t">
                  <h3>Teams:</h3>
                  <ul className="tag-list" style={{"padding": "0"}}>
                    {
                      AssignTeams.map((assign:any,index:number)=>{
                        return(<li key={index}><a href="#" onClick={()=>removeAssign(assign)}>{assign.name}&nbsp;<i className="fa fa-trash"></i></a></li>)
                      })
                    }
                  </ul>
                </div>

              </div>
            </div>
          </div>
          {/* Start */}
          <div className="ibox">
            <div className="ibox-title">
                <h5>Exports</h5>
            </div>
            <div className="ibox-content">
              <div className="sk-spinner sk-spinner-wave">
                  <div className="sk-rect1"></div>
                  <div className="sk-rect2"></div>
                  <div className="sk-rect3"></div>
                  <div className="sk-rect4"></div>
                  <div className="sk-rect5"></div>
                  extracting
              </div>
              <form onSubmit={exportToXLSX}>
                <div className="form-group">
                    <label>Date Start</label>
                    <input type="date" id="export_start" name="export_start" placeholder="Start Date" className="form-control"/>
                </div>
                <div className="form-group">
                    <label>Date End</label>
                    <input type="date" id="export_end" name="export_end" placeholder="End Date" className="form-control"/>
                </div>
                <div className="form-group">
                    <label>Media Buyer</label>
                    <select name="productList" id="export_email" className="form-control input-sm">
                      <option value="">All</option>
                        {
                            TeamListMember.map((member: any, index: number)=>{
                                return(<option value={index} key={index}>{member.firstname} {member.lastname}</option>)
                            })
                        }
                    </select>
                </div>
                <div className="form-group">
                    <label>Product List:</label>
                    <select name="productList" id="export_item" className="form-control input-sm">
                      <option value="">All</option>
                        {
                            itemList.map((productItem: any, index: number)=>{
                                return(<option value={productItem.item_name} key={index}>{productItem.item_name}</option>)
                            })
                        }
                    </select>
                </div>
                <div className="form-group">
                    <label>Status</label>
                    <select name="status" id="export_status" className="form-control">
                        <option value="" key={-1}>All</option>
                        {
                            statusList.map((statusItem: any, index: number)=>{
                                return(<option value={statusItem.order_status} key={index}>{statusItem.order_status}</option>)
                            })
                        }
                    </select>
                </div>
                <div className="form-group">
                    <label>Split By</label>
                    <select name="status" id="export_split" className="form-control">
                        <option value="0" key={0}>No split</option>
                        <option value="1000" key={1}>1000 each</option>
                        <option value="2000" key={2}>2000 each</option>
                    </select>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-w-m btn-primary btn-block m-t">Export</button>
                </div>
              </form>
              <a href={downloadLink} target="blank" style={{"display": downloadLink.length>0 ? 'block' : 'none'}}>Click to download</a>
            </div>
          </div>
          {/* End */}
          {/* Start */}
          <div className="ibox" style={{"display": "none"}}>
            <div className="ibox-title">
                <h5>Assigned To</h5>
            </div>
            <div className={`ibox-content ${uploadState}`}>
              <div className="sk-spinner sk-spinner-wave">
                  <div className="sk-rect1"></div>
                  <div className="sk-rect2"></div>
                  <div className="sk-rect3"></div>
                  <div className="sk-rect4"></div>
                  <div className="sk-rect5"></div>
                  extracting
              </div>
              <div className="row">
                <div className="col-lg-12">

                  <div className="fb-login-button" data-width="" data-size="large" data-button-type="continue_with" data-layout="rounded" data-auto-logout-link="true" data-use-continue-as="false"></div>
                </div>

              </div>
            </div>
          </div>
          {/* End */}
        </div>
        <div className="col-lg-6">
          <div className="ibox">
            <div className="ibox-title">
                <h5>Members ( {TeamListMember.length} )</h5>
            </div>
            <div className="ibox-content">
                <div className="table-responsive" style={{"height":"75vh"}}>
                    <table className="table profile-product">
                        <tbody>
                          {
                            TeamListMember.map((teamMember:any, keyCount:any)=>{
                              return(
                                <tr key={keyCount}>
                                  <td width="90">
                                      <div className="cart-product-imitation">
                                        <img className='profile-square' src={uiHelper.assets.getProfilePic(teamMember.profilePic) ||  Assets.profileIcon}/>
                                      </div>
                                  </td>
                                  <td className="profile-desc">
                                      <h3>
                                        <a href="#" className="text-navy" data-toggle="modal" data-target="#ShopModal" onClick={()=>shopModal(teamMember)}>
                                            {teamMember.firstname} {teamMember.lastname}
                                        </a>
                                      </h3>
                                      <p className="small">
                                        {teamMember.membertype} <br/>
                                        ( {teamMember.teamname} )
                                      </p>
                                      <dl className="small m-b-none">
                                          <dt>Product lists</dt>
                                          <dd>
                                            <ul className="">
                                              {
                                                userProductListFormat(teamMember.itemlist).map((products:string,productKey:any)=>{
                                                  return(<li>{products}&nbsp;<i className="fa fa-trash" onClick={(e)=>removeProductAssign(products)}></i></li>);
                                                })
                                              }
                                            </ul>
                                          </dd>
                                      </dl>
                                  </td>
                                  <td>
                                    <label>Products</label>
                                    <select className="form-control productList">
                                      {
                                        itemList.map((item:any,index:number)=>{
                                          return(<option value={item.item_name} key={index}>{item.item_name}</option>)
                                        })
                                      }
                                    </select>
                                    <input type="hidden" className='emailToBeAssign' value={teamMember.email}/>
                                    <button type="button" className="btn btn-w-m btn-primary btn-block m-t" onClick={productAssign}>Assign</button>
                                  </td>
                                </tr>
                              );
                            })
                          }
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
        </div>
      </div>




    </div>
  );
}

export default ShopsDashboard;
