import React, { useState, useEffect } from 'react';

// Static CSS
import '../App.css';

// Declare Modules
import Assets from './module/Assets';

import uiHelper from './module/uiHelper';
import SyncDeclare from '../SyncDeclare';
import ShopsService from './module/Shops.service';
import TeamsService from './module/Teams.service';
import OrdersService from './module/Orders.service';
import PayoutService from './module/Payout.service';
import { useHistory } from 'react-router-dom';

function PayoutDashboard() {
  // Static Assets
  const logo = Assets.logo;
  const Shop = SyncDeclare.ShopVariable;
  const skipColumn = ['raw_data','item_cost','ads_spent','shopify_cost','others','updatedAt','createdAt'];
  const history = useHistory();

  // Declare Variable
  let [loading, setLoading]:any = useState('');
  let [TransactionHistory, setTransactionHistory]:any[] = useState([]);
  let [Reports, setReports]:any = useState({});
  let [RangeDate, setRangeDate]:any = useState('');
  let mainRef:any;

  async function LoadHistory(){
    const list = await PayoutService.getHistory();
    setTransactionHistory(list);
    SyncDeclare.TemplateHeader('', 'Billing', true);
  }
  function setShop(e:any){

  }
  function View(transaction:any){
    setLoading('sk-loading');
    setReports(JSON.parse(transaction.raw_data || '{}'));
    setLoading('');
  }
  async function Export(transid:any){
    setLoading('sk-loading');
    const response:any = await ShopsService.exportOrderPaid(transid);
    uiHelper.toastr.info('Downloading '+response.file);
    window.open('https://admin.tumia.shop/client/exports/'+response.file);
    setLoading('');
  }
  function CheckProductUser(reports:any){
    let lists = [];
    let _itemList = reports.item_total || [];
    let _itemcostList = (reports.item_cost || '').split('|');
    for(let key in _itemList){
        let filter = _itemcostList.filter((item:any)=>{
            return item.includes(key);
        });
        lists.push({
            name: key,
            total: _itemList[key], 
            cost: Number((filter[0] || '0').replace(key+':',''))
        })
    }
    SyncDeclare.PayoutMediaBuyer(lists);
  }
  function CheckProductUserForRTS(reports:any){
    let lists = [];
    let _itemList = reports.item_rts_total || [];
    let _itemcostList = (reports.item_cost || '').split('|');
    for(let key in _itemList){
        let filter = _itemcostList.filter((item:any)=>{
            return item.includes(key);
        });
        lists.push({
            name: key,
            total: _itemList[key], 
            cost: Number((filter[0] || '0').replace(key+':',''))
        })
    }
    SyncDeclare.PayoutMediaBuyer(lists,'RTS');
  }
  function currencyFormat(num:any) {
    return 'PHP ' + (Number(num || 0).toFixed(2)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  function FormatRow(row:any){
    let formatRow = [];
    for(const column in row){
        if(skipColumn.indexOf(column)<0){
            if(['createdAt','updatedAt'].indexOf(column)>=0){
                formatRow.push({column, value: row[column] != null ? uiHelper.format.fullDateWithTime(row[column]) : ""});
            }else{
                formatRow.push({column, value: row[column]});
            }
        }
    }
    return formatRow;
  }
  function FormatHeaders(row:any){
    let formatRow = [];
    if(row.length>0){
        let row1 = row[0]
        for(const column in row1){
            if(skipColumn.indexOf(column)<0){
                formatRow.push(column);
            }
        }
    }
    return formatRow;
  }
  // Declare Hooks
  useEffect(() => {
    // verify id
    if(SyncDeclare.ShopVariable.shopid==0){
    //   window.location.href="/payout";
    }
    // Load Function Once
    LoadHistory();
    // LoadReportsByProduct('','','');
  }, []);
  // Template
  return (
    <div className="" id="PayoutDashboard" ref={(node:any)=> mainRef = node}>
      {/* start */}
      <div className="row">
        <div className="col-sm-6">
            <div className="ibox float-e-margins">
                <div className="ibox-title">
                    <h5>History</h5>
                </div>
                <div className="ibox-content custom-table">
                    <table className="table table-striped">
                        <thead>
                        <tr key={-1} >
                            <th key={-1}>#</th>
                            <th>Transaction #</th>
                            <th>Name</th>
                            <th>Date Range</th>
                            <th>Shop</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                                TransactionHistory.map((order:any, transactionIndex:any)=>{
                                    return(
                                        <tr key={transactionIndex}>
                                            <td>{(transactionIndex+1)}</td>
                                            {
                                                FormatRow(order).map((object:any, i:any)=>{
                                                    return(
                                                    <td data-toggle="tooltip" data-placement="left" title={object.column} key={i}>
                                                        {
                                                            object.column == 'payout_transid'?
                                                            <a href={"https://admin.tumia.shop/client/billing/?id="+object.value} target='blank'>{object.value}</a>
                                                            :object.value
                                                        }
                                                    </td>
                                                    )
                                                })
                                            }
                                            <td>
                                                <button className='btn btn-outline btn-primary' onClick={()=>View(order)}>View</button>&nbsp;
                                                <button className='btn btn-outline btn-info' onClick={()=>Export(order.payout_transid)}>Export</button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="col-sm-6">
            <div className="ibox float-e-margins">
                <div className="ibox-title">
                    <h5>Transaction Information</h5>
                </div>
                <div className={`ibox-content ${loading}`}>
                    <div className="sk-spinner sk-spinner-wave">
                        <div className="sk-rect1"></div>
                        <div className="sk-rect2"></div>
                        <div className="sk-rect3"></div>
                        <div className="sk-rect4"></div>
                        <div className="sk-rect5"></div>
                        Loading
                    </div>
                    <div className="row">
                        <div className="col-sm-6 untouch">
                            <div className="form-group">
                                <label>Delivered Value:</label>
                                <input type="text" id="payout_delivered_value" value={currencyFormat(Reports.delivered_value)} disabled name="delivered_value" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>Delivered Shipping Cost:</label>
                                <input type="text" id="payout_delivered_shipping_cost" value={currencyFormat(Reports.delivered_shipping_cost)} disabled name="delivered_shipping_cost" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>COD Fee:</label>
                                <input type="text" id="payout_cod_fee" value={currencyFormat(Reports.cod_fee)} disabled name="cod_fee" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>VAT Fee:</label>
                                <input type="text" id="payout_vat_fee" value={currencyFormat(Reports.vat_fee)} disabled name="vat_fee" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>Returned Shipping Cost:</label>
                                <input type="text" id="payout_returned_shipping_cost" value={currencyFormat(Reports.returned_shipping_cost )} disabled name="returned_shipping_cost" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>Product Cost:</label>
                                <input type="text" id="payout_product_cost" value={currencyFormat(Reports.product_cost)} disabled name="product_cost" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>Net Profit:</label>
                                <input type="text" id="payout_net_profit" value={currencyFormat(Reports.net_profit)} disabled name="net_profit" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>Share({(Reports.share_percent || 0.2)*100}%): <i className="fa fa-question-circle" data-toggle="modal" data-target="#payout_formula"></i></label>
                                <input type="text" id="payout_share" value={currencyFormat(Reports.share)} disabled name="share" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>RTS Rate (<span className={Reports.rts_rate_percent < 30 ? "text-navy" : "text-warning"}>{Reports.rts_rate_percent || 0}%</span>): &nbsp;
                                <small className="text-warning">{Reports.rts_rate_percent}% of the total RTS</small>
                                &nbsp;<i className="fa fa-question-circle" onClick={()=>CheckProductUserForRTS(Reports)} data-toggle="modal" data-target="#payout_mediabuyer_itemcost"></i>
                                </label>
                                <input type="text" id="payout_share" value={currencyFormat(Reports.rts_rate_cost)} disabled name="share" className="form-control"/>
                            </div>
                        </div>
                        <div className="col-sm-6 untouch">
                            <div className="form-group">
                                <label>Label Name:</label>
                                <input type="text" id="payout_label" disabled value={Reports.label || ""} name="payout_label" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>Item Cost: <i className="fa fa-question-circle" onClick={()=>CheckProductUser(Reports)} data-toggle="modal" data-target="#payout_mediabuyer_itemcost"></i></label>
                                <input type="text" id="payout_item_cost" disabled value={currencyFormat(Reports.product_cost)} name="end_date" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>FB Ad Spent:</label>
                                <input type="text" id="payout_adspent" disabled value={currencyFormat(Reports.adspent)} name="start_date" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>Shopify Cost:</label>
                                <input type="text" id="payout_shopify_cost" disabled value={currencyFormat(Reports.shopifycost)} name="end_date" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>Other Expenses:</label>
                                <input type="text" id="payout_others" disabled value={currencyFormat(Reports.others)} name="end_date" className="form-control"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      {/* end */}
    </div>
  );
}

export default PayoutDashboard;
