import { Requests } from "./Request";

export default class PayoutService{
    static getReportByProduct(shopid:Number, status:string, startdate:string, enddate:string){
        const body = {
            id: shopid,
            s: status,
            sd: startdate,
            ed: enddate
        }
        return Requests.Post('/payout/report/products',body, true);
    }
    static saveReports(shopid:Number, label:string, item_cost:string, ads_spent:string, shopify_cost:string, others:string, raw_data:string, email:string,startdate:string,enddate:string,billed_date:string){
        const body = {
            id: shopid,
            label: label,
            item_cost: item_cost,
            ads_spent: ads_spent,
            shopify_cost: shopify_cost,
            others: others,
            raw_data: JSON.stringify(raw_data),
            email: email,
            sd: startdate,
            ed: enddate,
            bd: billed_date
        }
        return Requests.Post('/payout/report/save',body, true);
    }
    static saveReportsOthers(shopid:Number, label:string, raw_data:string, email:string,startdate:string,billed_date:string){
        const body = {
            id: shopid,
            label: label,
            item_cost: '',
            ads_spent: '',
            shopify_cost: '',
            others: '',
            raw_data: JSON.stringify(raw_data),
            email: email,
            sd: startdate,
            ed: '',
            bd: billed_date
        }
        return Requests.Post('/payout/report/others/save',body, true);
    }
    static getReportsWithPaid(shopid:Number, item:string, email:string,startdate:string,enddate:string){
        const body = {
            id: shopid,
            item: item,
            e: email,
            sd: startdate,
            ed: enddate
        }
        return Requests.Post('/payout/report/totals/paid',body, true);
    }
    static getTransactionHistory(shopid:Number){
        return Requests.Get('/payout/history?id='+shopid, true);
    }
    static calculate(shopid: Number, itemname:string, email:string, startdate: string, enddate: string){
        const body = {
            id: shopid,
            itemname: itemname,
            email: email,
            sd: startdate,
            ed: enddate
        }
        return Requests.Post('/payout/reports/calculate', body, true);
    }
    static getSOA(shopid: Number, startdate: string, enddate: string){
        const body = {
            id: shopid,
            sd: startdate,
            ed: enddate
        }
        return Requests.Post('/payout/reports/soa', body, true);
    }
    static getHistory(){
        return Requests.Get('/payout/history/me', true);
    }
    static getReportsWithPaidByUser(shopid:Number, item:string, startdate:string,enddate:string){
        const body = {
            id: shopid,
            item: item,
            e: '',
            sd: startdate,
            ed: enddate
        }
        return Requests.Post('/payout/report/totals/paid/me',body, true);
    }
    static update(transid:Number, billdate:string){
        const body = {
            id: transid,
            date: billdate
        }
        return Requests.Post('/payout/update',body, true);
    }
    static getHistoryList(shopid:Number, startdate:string, enddate:string){
        const body = {
            id: shopid,
            startdate: startdate,
            enddate: enddate
        }
        return Requests.Post('/payout/history/list',body, true);
    }
}