import React, { useState, useEffect } from 'react';

// Static CSS
import '../App.css';

// Declare Modules
import Assets from './module/Assets';

import uiHelper from './module/uiHelper';
import SyncDeclare from '../SyncDeclare';
import PayoutService from './module/Payout.service';
function PaymentSOA() {
  // Static Assets
  const logo = Assets.logo;
  const Shop = SyncDeclare.ShopVariable;
  const skipColumn = ['delivered_item_total','delivered_total','shipping_item_totals','delivered_shipping_cost'];

  // Declare Variable
  let [loading, setLoading]:any = useState('sk-loading');
  let [ItemList, setItemList]:any[] = useState([]);
  let [COD,setCOD] = useState({
    delivered_value: 0,
    shipping_cost: 0,
    collection_fee: 0,
    vat_fee: 0,
    total: 0,
});
  let mainRef:any;

  async function Calculate(e:any){
    setLoading('sk-loading');
    let dom = e.target.parentNode.parentNode;
    let sd = dom.querySelector('#payout_startdate').value;
    let ed = dom.querySelector('#payout_enddate').value;
    if(sd==='' || ed===''){
        uiHelper.toastr.error('Please add the following dates.')
        return;
    }
    const response:any = await PayoutService.getSOA(Shop.shopid, sd, ed);
    let newList = [];
    let sumcod = 0;
    let sumshipping = 0;
    let sumcollection = 0;
    let sumvat = 0;
    let pieData = [];
    for(let item of response){
        let sum = 0;
        sum += Number(item.delivered_value || 0);
        sum -= Number(item.shipping_cost || 0);
        sum -= Number(item.collection_fee || 0);
        sum -= Number(item.vat_fee || 0);

        sumcod += Number(item.delivered_value || 0);
        sumshipping += Number(item.shipping_cost || 0);
        sumcollection += Number(item.collection_fee || 0);
        sumvat += Number(item.vat_fee || 0);

        let newItem = {
            item_name: item.item_name,
            delivered_value: item.delivered_value,
            shipping_cost: item.shipping_cost,
            collection_fee: item.collection_fee,
            vat_fee: item.vat_fee,
            total: sum,
        }
        pieData.push({name: item.item_name, value: sum});
        newList.push(newItem);
    }
    let newItem = {
        item_name: "TOTAL",
        delivered_value: sumcod,
        shipping_cost: sumshipping,
        collection_fee: sumcollection,
        vat_fee: sumvat,
        total: sumcod - sumshipping - sumcollection - sumvat,
    }
    setCOD(newItem);
    newList.push(newItem);
    setItemList(newList);
    uiHelper.c3('#pie',pieData);
    setLoading('');
  }
  function currencyFormat(num:any) {
    return 'PHP ' + (Number(num || 0).toFixed(2)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  function FormatRow(row:any){
    let formatRow = [];
    for(const column in row){
        if(skipColumn.indexOf(column)<0){
            if(['createdAt','updatedAt'].indexOf(column)>=0){
                formatRow.push({column, value: row[column] != null ? uiHelper.format.fullDateWithTime(row[column]) : ""});
            }else{
                formatRow.push({column, value: row[column]});
            }
        }
    }
    return formatRow;
  }
  function FormatHeaders(row:any){
    let formatRow = [];
    if(row.length>0){
        let row1 = row[0]
        for(const column in row1){
            if(skipColumn.indexOf(column)<0){
                formatRow.push(column);
            }
        }
    }
    return formatRow;
  }
  // Declare Hooks
  useEffect(() => {
    // verify id
    if(SyncDeclare.ShopVariable.shopid==0){
      window.location.href="/payout";
    }
    // Load Function Once
    // LoadReportsByProduct('','','');
  }, []);
  // Template
  return (
    <div className="" id="PayoutDashboardSOA" ref={(node:any)=> mainRef = node}>
      {/* start */}
      <div className="row">
        <div className="col-md-4">
            <div className="ibox float-e-margins">
                <div className="ibox-title">
                    <h5>Custom Filter</h5>
                </div>
                <div className="ibox-content">

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label>Date Start</label>
                                <input type="date" id="payout_startdate" name="start_date" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>Date End</label>
                                <input type="date" id="payout_enddate" name="end_date" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>&nbsp;</label>
                                <button type="button" className="btn btn-primary" onClick={Calculate}> <i className="fa fa-check-circle"></i> Filter</button>
                            </div>
                        </div>
                </div>
                </div>
            </div>
            {/* End */}
            {/* Start */}
            <div className="ibox float-e-margins" style={{"display": "none"}}>
                <div className="ibox-title">
                    <h5>Calculations</h5>
                    <div className="ibox-tools">
                        <a className="collapse-link">
                            <i className="fa fa-chevron-up"></i>
                        </a>
                        <a className="close-link">
                            <i className="fa fa-times"></i>
                        </a>
                    </div>
                </div>
                <div className="ibox-content">

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label>COD Transaction</label>
                                <input type="text" id="payout_startdate" name="start_date" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>Collection Fee( 2.5% )</label>
                                <input type="text" id="payout_enddate" name="end_date" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>VAT Fee( 12% )</label>
                                <input type="text" id="payout_enddate" name="end_date" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>Shipping Fee</label>
                                <input type="text" id="payout_enddate" name="end_date" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>RTS Fee</label>
                                <input type="text" id="payout_enddate" name="end_date" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>COD Payable</label>
                                <input type="text" id="payout_enddate" name="end_date" className="form-control"/>
                            </div>
                        </div>
                </div>
                </div>
            </div>
            {/* End */}
        </div>

        <div className='col-sm-8'>
            <div className="ibox float-e-margins">
                <div className="ibox-title">
                    <h5>Calculations</h5>
                </div>
                <div className={`ibox-content ${loading}`}>
                    <div className="sk-spinner sk-spinner-wave">
                        <div className="sk-rect1"></div>
                        <div className="sk-rect2"></div>
                        <div className="sk-rect3"></div>
                        <div className="sk-rect4"></div>
                        <div className="sk-rect5"></div>
                        Loading
                    </div>
                    <div className='row'>
                        <div className='col-sm-8'>
                            <div id="pie"></div>
                        </div>
                        <div className='col-sm-2'>
                             <ul className="stat-list">
                                <li>
                                    <small>COD Transaction: </small>
                                    <br/><span className='text-navy'>{currencyFormat(COD.delivered_value)}</span>
                                </li>
                                <li>
                                    <small>Shipping Fee: </small>
                                    <br/><span className='text-navy'>{currencyFormat(COD.shipping_cost)}</span>
                                </li>
                                <li>
                                    <small>Collection Fee: </small>
                                    <br/><span className='text-navy'>{currencyFormat(COD.collection_fee)}</span>
                                </li>
                                <li>
                                    <small>VAT Fee: </small>
                                    <br/><span className='text-navy'>{currencyFormat(COD.vat_fee)}</span>
                                </li>
                                <li>
                                    <small>COD Payable: </small>
                                    <br/><span className='text-navy'>{currencyFormat(COD.total)}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      </div>
      {/* end */}
      {/* start */}
    <div className='row'>
    <div className="col-md-12">
            <div className="ibox float-e-margins">
                <div className="ibox-title">
                    <h5>Statement of Account</h5>
                </div>
                <div className={`ibox-content ${loading}`}>
                    <div className="sk-spinner sk-spinner-wave">
                        <div className="sk-rect1"></div>
                        <div className="sk-rect2"></div>
                        <div className="sk-rect3"></div>
                        <div className="sk-rect4"></div>
                        <div className="sk-rect5"></div>
                        Loading
                    </div>
                    <div className="row">
                        <div className="col-lg-12 custom-table-bootstrap">
                            <table className="table table-stripped">
                                <thead>
                                <tr className='text-center'>
                                    <th key={-1}>#</th>
                                    <th key={1}>Item Name</th>
                                    <th key={2}>COD Transaction</th>
                                    <th key={3}>Shipping Fee</th>
                                    <th key={4}>Collection Fee</th>
                                    <th key={5}>VAT Fee</th>
                                    <th key={6}>COD Payable</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    ItemList.map((item:any, transactionIndex:any)=>{
                                        return(
                                            <tr key={transactionIndex} className={ItemList.length === (transactionIndex+1) ? 'solid' : ''}>
                                                <td>{ItemList.length-1 > (transactionIndex) ? (transactionIndex+1) : ''}</td>
                                                {
                                                    FormatRow(item).map((object:any, i:any)=>{
                                                        return(
                                                            <td data-toggle="tooltip" data-placement="left" title={object.column} key={i}>
                                                                {typeof object.value === 'string' ? (object.value) : currencyFormat(object.value)}
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      {/* end */}
    </div>
  );
}

export default PaymentSOA;
