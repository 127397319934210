import { Requests } from "./Request";

export default class OrdersService{
    static list(email:string, itemname:string, status:string, searchby:string, search: string,shopid: Number, offset: Number, limit: Number, startdate: string, enddate: string, sort: Boolean){
        const body = {
            email: email,
            search: search,
            id: shopid,
            ofs: offset,
            lim: limit,
            asc: sort,
            itemname: itemname,
            s: status,
            searchby: searchby,
            sd: startdate,
            ed: enddate
        }
        return Requests.Post('/orders/lists',body, true);
    }
    static listItems(shopid: Number){
        return Requests.Get('/orders/lists/items?id='+shopid,true);
    }
    static getOrderTimeLimit(){
        return Requests.Get('/orders/limit/date',true);
    }
    static listStatus(shopid: Number){
        return Requests.Get('/orders/lists/status?id='+shopid,true);
    }
    static reportStatus(shopid: Number){
        return Requests.Get('/orders/report/status?id='+shopid,true);
    }
    static listTotal(email:string, itemname:string, status:string, searchby:string, search: string,shopid: Number, startdate: string, enddate: string){
        const body = {
            email: email,
            id: shopid,
            itemname: itemname,
            s: status,
            searchby: searchby,
            search: search,
            sd: startdate,
            ed: enddate
        }
        return Requests.Post('/orders/lists/counts', body, true);
    }
    static reportsShare(shopid: Number, itemname:string, email:string, startdate: string, enddate: string){
        const body = {
            id: shopid,
            itemname: itemname,
            email: email,
            sd: startdate,
            ed: enddate
        }
        return Requests.Post('/orders/reports/share', body, true);
    }
    static reportsTotal(shopid: Number, itemname:string, email:string, startdate: string, enddate: string){
        const body = {
            id: shopid,
            itemname: itemname,
            email: email,
            sd: startdate,
            ed: enddate
        }
        return Requests.Post('/orders/reports/totals', body, true);
    }
    static productUpdate(shopid: Number, itemname:string, newItemName: string){
        const body = {
            id: shopid,
            itemname: itemname,
            nitemname: newItemName
        }
        return Requests.Post('/orders/product/item/update', body, true);
    }
    static checksList(shopid: Number, phonenumbers:any[], itemname: string, datestart: string, dateend: string){
        const body = {
            id: shopid,
            pn: phonenumbers,
            itemname: itemname,
            ds: datestart,
            de: dateend
        }
        return Requests.Post('/orders/check/list', body, true);
    }
    static deepChecksList(shopid: Number, phonenumbers:any[], itemname: string, datestart: string, dateend: string){
        const body = {
            id: shopid,
            pn: phonenumbers,
            itemname: itemname,
            ds: datestart,
            de: dateend
        }
        return Requests.Post('/orders/deepcheck/list', body, true);
    }
    static getOrderReports(shopid: Number, datestart: string, dateend: string){
        const body = {
            id: shopid,
            ds: datestart,
            de: dateend
        }
        return Requests.Post('/orders/check/list', body, true);
    }
    static getReports(shopid: Number, itemname:string, startdate: string, enddate: string){
        const body = {
            id: shopid,
            itemname: itemname,
            sd: startdate,
            ed: enddate
        }
        return Requests.Post('/orders/reports/me', body, true);
    }
    static getReportsTotal(shopid: Number, itemname:string, startdate: string, enddate: string){
        const body = {
            id: shopid,
            itemname: itemname,
            sd: startdate,
            ed: enddate
        }
        return Requests.Post('/orders/reports/me/totals', body, true);
    }
    static listByUser(itemname:string, status:string, searchby:string, search: string,shopid: Number, offset: Number, limit: Number, startdate: string, enddate: string, sort: string){
        const body = {
            search: search,
            id: shopid,
            ofs: offset,
            lim: limit,
            asc: sort,
            itemname: itemname,
            s: status,
            searchby: searchby,
            sd: startdate,
            ed: enddate
        }
        return Requests.Post('/orders/lists/me',body, true);
    }
    static listTotalByUser(email:string, itemname:string, status:string, searchby:string, search: string,shopid: Number, startdate: string, enddate: string){
        const body = {
            id: shopid,
            email: email,
            itemname: itemname,
            s: status,
            searchby: searchby,
            search: search,
            sd: startdate,
            ed: enddate
        }
        return Requests.Post('/orders/lists/counts/me', body, true);
    }
    static listItemsByUser(shopid: Number){
        return Requests.Get('/orders/lists/items/me?id='+shopid,true);
    }
}