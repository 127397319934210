import { Requests } from "./Request";

export default class SmsService{
    static list(){
        return Requests.Get('/sms/template/list',true);
    }
    static templateList(id:Number){
        return Requests.Post('/sms/template/list',{id},true);
    }
    static templateSave(id:Number, title:string, text:string){
        return Requests.Post('/sms/template/save',{id, title, text},true);
    }
    static templateUpdate(id:Number, title:string, text:string, sid:Number){
        return Requests.Post('/sms/template/update',{id, title, text, sid},true);
    }
    static templateSelected(id:Number, sid:Number){
        return Requests.Post('/sms/template/selected',{id, sid},true);
    }
}