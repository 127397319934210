export function setMultiMediaBuyerList(lists:any){
    let list = [];
    for(let user of lists){
        list.push({label: user.lastname+" "+user.firstname+" - "+user.teamname, value: user});
    }
    return list;
}
export function UpdateStateWithObject(e:any, obj:any, key:string,setup:any,){
    const format:any = obj;
    format[key] = e.target.value;
    setup({...obj, format})
}