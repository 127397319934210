import React, { useState, useEffect } from 'react';


import SyncD from './SyncDeclare';
import Assets from './pages/module/Assets';
import uiHelper from './pages/module/uiHelper';
import ProductsService from './pages/module/Products.service';
import SyncDeclare from './SyncDeclare';


function SideBar(){
    // declare Static Assets
    let SLPLogo = Assets.SLPLogo;
    // declare modules variables

    // declare variables
    let [profileImg, setProfileImg] = useState<any>(Assets.defaultProfilePic);
    let [shop, setShop]:any = useState('');
    let [productList, setProductList] = useState([]);
    let [productConfigList, setProductConfigList] = useState([]);

    let [SideBarOpen, setSideBarOpen] = useState(false);
    let [SideBarLoading, setSideBarLoading] = useState(true);
    let SideBarDom:HTMLElement|undefined|null;
    let BarChart:HTMLElement|undefined|null;

    // declare functions
    async function SaveData(event: any){
        event.preventDefault();
        let domElement = event.target;
        let parentElement = domElement.parentNode.parentNode;
        let buttonText = domElement.textContent;
        let inputElement = parentElement.querySelectorAll('.input-sm');
        if(inputElement.length>0){
            let itemname = inputElement[0].value || '';
            let labelname = inputElement[2].value || '';
            let product_cost = Number(inputElement[3].value || 0);
            let ads_spent = Number(inputElement[4].value || 0);
            if(buttonText.trim() == 'New'){
                if(isNaN(product_cost) || isNaN(ads_spent) || itemname.length == 0 || labelname.length == 0){
                    uiHelper.toastr.error('Product cost or ads spent must be a number. All field are required.');
                }else{
                    const response:any = await ProductsService.add(itemname,labelname,product_cost,ads_spent,shop.shopid);
                    if(response.status=='success'){
                        uiHelper.toastr.success(response.message);
                    }else{
                        uiHelper.toastr.error(response.message);
                    }
                }
            }else if(buttonText.trim() == 'Save'){
                if(isNaN(product_cost) || isNaN(ads_spent) || itemname.length == 0 || labelname.length == 0){
                    uiHelper.toastr.error('Product cost or ads spent must be a number. All field are required.');
                }else{
                    const response:any = await ProductsService.update(itemname,labelname,product_cost,ads_spent,shop.shopid);
                    if(response.status=='success'){
                        uiHelper.toastr.success(response.message);
                    }else{
                        uiHelper.toastr.error(response.message);
                    }
                }
            }else if(buttonText.trim() == 'Set'){
                if(isNaN(product_cost) || isNaN(ads_spent) || itemname.length == 0 || labelname.length == 0){
                    uiHelper.toastr.error('Product cost or ads spent must be a number. All field are required.');
                }else{
                    const response:any = await ProductsService.set(itemname,labelname,product_cost,ads_spent,shop.shopid);
                    if(response.status=='success'){
                        uiHelper.toastr.success(response.message);
                    }else{
                        uiHelper.toastr.error(response.message);
                    }
                }
            }
        }
        // if(status===1)
        //     uiHelper.toastr.success("Scholar's Data Has Been Saved.");
        // else
        //     uiHelper.toastr.error("Something went wrong.");
    }
    async function LoadProductConfigList(shopid:any, item_name:any){
        const response:any = await ProductsService.list(shopid, item_name);
        setProductConfigList(response);
        if(SideBarDom){
            const domProductList:any = SideBarDom.querySelector('#side-bar-productconfiglist');
            const domLabel:any = SideBarDom.querySelector('#productconfig-label');
            const domCost:any = SideBarDom.querySelector('#productconfig-cost');
            const domAdsSpent:any = SideBarDom.querySelector('#productconfig-adspent');
            if(domProductList){
                for(let configIndex in response){
                    if(response[configIndex].used==1){
                        domProductList.value = configIndex;
                        domLabel.value = response[configIndex].label;
                        domCost.value = response[configIndex].product_cost;
                        domAdsSpent.value = response[configIndex].ads_spent;
                    }
                }
            }
        }
    }
    async function LoadData(data: any){
        // uiHelper.toastr.success(`Open Details For [${data.ign}]`);
        setSideBarLoading(true);
        if(SideBarDom){
            if(!SideBarDom.classList.contains('sidebar-open')) {
                setSideBarOpen(false);
            }
            setProductList(data.itemList);
            setShop(data.shop);
            setTimeout(()=>setSideBarOpen(true),200);
        }

        setTimeout(()=>{
            setSideBarLoading(false);
            if(data.itemList.length>0){
                LoadProductConfigList(data.shop.shopid, data.itemList[0].item_name);
            }
        },1000);
    }
    function SideBarToggle(){
        if(SideBarDom){
            if(!SideBarDom.classList.contains('sidebar-open')) setSideBarOpen(false);
            setSideBarOpen(!SideBarOpen);
        }
    }
    function changeProductItem(e:any){
        const itemname = e.target.value || '';
        if(itemname.length>0){
            setSideBarLoading(true);
            LoadProductConfigList(shop.shopid, itemname);
            setSideBarLoading(false);
        }
    }
    function changeProductConfig(e:any){
        const configIndex = e.target.value || 0;
        setSideBarLoading(true);
        const config:any = productConfigList[configIndex];
        if(SideBarDom && config.label){
            const domProductList:any = SideBarDom.querySelector('#side-bar-productconfiglist');
            const domLabel:any = SideBarDom.querySelector('#productconfig-label');
            const domCost:any = SideBarDom.querySelector('#productconfig-cost');
            const domAdsSpent:any = SideBarDom.querySelector('#productconfig-adspent');
            if(domProductList){
                domLabel.value = config.label;
                domCost.value = config.product_cost;
                domAdsSpent.value = config.ads_spent;
            }
        }
        setSideBarLoading(false);
    }
    function TabSetActive(tabname: string){

    }
    // Declare SyncD
    SyncD.SideBar.LoadData = LoadData;

    // Declare Hooks
    useEffect(() => {
        SideBarDom = document.getElementById('right-sidebar');
    })

    // template
    return(
        <div id="right-sidebar" className={"animated "+(SideBarOpen ? 'sidebar-open' : '')}>
            <div className="sidebar-container">

                <ul className="nav nav-tabs navs-3">

                    <li className="active"><a data-toggle="tab" href="#tab-1">
                        Product
                    </a></li>
                    <li className=""><a data-toggle="tab" href="#tab-2">
                        Axie(s)
                    </a></li>
                    <li className=""><a data-toggle="tab" href="#tab-3">
                        <i className="fa fa-gear"></i>
                    </a></li>
                </ul>

                <div className="tab-content">
                    <div id="tab-1" className="tab-pane active">

                        <div className="sidebar-title">
                            <h3> <i className="fa fa-user-o"></i> Product Setup</h3>
                            <small><i className="fa fa-info-circle"></i> Setup cost & ad spent</small>
                        </div>
                        <div className={"ibox-sidebar-content "+(SideBarLoading ? 'sk-loading' : '')}>
                            <div className="sk-spinner sk-spinner-double-bounce">
                                <div className="sk-double-bounce1"></div>
                                <div className="sk-double-bounce2"></div>
                            </div>
                            {
                                !SideBarLoading ?
                                <div className="sidebar-content">
                                    <form className="m-t" role="form">
                                        <div className="form-group">
                                            <label>Product List:</label>
                                            <select name="productList" id="side-bar-productlist" className="form-control input-sm" onChange={changeProductItem}>
                                                {
                                                    productList.map((productItem: any, index: number)=>{
                                                        return(<option value={productItem.item_name} key={index}>{productItem.item_name}</option>)
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>New Product:</label>
                                            <input type="text" className="form-control input-sm" id="productconfig-label" placeholder="Label Name"/>
                                        </div>
                                        <div className="form-group">
                                            <button className="btn btn-info" onClick={SaveData}>Update</button>
                                        </div>
                                    </form><br/>
                                    <form className="m-t">
                                        <div className="form-group">
                                            <label>Product Cost:</label>
                                            <input type="text" className="form-control input-sm" id="productconfig-cost" placeholder="Product Cost"/>
                                        </div>
                                        <div className="form-group">
                                            <label>Ads Spent:</label>
                                            <input type="text" className="form-control input-sm" id="productconfig-adspent" placeholder="Ads Spent"/>
                                        </div>
                                    </form>
                                    <br/>
                                </div> : ''
                            }
                            <div className="sidebar-content">

                                <button className="btn btn-danger pull-left" onClick={SideBarToggle}>Close</button>
                            </div>
                            <br/>
                        </div>

                    </div>

                    <div id="tab-2" className="tab-pane">

                        <div className="sidebar-title">
                            <h3> <i className="fa fa-axie"></i> Scholar Axie(s)</h3>
                            <small><i className="fa fa-info-circle"></i> Check and Click scholars' axie</small>
                        </div>
                        <div className={"ibox-sidebar-content "+(SideBarLoading ? 'sk-loading' : '')}>
                            <div className="sk-spinner sk-spinner-double-bounce">
                                <div className="sk-double-bounce1"></div>
                                <div className="sk-double-bounce2"></div>
                            </div>
                            {
                                !SideBarLoading ?
                                <ul className="sidebar-list">

                                </ul> : ''
                            }
                        </div>

                    </div>

                    <div id="tab-3" className="tab-pane">

                        <div className="sidebar-title">
                            <h3><i className="fa fa-gears"></i> Settings</h3>
                            <small><i className="fa fa-tim"></i> Scholar profile settings.</small>
                        </div>
                        <div className={"ibox-sidebar-content "+(SideBarLoading ? 'sk-loading' : '')}>
                            <div className="sk-spinner sk-spinner-double-bounce">
                                <div className="sk-double-bounce1"></div>
                                <div className="sk-double-bounce2"></div>
                            </div>
                        {
                            !SideBarLoading ?
                            <div>

                            </div>: ''
                        }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default SideBar;