import React, { useState, useEffect } from 'react';

// Static CSS
import '../App.css';

// Declare Modules
import Assets from './module/Assets';

import uiHelper from './module/uiHelper';
import TeamsService from './module/Teams.service';
import SyncDeclare from '../SyncDeclare';

function TeamsMember() {
  // Static Assets
  const logo = Assets.logo;

  // Declare Variable
  let [TeamChannel,setTeamChannel]:any = useState({});
  let [TeamMember,setTeamMember]:any = useState([]);
  let mainRef:any;

  async function LoadTeamMembers(){
    // const list = await TeamsService.list();
    // setTeamList(list);
    if(SyncDeclare.TeamVariable.teamsid != 0){
      const membersReponse:any = await TeamsService.members(SyncDeclare.TeamVariable.teamsid);
      setTeamMember(membersReponse);
      SyncDeclare.TemplateHeader(SyncDeclare.TeamVariable.name, 'Customize what your members can edit and access. You can add up to 15 members. ');
    }else{
      window.location.href="/teams";
    }
  }
  SyncDeclare.LoadTeamMembers = LoadTeamMembers;
  // Declare Hooks
  useEffect(() => {
    // Load Function Once
    LoadTeamMembers();
  }, []);
  // Template
  return (
    <div className="" id="TeamsMember" ref={(node:any)=> mainRef = node}>
      <div className="row">
        <div className="col-lg-12">
            <button type="button" className="btn btn-w-m btn-primary" data-toggle="modal" data-target="#teamsAddNewMember">Add new member</button>
        </div>
      </div>
      <div className="row wrapper wrapper-content animated fadeInRight">
        {
          TeamMember.map((member:any, index:number)=>{
            return(
              <div className="col-lg-3">
                <div className="contact-box center-version">
                  <a href="profile.html">

                    <img alt="image" className="img-circle" src={uiHelper.assets.getProfilePic(member.profilePic, Assets.profileIcon)}/>


                    <h3 className="m-b-xs"><strong>{member.lastname}, {member.firstname}</strong></h3>

                    <div className="font-bold">{member.membertype}</div>
                    <div className="m-t-md">
                      <strong>Information</strong><br/>
                      {member.email}<br/>
                      {member.address}
                    </div>
                  </a>
                  <div className="contact-box-footer">
                    <div className="m-t-xs btn-group">
                      <a className="btn btn-xs btn-white"><i className="fa fa-phone"></i> Remove </a>
                      <a className="btn btn-xs btn-white"><i className="fa fa-envelope"></i> Access</a>
                    </div>
                  </div>

                </div>
              </div>
            )
          })
        }
        </div>
    </div>
  );
}

export default TeamsMember;
