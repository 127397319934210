import uiHelper from "../pages/module/uiHelper";
import UsersService from "../pages/module/Users.service";
import SyncDeclare from "../SyncDeclare";

export default class UserController{
    static update(details:{email:string,firstname:string,lastname:string,address:string},products:any){
        return UsersService.update(
            SyncDeclare.ShopVariable.shopid,
            details.email,
            details.firstname,
            details.lastname,
            details.address,
            products,
        );
    }
    static async pages(){
        const lists:any = await UsersService.getPages();
        const response = [];
        for(let list of lists){
            response.push({label: list.page+' - '+list.permission, value: list.permission || -1});
        }
        return response;
    }
    static async activity(){
        const lists:any = await UsersService.getActivity(SyncDeclare.ShopVariable.shopid);
        return lists;
    }
}