import { Requests } from "../pages/module/Request";

export default class ShopAppService{
    static dashboard(){
        return Requests.Get('/api/shop-dashboard', true);
    }
    static dashboardClient(shopid:Number,itemName:string,startDttm:string,endDttm:string){
        return Requests.Post('/api/dashboard/client', {
            id: shopid,
            itemname: itemName,
            sd: startDttm,
            ed: endDttm
        }, true);
    }
}