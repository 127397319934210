import { Requests } from "./Request";

export default class ShopsService{
    static list(){
        return Requests.Get('/shops/list',true);
    }
    static create(name:string, description:string){
        return Requests.Post('/shops/create',{name,description},true);
    }
    static upload(file:any,shopid: Number){
        return Requests.Upload('/upload/jnt?id='+shopid,file,true);
    }
    static status(shopid: Number){
        return Requests.Get('/upload/status?id='+shopid,true);
    }
    static assignTeam(shopid: Number,teamsid: Number){
        return Requests.Post('/shops/assign/team',{shopid,teamsid}, true);
    }
    static assignList(shopid: Number){
        return Requests.Get('/shops/assign/list?id='+shopid,true);
    }
    static teamList(shopid: Number){
        return Requests.Get('/shops/assign/team/member?id='+shopid,true);
    }
    static assignRemove(shopid: Number,teamsid: Number){
        return Requests.Post('/shops/assign/remove',{shopid,teamsid},true);
    }
    static productAssign(itemname:string, email:string, shopid: Number){
        return Requests.Post('/shops/assign/product/user',{itemname, email, shopid}, true);
    }
    static userProductList(email:string, shopid: Number){
        return Requests.Post('/shops/user/product/list',{email, shopid}, true);
    }
    static assignProductRemove(itemname:string, shopid: Number){
        return Requests.Post('/shops/product/assign/remove',{itemname,shopid},true);
    }
    static exportOrder(shopid: Number,status:string, email:string, itemname:string, split:Number, startdate:string, enddate:string){
        const body = {
            id: shopid,
            s: status,
            email: email,
            itemname: itemname,
            spl: split,
            sd: startdate,
            ed: enddate
        }
        return Requests.Post('/export/orders',body,true);
    }
    static exportOrderSplit(shopid: Number,status:string, email:string, itemname:string, split:Number, startdate:string, enddate:string){
        const body = {
            id: shopid,
            s: status,
            email: email,
            itemname: itemname,
            spl: split,
            sd: startdate,
            ed: enddate
        }
        return Requests.Post('/export/orders/split',body,true);
    }
    static exportOrderPaid(transid: string){
        const body = {transid}
        return Requests.Post('/export/orders/me/paid',body,true);
    }
    static exportOrderPaidBy(transid: string){
        const body = {transid}
        return Requests.Post('/export/orders/paid',body,true);
    }
    static getUploadDate(shopid: Number){
        return Requests.Get('/upload/date?id='+shopid,true);
    }
    static importStart(){
        return Requests.Get('/migrate/users',true);
    }
    static importStartOrders(){
        return Requests.Get('/migrate/orders',true);
    }
    static importStartPayout(){
        return Requests.Get('/migrate/payout',true);
    }
}