import React, { useState, useEffect } from 'react';

// Static CSS
import '../App.css';

// Declare Modules
import Assets from './module/Assets';

import uiHelper from './module/uiHelper';
import SyncDeclare from '../SyncDeclare';

function ProfileDashboard() {
  // Static Assets
  const logo = Assets.logo;
  const Shop = SyncDeclare.ShopVariable;
  let [User,setUser]:any = useState({
    "userinfoid":7,
    "firstname":"",
    "middlename":"",
    "lastname":"",
    "address":"",
    "profilePic":"",
    "permissionid":"",
    "createdAt":"",
    "updatedAt":"",
    "email":"",
    "roles":[]
    });
  let mainRef:any;

  function OpenModal(){
    SyncDeclare.Modals.Profile.UserInfo(User);
  }
  // Declare Hooks
  useEffect(() => {
    // Load Function Once
    if(SyncDeclare.ShopVariable.shopid==0){
    //   window.location.href="/orders";
    }
    setTimeout(()=>{
        SyncDeclare.TemplateHeader('', '', false);
        setUser(SyncDeclare.Users);
    },100)
  }, []);
  // Template
  return (
    <div className="" id="ChecksDashboard" ref={(node:any)=> mainRef = node}>
        <div className="col-md-4">
            <div className="ibox float-e-margins">
                <div className="ibox-title">
                    <h5>Profile Detail</h5>
                </div>
                <div>
                    <div className="ibox-content no-padding border-left-right">
                        <img alt="image" className="img-responsive" src={uiHelper.assets.getProfilePic(User.profilePic,Assets.profileIcon)} style={{"margin": "0 auto","maxWidth": "100px"}}/>
                    </div>
                    <div className="ibox-content profile-content">
                        <h4><strong>{User.firstname} {User.lastname}</strong></h4>
                        <p><i className="fa fa-map-marker"></i> {User.address}</p>
                        <h3>
                            Account Details
                        </h3>
                            {
                                User.roles.map((role:any, roleIndex:any)=>{
                                    return (<div key={roleIndex}>
                                        <h3>{role.name}</h3>
                                        <p>{role.team}</p>
                                    </div>)
                                })
                            }
                        <div className="user-button">
                            <div className="row">
                                <div className="col-md-12">
                                    <button type="button" className="btn btn-primary btn-sm btn-block" data-toggle="modal" data-target="#ProfileModal_ChangePassword" onClick={OpenModal}><i className="fa fa-envelope"></i> Change Password</button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>

    </div>
  );
}

export default ProfileDashboard;
