

// Static CSS
import { useEffect, useState } from 'react';
import '../App.css';
import SyncDeclare from '../SyncDeclare';
import Assets from './module/Assets';
import OrdersService from './module/Orders.service';
import ShopsService from './module/Shops.service';
import TeamsService from './module/Teams.service';
import uiHelper from './module/uiHelper';
import UsersService from './module/Users.service';
import SmsService from './module/SMS.service';

function Modals() {
    // Set Variables
    const [userlist, setUserList]:any = useState([]);
    const [email, setEmail] = useState('');
    const [teamName, setTeamName] = useState('');
    const [teamCreateError, setTeamCreateError] = useState({errordisplay: 'none', type: 'alert-danger', message: ''});
    const [productList, setProductList] = useState([]);
    const [PayoutProductList, setPayoutProductList] = useState([]);
    const [PayoutProductTitle, setPayoutProductTitle] = useState(false);

    const [SMS_Template_List, setSMS_Template_List] = useState([]);
    const [SMS_Template_Selected, setSMS_Template_Selected] = useState('');
    const [SMS_Template_Title, setSMS_Template_Title] = useState('');
    const [SMS_Template_Body, setSMS_Template_Body] = useState('');
    // Static Assets
    const logo = 'assets/img/main-logo.png';
    let domModal:any;
    function loadTeams(){
    }
    async function findByEmail(e?:any){
        // prevent browser from reloading
        if(e){
            e.preventDefault();
        }
        const listResponse = await TeamsService.findByEmail(email, SyncDeclare.TeamVariable.teamsid);
        setUserList(listResponse);

    }
    async function AddMember(email: string){
        let status = {errordisplay: 'block', type: 'alert-success', message: 'Added Successfully.'};
        try{
            await TeamsService.addMember(email, SyncDeclare.TeamVariable.teamsid);
        }catch(e:any){
            status = {errordisplay: 'block', type: 'alert-danger', message: e.message};
        }
        setTeamCreateError(status);
        findByEmail();
        SyncDeclare.LoadTeamMembers();

      }
    async function createTeam(e:any){
        // prevent browser from reloading
        e.preventDefault();
        let status = {errordisplay: 'block', type: 'alert-success', message: 'Team Created Successfully.'};
        try{
            await TeamsService.create(teamName);
        }catch(e:any){
            status = {errordisplay: 'block', type: 'alert-danger', message: e.message};
        }
        setTeamCreateError(status);
    }
    async function createShop(e:any){
        // prevent browser from reloading
        e.preventDefault();
        let status = {errordisplay: 'block', type: 'alert-success', message: 'Shop Created Successfully.'};
        try{
            await ShopsService.create(teamName, '');
        }catch(e:any){
            status = {errordisplay: 'block', type: 'alert-danger', message: e.message};
        }
        setTeamCreateError(status);
    }
    function getProductItem(e:any){
        const itemName = e.target.value || '';
        const domModalTemplate:any =  document.getElementById('modalTemplate');
        const domConfigInput = domModalTemplate.querySelector('#orderdashboardconfig input');
        domConfigInput.value = itemName;
    }
    function LoadOrdersConfig(ordersConfig:any){
        setProductList(ordersConfig);
    }
    function getProductCost(e:any){
        SyncDeclare.ProductConfig.productcost = e.target.value || 0;
        localStorage.setItem('product_cost', e.target.value || 0);
        SyncDeclare.OrdersDashboardRefresh();
    }
    function getAdSpent(e:any){
        SyncDeclare.ProductConfig.fbadspent = e.target.value || 0;
        localStorage.setItem('ads_spent', e.target.value || 0);
        SyncDeclare.OrdersDashboardRefresh();
    }
    async function updateProductItemName(e:any){
        e.preventDefault();
        const domForm = e.target;
        const optionDom = domForm.querySelectorAll('select option');
        const oldItemName = domForm.querySelector('select').value;
        const newItemName = domForm.querySelector('input').value;
        if(newItemName.length>0 && oldItemName.length>0){
            let newProductList:any = productList;
            let getOldItemNameIndex = -1;
            let getNewItemNameIndex = -1;
            productList.map((item:any, index:any)=>{
                if(item.item_name == newItemName.trim()){
                    getNewItemNameIndex = index;
                }
                if(item.item_name == oldItemName.trim()){
                    getOldItemNameIndex = index;
                }
            });
            if(getNewItemNameIndex != -1 && getNewItemNameIndex != getOldItemNameIndex){
                newProductList.splice(getOldItemNameIndex,1);
                optionDom[getOldItemNameIndex].remove();
            }else if(getNewItemNameIndex == -1 && getNewItemNameIndex != getOldItemNameIndex){
                newProductList[getOldItemNameIndex].item_name = newItemName;
                optionDom[getOldItemNameIndex].innerHTML = newItemName;
                optionDom[getOldItemNameIndex].value = newItemName;
            }
            // setProductList(newProductList);
            // const response:any = await OrdersService.productUpdate(SyncDeclare.ShopVariable.shopid, oldItemName, newItemName)
            // if(response.status == 'success'){
            //     uiHelper.toastr.success(response.message);
            // }else{
            //     uiHelper.toastr.error(response.message);
            // }
        }
    }
    async function SMSTemplate(shopid:any){
        const list:any = await SmsService.templateList(shopid);
        setSMS_Template_List(list);
    }
    async function SMS_Template_Select(id:any){
        setSMS_Template_Selected(id);
        if(id != ''){
            const SMS_Property:any = SMS_Template_List[id] || {title: '', template: ''};
            setSMS_Template_Title(SMS_Property.title);
            setSMS_Template_Body(SMS_Property.template);
        }else{
            setSMS_Template_Title('');
            setSMS_Template_Body('');
        }
    }
    async function SMS_Template_Save(){
        const shopid = SyncDeclare.ShopVariable.shopid;
        if(SMS_Template_Selected == ''){
            const response:any = await SmsService.templateSave(shopid,SMS_Template_Title,SMS_Template_Body);
            if(response.status == 'success'){
                uiHelper.toastr.success(response.message);
            }else{
                uiHelper.toastr.error(response.message);
            }
        }else{
            const SMS_Property:any = SMS_Template_List[Number(SMS_Template_Selected)] || {id: ''};
            const response:any = await SmsService.templateUpdate(SMS_Property.id,SMS_Template_Title,SMS_Template_Body,shopid);
            if(response.status == 'success'){
                uiHelper.toastr.success(response.message);
            }else{
                uiHelper.toastr.error(response.message);
            }
        }
        SMSTemplate(shopid);
    }
    async function SMS_Template_Default(){
        const shopid = SyncDeclare.ShopVariable.shopid;
        const SMS_Property:any = SMS_Template_List[Number(SMS_Template_Selected)] || {id: ''};
        const response:any = await SmsService.templateSelected(SMS_Property.id,shopid);
        if(response.status == 'success'){
            uiHelper.toastr.success(response.message);
        }else{
            uiHelper.toastr.error(response.message);
        }
        SMSTemplate(shopid);
    }
    function PayoutMediaBuyer(e:any,option?:any){
        setPayoutProductList(e);
        setPayoutProductTitle(option ? true : false);
    }
    SyncDeclare.ProductConfigList = LoadOrdersConfig;
    SyncDeclare.PayoutMediaBuyer = PayoutMediaBuyer;
    SyncDeclare.SMSTemplate = SMSTemplate;
    // Declare Hooks
    useEffect(() => {
        // Load Function Once
    });
  // Template
  return (
    <div id="modalTemplate">
        <div className="modal inmodal fade" id="teamsAddNewMember" role="dialog"  aria-hidden="true">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-body">
                    <div className="ibox-content1">
                            <div className="search-form">
                                <form role="form" onSubmit={findByEmail}>
                                    <div className="input-group">
                                        <input type="text" placeholder="Search users by email" name="search" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control input-lg"/>
                                        <div className="input-group-btn">
                                            <button className="btn btn-lg btn-primary" type="submit">
                                                Search
                                            </button>
                                        </div>
                                    </div>

                                </form>
                                <div className={`alert ${teamCreateError.type} alert-dismissable`} style={{display: teamCreateError.errordisplay}}>
                                <button aria-hidden="true" data-dismiss="alert" className="close" type="button">×</button>
                                {teamCreateError.message}
                            </div>
                            </div>
                            <div className="hr-line-dashed"></div>
                            {
                                userlist.map((user:any,index:number)=>{
                                    return(
                                        <div key={index}>
                                            <div className="contact-box">
                                                <a href="#">
                                                    <div className="col-sm-4">
                                                        <div className="text-center">
                                                            <img alt="image" className="img-circle m-t-xs img-responsive" src={Assets.defaultProfilePic} style={{margin:"0 auto"}}/>
                                                            <div className="m-t-xs font-bold">Media Buyer</div>

                                                            <button type="button" className="btn btn-w-m btn-primary btn-outline" onClick={()=>AddMember(user.email)}>Add</button>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <h3><strong>{user.lastname}, {user.firstname}</strong></h3>
                                                        <p>
                                                            <i className="fa fa-envelope"></i>&nbsp;<b>Email:</b>&nbsp;{user.email}<br/>
                                                            <i className="fa fa-map-marker"></i>&nbsp;<b>Address:</b>&nbsp;{user.address}
                                                        </p>
                                                        <div>
                                                            <strong>Associated with:</strong><br/>
                                                            test@gmail.com<br/>
                                                        </div>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                </a>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-white" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal inmodal fade" id="teamsCreate" role="dialog"  aria-hidden="true">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-body">
                    <div className="ibox-content1">
                        <div className="search-form">
                            <form role="form" onSubmit={createTeam}>
                                <p>Create Team</p>
                                <div className="input-group">
                                    <input type="text" placeholder="Name" name="search" value={teamName} onChange={(e)=>setTeamName(e.target.value)} className="form-control input-lg"/>
                                    <div className="input-group-btn">
                                        <button className="btn btn-lg btn-primary" type="submit">
                                            Create
                                        </button>
                                    </div>
                                </div>
                                <label className="p-md">
                                    <input type="checkbox"/> Order Access
                                </label>
                                <label className="p-md">
                                    <input type="checkbox"/> Shop Access
                                </label>
                                <label className="p-md">
                                    <input type="checkbox"/> Team Access
                                </label>
                            </form>
                            <div className={`alert ${teamCreateError.type} alert-dismissable`} style={{display: teamCreateError.errordisplay}}>
                                <button aria-hidden="true" data-dismiss="alert" className="close" type="button">×</button>
                                {teamCreateError.message}
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-white" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal inmodal fade" id="shopsCreate" role="dialog"  aria-hidden="true">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-body">
                    <div className="ibox-content1">
                        <div className="search-form">
                            <form role="form" onSubmit={createShop}>
                                <p>Create Shop</p>
                                <div className="input-group">
                                    <input type="text" placeholder="Name" name="search" value={teamName} onChange={(e)=>setTeamName(e.target.value)} className="form-control input-lg"/>
                                    <div className="input-group-btn">
                                        <button className="btn btn-lg btn-primary" type="submit">
                                            Create
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div className={`alert ${teamCreateError.type} alert-dismissable`} style={{display: teamCreateError.errordisplay}}>
                                <button aria-hidden="true" data-dismiss="alert" className="close" type="button">×</button>
                                {teamCreateError.message}
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-white" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal inmodal fade" id="orderdashboardconfig" role="dialog"  aria-hidden="true">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                        <i className="fa fa-gear modal-icon"></i>
                        <h4 className="modal-title">Order Config</h4>
                        <small className="font-bold">Setup product cost and spent or update product name.</small>
                    </div>
                    <div className="modal-body">
                        <div className='row'>
                            <div className='col-sm-6' style={{"display":"none"}}>
                                <div className="ibox-content">
                                    <form role="form" onSubmit={updateProductItemName}>
                                        <div className="form-group">
                                            <label>Product List:</label>
                                            <select name="productList" id="side-bar-productlist" className="form-control input-sm" onChange={getProductItem}>
                                                {
                                                    productList.map((productItem: any, index: number)=>{
                                                        return(<option value={productItem.item_name} key={index}>{productItem.item_name}</option>)
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className='form-group'>
                                            <label>Product Name:</label>
                                            <div className="input-group">
                                                <input type="text" placeholder="Product name" name="productname" id="modal-productconfig-itemname" className="form-control input-sm"/>
                                                <div className="input-group-btn">
                                                    <button className="btn btn-lg btn-primary btn-sm" type="submit">
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className={`alert ${teamCreateError.type} alert-dismissable`} style={{display: teamCreateError.errordisplay}}>
                                        <button aria-hidden="true" data-dismiss="alert" className="close" type="button">×</button>
                                        {teamCreateError.message}
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className="ibox-content">
                                    <form role="form">
                                        <div className="form-group">
                                            <label>Product Cost:</label>
                                            <input type="text" placeholder="Cost of product" name="productcost" onBlur={getProductCost} className="form-control input-sm"/>
                                        </div>
                                        <div className="form-group">
                                            <label>FB Ads Spent:</label>
                                            <input type="text" placeholder="Ads Spent" name="adsspent" onBlur={getAdSpent} className="form-control input-sm"/>
                                        </div>
                                    </form>
                                    <div className={`alert ${teamCreateError.type} alert-dismissable`} style={{display: teamCreateError.errordisplay}}>
                                        <button aria-hidden="true" data-dismiss="alert" className="close" type="button">×</button>
                                        {teamCreateError.message}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-white" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal inmodal fade" id="payout_mediabuyer_itemcost" role="dialog"  aria-hidden="true">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                        <i className="fa fa-gear modal-icon"></i>
                        <h4 className="modal-title">Payout Media Buyer {PayoutProductTitle ? '(RTS)' : ''}</h4>
                        <small className="font-bold">See the details of Product Total and Cost</small>
                    </div>
                    <div className="modal-body">
                        <div className='row'>
                            <div className='col-sm-12'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Item Name</th>
                                            <th>Total(s)</th>
                                            <th>Formula</th>
                                            <th>Cost(s)</th>
                                            <th>Equal(s)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            PayoutProductList.map((item:any,itemKey:any)=>{
                                                return(
                                                    <tr key={itemKey}>
                                                        <th>{itemKey+1}</th>
                                                        <th>{item.name}</th>
                                                        <th>{item.total}</th>
                                                        <th>x</th>
                                                        <th>{item.cost}</th>
                                                        <th>{(item.total || 0)*(item.cost || 0)}</th>
                                                    </tr>
                                                )
                                            })
                                        }
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-white" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal inmodal fade" id="payout_formula" role="dialog"  aria-hidden="true">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                        <i className="fa fa-gear modal-icon"></i>
                        <h4 className="modal-title">Payout Formula</h4>
                        <small className="font-bold">See the details of formula</small>
                    </div>
                    <div className="modal-body">
                        <div className='row'>
                            <div className='col-sm-12'>
                                <img src="assets/img/formula_tumia_1.png" style={{"width": "100%"}}/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-white" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal inmodal fade" id="order_splits_modal" role="dialog"  aria-hidden="true">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                        <i className="fa fa-gear modal-icon"></i>
                        <h4 className="modal-title">SMS Template</h4><br/>
                    </div>
                    <div className="modal-body">
                        <div className='row'>
                            <div className='col-sm-12'>
                                <label>List</label>
                                <select name="status" id="status" className="form-control" onChange={(e)=>SMS_Template_Select(e.target.value)}>
                                    <option value="" key={-1}>Create Template</option>
                                    {
                                        SMS_Template_List.map((templateItem: any, index: number)=>{
                                            return(<option value={index} key={index} >{templateItem.title}{templateItem.selected == 1 ? ' - Default' : ''}</option>)
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-sm-12 m-t'>
                                <label>Title</label>
                                <input type="text" className='form-control' value={SMS_Template_Title} onChange={(e:any)=>setSMS_Template_Title(e.target.value)}/>
                            </div>
                            <div className='col-sm-12'>
                                <label>Template</label>
                                <textarea className='form-control' style={{height: '100px'}} value={SMS_Template_Body} onChange={(e:any)=>setSMS_Template_Body(e.target.value)}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-white" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-info" onClick={SMS_Template_Default}>Use Template</button>
                        <button type="button" className="btn btn-success" onClick={SMS_Template_Save}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Modals;
