import React, { useState, useEffect } from 'react';

// Static CSS
import '../App.css';

// Declare Modules
import Assets from '../pages/module/Assets';
import ShopAppService from '../services/shop-dashboard.service';
import SyncDeclare from '../SyncDeclare';
import Base64 from '../utils/Base64';

function Shop_App() {
  // Static Assets
  const logo = Assets.logo;
  const [ShopList, setShopList] = useState([]);
  const [ShopDetails, setShopDetails] = useState({delivered: 0, earnings: 0, returned: 0});

  // Declare Variable
  let mainRef:any;
  
  async function LoadShopList(){
    const response:any = await ShopAppService.dashboard();
    setShopList(response.shop.list);
    setShopDetails(response.report);
    console.log(response);
  }
  function OpenShop(shop:any){
    localStorage.setItem('shop-id',JSON.stringify(shop));
    window.location.reload();
  }
  // Declare Hooks
  useEffect(() => {
    // Load Function Once
    LoadShopList();
  }, []);
  // Template
  return (
    <div className="wrapper wrapper-content animated fadeInRight" id="Shop-Dashboard" ref={(node:any)=> mainRef = node}>
      <div className='container'>
        <div className='text-center'>
          <h1 style={{"color":"white"}}>Where would you like to go?</h1>
        </div>
        <div className='justify-row-content'>
          {
            ShopList.map((shop:any,shopKey:number)=>{
              return(
                <div className='col-centered' key={shopKey}>
                  <div className="ibox">
                      <div className="ibox-title">
                          <span className="label label-primary pull-right">NEW</span>
                          <h5>Shop - [{shop.name}]</h5>
                      </div>
                      <div className="ibox-content">
                          <h4>More Information.</h4>
                          <div className="row  m-t-sm">
                              <div className="col-sm-4">
                                  <div className="font-bold">DELIVERED</div>
                                  {ShopDetails.delivered}
                              </div>
                              <div className="col-sm-4">
                                  <div className="font-bold">EARNINGS</div>
                                  {(ShopDetails.earnings || 0).toFixed(2)}
                              </div>
                              <div className="col-sm-4 text-right">
                                  <div className="font-bold">RETURNED</div>
                                  {ShopDetails.returned}
                              </div>
                          </div>
                          
                          <p>
                            <button type="button" className="btn btn-block btn-outline btn-primary" onClick={()=>OpenShop(shop)}>Open Shop</button>
                          </p>
                          <div className="team-members">
                              <a href="#"><img alt="member" className="img-circle" src="assets/img/a1.jpg"/></a>
                              <a href="#"><img alt="member" className="img-circle" src="assets/img/a2.jpg"/></a>
                              <a href="#"><img alt="member" className="img-circle" src="assets/img/a3.jpg"/></a>
                              <a href="#"><img alt="member" className="img-circle" src="assets/img/a5.jpg"/></a>
                              <a href="#"><img alt="member" className="img-circle" src="assets/img/a6.jpg"/></a>
                          </div>

                      </div>
                  </div>
                </div>
              )
            })
          }
          
        </div>
      </div>
    </div>
  );
}

export default Shop_App;
