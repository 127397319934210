import { Requests } from "./Request";

export default class TeamsService{
    static list(){
        return Requests.Get('/teams/list',true);
    }
    static create(name:string){
        return Requests.Post('/teams/create',{name},true);
    }
    static addMember(email:string,teamid:number){
        return Requests.Post('/teams/addMember',{email, teamid},true);
    }
    static findByEmail(email:string,teamsid:number){
        return Requests.Get('/teams/findUser?email='+email+'&teamsid='+teamsid, true);
    }
    static members(teamsid:number){
        return Requests.Get('/teams/members?teamsid='+teamsid, true);
    }
}