const HOST = "https://serverv1.tumia.shop";
const TOKEN = localStorage.getItem("token");

export class Requests {
    static Header: any = {};
    static Redirect: any = "https://admin.tumia.shop/client/signin";

    static SetMethod: string;
    static PrivateMethod: string = "Private";
    static PublicMethod: string = "Public";

    // Set Method to Private or Public
    static Method(Private?:Boolean){
        if(Private){
            this.SetMethod = this.PrivateMethod;
            this.Header = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+TOKEN,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                'Access-Control-Allow-Credentials': true
            }
        }else{
            this.SetMethod = this.PublicMethod;
            this.Header = {
                'Content-Type': 'application/json'
            }
        }
        return "This Connection is "+this.SetMethod;
    }
    // Validate If The Reponse Status is OK
    static async Validation(response:any){
        const self = Requests;
        if(response.ok) return response.json();
        else{
            if(response.status === 401 && self.SetMethod === self.PrivateMethod){
                response.json().then(()=>{
                    localStorage.removeItem('shop-id');
                    localStorage.removeItem('token');
                    window.location.href = self.Redirect;
                });
            }else {
                const event:any = await response.json();
                if(event.message) {
                    throw new Error(event.message);
                }else{
                    throw new Error(response.statusText);
                }
            }
        }
    }
    // Request To Get Data
    static Get(URL:string, Private?: Boolean){
        this.Method(Private);
        return new Promise((resolve,reject)=>{
            fetch(HOST+URL, {
                method: 'GET', // or 'PUT'
                headers: this.Header
            }).then(this.Validation)
            .then(resolve)
            .catch(reject);
        });
    }
    // Request To Add More Data
    static Post(URL:string, body:any, Private?: Boolean){
        this.Method(Private);
        return new Promise((resolve,reject)=>{
            fetch(HOST+URL, {
                method: 'POST', // or 'PUT'
                headers: this.Header,
                body: JSON.stringify(body)
            }).then(this.Validation)
            .then(resolve)
            .catch(reject);
        });
    }
    // Request For Updating Information
    static Put(URL:string, body:any, Private?: Boolean){
        this.Method(Private);
        return new Promise((resolve,reject)=>{
            fetch(HOST+URL, {
                method: 'PUT', // or 'PUT'
                headers: this.Header,
                body: JSON.stringify(body)
            }).then(this.Validation)
            .then(resolve)
            .catch(reject);
        });
    }
    static Upload(URL:string, file:any, Private?: Boolean){
        return new Promise((resolve,reject)=>{
            fetch(HOST+URL, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Authorization': 'Bearer '+TOKEN,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                },
                body: file
            }).then(this.Validation)
            .then(resolve)
            .catch(reject);
        });
    }
}