declare global {interface Window{toastr:any,Chart:any,plot:any,Morris:any,c3:any}}

const uiHelper = {
    toastr: {
        success: function(msg:string){
            window.toastr.success(msg);
        },
        info: function(msg:string){
            window.toastr.info(msg);
        },
        error: function(msg:string){
            window.toastr.error(msg);
        }
    },
    format: {
        fullDate: (date:Date)=>{
            let nDate:Date = new Date(date);
            let m = nDate.getMonth() + 1;
            let d = nDate.getDate();
            let yyyy = nDate.getFullYear();

            let mm = m > 9 ? m : '0'+m;
            let dd = d > 9 ? d : '0'+d;

            return yyyy+'-'+mm+'-'+dd;
        },
        fullDateWithTime: (date:Date)=>{
            let nDate:Date = new Date(date);
            let m = nDate.getMonth() + 1;
            let d = nDate.getDate();
            let yyyy = nDate.getFullYear();
            let h = nDate.getHours();
            let ms = nDate.getMinutes();
            let s = nDate.getSeconds();

            let mm = m > 9 ? m : '0'+m;
            let dd = d > 9 ? d : '0'+d;
            let hh = h > 12 ? Math.abs(12-h) : h;
            let mms = ms > 9 ? ms : '0'+ms;
            let ss = s > 9 ? s : '0'+s;

            let meridian = 'am';
            if(h>12) meridian = 'pm';

            return yyyy+'-'+mm+'-'+dd+' '+hh+':'+mms+' '+meridian;
        },
        MonthDateOnly: function(date:any, add:any){
            try{
                let nDate:Date = new Date(date);
                return (nDate.getMonth()+1)+"-"+(nDate.getDate()+add);
            }catch(e){return '';}
        },
        FullDateTime: function(date:any, add?:any){
            try{
                if(!date) return "";
                let nDate:any = new Date(date);
                if (typeof add !== 'undefined') {
                    nDate = new Date(nDate.setDate(nDate.getDate() + add));
                }
                let daytime:string = "AM";
                let hh:any = nDate.getHours();
                let mm:any = nDate.getMinutes();
                if(hh>12){
                    daytime = "PM";
                    hh = hh - 12;
                }
                if(mm<10) mm = "0"+mm;

                return nDate.getFullYear()+"-"+(nDate.getMonth()<9 ? "0":"")+(nDate.getMonth()+1)+"-"+nDate.getDate()+" "+hh+":"+mm+""+daytime;
            }catch(e){return ''}
        },
        SubtractDate: function(date:any){
            let todays = new Date(date);
            todays.setDate(todays.getDate() - 6);
            let mm:any = todays.getMonth()+1;
            let dd:any = todays.getDate();
            let yyyy:any = todays.getFullYear();
            let m:any = mm;
            let d:any = dd;
            if(mm<10){
                m = '0'+mm;
            }
            if(dd<10){
                d = '0'+dd;
            }
            return yyyy+'-'+m+'-'+d;
        }

    },
    chartJS: {},
    // barChart: function(dom:any, _labels:any[], _values:any[]){
    //     let barData = {
    //         labels: _labels,
    //         datasets: [
    //             {
    //                 label: "Total",
    //                 backgroundColor: 'rgba(26,179,148,0.5)',
    //                 pointBorderColor: "#000",
    //                 data: _values
    //             }
    //         ]
    //     };
    //     if(Object.keys(this.chartJS).length > 0){
    //         let chart:any = this.chartJS;
    //         chart.destroy();
    //     }
    //     let ctx2 = dom.getContext("2d");
    //     this.chartJS = new window.Chart(ctx2, {type: 'bar', data: barData, options:{
    //         responsive: true,
    //         maintainAspectRatio: false
    //     }});
    //     console.log(this.chartJS, dom)
    // },
    barChart: function(dom:any, _labels:any[], _values:any[]){
        let barData = {
            labels: _labels,
            datasets: [
                {
                    label: "Total",
                    backgroundColor: 'rgba(26,179,148,0.5)',
                    pointBorderColor: "#000",
                    data: _values
                }
            ]
        };
        let chartDom: any = this.chartJS;
        if(chartDom.chart){
            if(chartDom.chart.canvas.id === dom.id){
                chartDom.destroy();
            }
        }
        let ctx2 = dom.getContext("2d");
        this.chartJS = new window.Chart(ctx2, {type: 'bar', data: barData, options:{
            responsive: true,
            maintainAspectRatio: false
        }});
    },
    barChart2: function(dom:any, _labels:any[], _values1:any[],_values2:any[]){
        let barData = {
            labels: _labels,
            datasets: [
                {
                    label: "Total",
                    backgroundColor: 'rgba(26,179,148,0.5)',
                    pointBorderColor: "#000",
                    data: _values1
                },
                {
                    label: "Paid",
                    backgroundColor: 'rgba(220, 220, 220, 0.5)',
                    pointBorderColor: "#000",
                    data: _values2
                }
            ]
        };
        // if(Object.keys(this.chartJS).length > 0){
        //     let chart:any = this.chartJS;
        //     chart.destroy();
        // }
        let chartDom: any = this.chartJS;
        if(chartDom.chart){
            if(chartDom.chart.canvas.id === dom.id){
                chartDom.destroy();
            }
        }
        let ctx2 = dom.getContext("2d");
        this.chartJS = new window.Chart(ctx2, {type: 'bar', data: barData, options:{
            responsive: true,
            maintainAspectRatio: false
        }});
    },
    morrisJS: {},
    morris: function(elementID:any, _data:any){
        let dom:any = document.querySelector('#'+elementID);
        if(dom.querySelector('svg')){
            dom.querySelector('svg').remove();
        }
        // document.querySelector('#'+elementID).empty();
        this.morrisJS = window.Morris.Donut({
            element: elementID,
            data: _data,
            resize: true,
            colors: ['#87d6c6', '#54cdb4','#1ab394'],
        });
    },
    c3JS: {},
    c3: function(_id:string, _data:any[]){
        let color:any = this.assets.getOrderedColor();
        let columnsData:any = [];
        let colorsData:any = {};
        for(let index in _data){
            columnsData.push([_data[index].name, _data[index].value]);
            colorsData[_data[index].name] = color[index];
        }
        if(Object.keys(this.c3JS).length > 0){
            let chart:any = this.c3JS;
            chart.destroy();
        }
        this.c3JS = window.c3.generate({
            bindto: _id,
            data:{
                columns: columnsData,
                colors: colorsData,
                type : 'pie'
            }
        });
    },
    multipleSelect: function(dom:any){
        dom.select2();
    },
    assets: {
        getOrderedColor: function() {
            return [
                '#42c2f5',
                '#5ccca5',
                '#d376e8',
                '#f0dc95',
                '#f08e71',
                '#9fd481',
                '#81d4a2',
                '#6ad6de',
                '#6a87de',
                '#9d7ecc',
            ]
        },
        getProfilePic: function(filename: any, option?:any){
            const assetsProfile = 'https://admin.tumia.shop/client/assets/profile/';
            if(filename){
              return assetsProfile+filename;
            }
            return option ? option : '';
        }
    }
}

export default uiHelper;