import React, { useState, useEffect } from 'react';

// Static CSS
import '../App.css';

// Declare Modules
import Assets from './module/Assets';

import uiHelper from './module/uiHelper';
import SyncDeclare from '../SyncDeclare';
import ShopsService from './module/Shops.service';
import TeamsService from './module/Teams.service';
import OrdersService from './module/Orders.service';
import { skipColumn } from '../utils/constant';
import OrderController from '../controller/orderController';

function OrdersDashboard() {
  // Static Assets
  const logo = Assets.logo;
  const Shop = SyncDeclare.ShopVariable;
  const maxRows = 1000;
  const mediaBuyerShare = 20/100;
  const Pagination:any = {
    Index: 2,
    List: []
  };

  // Declare Variable
  let [OrderColumns,setOrderColumns]:any[] = useState([]);
  let [OrderList,setOrderList]:any[] = useState([]);
  let [uploadState,setUploadState]:any = useState('');
  let [pageNumber, setPageNumber]:any[] = useState(0);
  let [itemList, setItemList]:any[] = useState([]);
  let [statusList, setStatusList]:any[] = useState([]);
  let [teamListMember, setTeamListMember]:any[] = useState([]);
  let [search,setSearch]:any = useState('');
  let [searchBy,setSearchBy]:any = useState('');
  let [startDate,setStartDate]:any = useState('');
  let [endDate,setEndDate]:any = useState('');
  let [searchProduct,setSearchProduct]:any = useState('');
  let [searchProductByUser,setSearchProductByUser]:any = useState('');
  let [filterStatus,setFilterStatus]:any = useState('');
  let [reportsMediaBuyer,setReportsMediaBuyer]:any = useState({});
  let [hideMediaBuyerProfit,setHideMediaBuyerProfit]:any = useState(false);
  let [totalList, setTotalList]:any = useState('0');
  let [paginationList, setPaginationList]:any = useState([]);
  let [domElement, setDomElement]:any = useState({});
  let [uploadDate, setUploadDate]:any = useState('');
  let mainRef:any;
  let domRef:any;

  async function LoadOrders(index: Number){
    SyncDeclare.TemplateHeader('['+Shop.name+']', '', false);
    setUploadState('sk-loading');
    await LoadCustomColumn();
    let CustomHeader:any[] = SyncDeclare.CustomHeader;
    const orders:any = await OrdersService.list(searchProductByUser, searchProduct, filterStatus, searchBy, search, Shop.shopid, index, maxRows, startDate, endDate, true);
    if(orders.length>0){
        const columns = orders[0];
        let setColumns = [];
        for(const column in columns){
            if(skipColumn.indexOf(column)<0 && !CustomHeader.includes(column)){
                setColumns.push(column);
            }
        }
        setOrderColumns(setColumns);
        setOrderList(orders);
        const items:any = await OrdersService.listItems(Shop.shopid);
        setItemList(items);
        TotalOrders(Shop.shopid);
        const status:any = await OrdersService.listStatus(Shop.shopid);
        setStatusList(status);
        reports();
    }else{
        setOrderList([]);
        setTotalList('0');
    }
    setUploadState('');
  }
  async function TotalOrders(ShopID:Number){
    const total:any = await OrdersService.listTotalByUser(searchProductByUser,'', filterStatus, searchBy, search, ShopID, startDate, endDate);
    setTotalList(total);
    Pagination.List = [];
    let pages = (Number(total)/maxRows);
    for(let page = 0; page<(pages);page++){
        Pagination.List.push(page);
    }
    setPaginationList(Pagination.List);
  }
  async function LoadTeamMembers(){
    const teamMembers:any = await ShopsService.teamList(Shop.shopid);
    setTeamListMember(teamMembers);
  }
  async function LoadOrdersByWaybill(){
    setPageNumber(0);
    LoadOrders(0);
  }
  async function SearchByProduct(e:any){
    searchProduct = e.target.value;
    setSearchProduct(e.target.value);
    setPageNumber(0);
    LoadOrders(0);
  }
  async function SearchProductByUser(e:any){
    const index = e.target.value;
    let assignedUser = '';
    if(index>=0 && index!=''){
        assignedUser = teamListMember[index].email;
    }
    searchProductByUser = assignedUser;
    setSearchProductByUser(assignedUser);
    setPageNumber(0);
    LoadOrders(0);
    setHideMediaBuyerProfit(index!='' ? true : false);
  }
  async function filterByParameters(e:any){
    setPageNumber(0);
    LoadOrders(0);
  }
  async function reports(){
    let reportsShare:any = await OrdersService.reportsShare(Shop.shopid,searchProduct,searchProductByUser,startDate, endDate);
    let cost:any = localStorage.getItem('product_cost');
    let adsSpent:any = localStorage.getItem('ads_spent');
    const product_cost:any = Number(reportsShare.item_total || 0) * Number(cost);
    const netProfit = Number(reportsShare.delivered_value || 0) - Number(reportsShare.delivered_shipping_cost || 0) - Number(reportsShare.cod_fee || 0) - Number(reportsShare.vat_fee || 0) - Number(reportsShare.returned_shipping_cost || 0) - (product_cost || 0) - (adsSpent || 0);
    reportsShare.product_cost = product_cost;
    reportsShare.product_set_cost = cost;
    reportsShare.net_profit = netProfit;
    reportsShare.share_profit = netProfit*mediaBuyerShare;
    setReportsMediaBuyer(reportsShare);
    const reportsTotal:any = await OrdersService.reportsTotal(Shop.shopid,searchProduct,searchProductByUser,startDate, endDate);
    //   console.log(reportsTotal);
    let labels = [];
    let values = [];
    for(let obj of reportsTotal){
        if(obj.order_status){
            labels.push(obj.order_status);
            values.push(obj.order_total);
        }
    }
    let dom:any = domElement;
    if(Object.keys(domElement).length == 0){
        dom = domRef;
    }
    uiHelper.barChart(dom.querySelector('#barChart'), labels, values);
  }
  function pagination(status:string, value?:any){
    if(status=='next'){
        let page = parseInt(pageNumber) + 1;
        if(page<paginationList.length){
            Pagination.Index = page;
            setPageNumber(page);
        }else{
            page = pageNumber;
        }
        LoadOrders(page*maxRows);
    }else if(status=='prev'){
        let page = 0;
        if(pageNumber>=0){
            page = pageNumber - 1;
            Pagination.Index = page;
            setPageNumber(page);
        }
        LoadOrders(page*maxRows);
    }else if(status=='selected'){
        Pagination.Index = value;
        setPageNumber(value);
        LoadOrders(value*maxRows);
    }
  }
  function formatTD(row:any){
      let formatRow = [];
        let CustomHeader:any[] = SyncDeclare.CustomHeader;
      for(const column in row){
          if(skipColumn.indexOf(column)<0 && !CustomHeader.includes(column)){
              if(['createdAt','updatedAt'].indexOf(column)>=0){
                  formatRow.push({column, value: row[column] != null ? uiHelper.format.fullDateWithTime(row[column]) : ""});
              }else{
                  formatRow.push({column, value: row[column]});
              }
          }
      }
      return formatRow;
  }
  function OpenSideBar(){
      SyncDeclare.SideBar.LoadData({itemList, shop: Shop});
  }
  function ConfigModals(){
      console.log(itemList)
      SyncDeclare.ProductConfigList(itemList)
  }
  function OrdersDashboardRefresh(){
    reports();
  }
  async function getLastUploadDate(){
    const uploadDate:any = await ShopsService.getUploadDate(Shop.shopid);
    if(uploadDate.length>0){
      setUploadDate(uiHelper.format.fullDateWithTime(uploadDate));
    }else{
      setUploadDate('');
    }
  }
  async function LoadCustomColumn(){
    if(SyncDeclare.CustomHeader.length>0) return; 
    const response:any = await OrderController.getCustomHeader();
    if(response.custom_column){
        const format = JSON.parse(response.custom_column);
        SyncDeclare.CustomHeader = format;
    }
    }
  SyncDeclare.OrdersDashboardRefresh = OrdersDashboardRefresh;
  // Declare Hooks
  useEffect(() => {
    // Load Function Once
    if(SyncDeclare.ShopVariable.shopid==0){
      window.location.href="/orders";
    }
    domRef = mainRef;
    setDomElement(mainRef);
    LoadOrders(0);
    LoadTeamMembers();
    setPageNumber(0);
    getLastUploadDate();
  }, []);
  // Template
  return (
    <div className="" id="OrdersDashboard" ref={(node:any)=> mainRef = node}>
        <div className='row'>
            <div className='col-sm-12 m-b-xs'>
                <div className="ibox">
                    <div className="ibox-content">
                        <div className='row'>
                            <div className='col-sm-12'>
                            <h2>Shop Order - {Shop.name}</h2>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-8' style={{height: "200px"}}>
                                <canvas id="barChart" height="100"></canvas>
                            </div>
                            <div className="col-sm-2">
                                <ul className="stat-list">
                                    <li>
                                        <small>Delivered Value: </small>
                                        <br/><span className='text-navy'>PHP {(reportsMediaBuyer.delivered_value || 0).toFixed(2)}</span>
                                    </li>
                                    <li>
                                        <small>Total Shipping Cost: </small>
                                        <br/><span className='text-navy'>PHP {(reportsMediaBuyer.delivered_shipping_cost || 0).toFixed(2)}</span>
                                    </li>
                                    <li>
                                        <small>COD Fee: </small>
                                        <br/><span className='text-navy'>PHP {(reportsMediaBuyer.cod_fee || 0).toFixed(2)}</span>
                                    </li>
                                    <li>
                                        <small>VAT Fee: </small>
                                        <br/><span className='text-navy'>PHP {(reportsMediaBuyer.vat_fee || 0).toFixed(2)}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-sm-2">
                                <ul className="stat-list">
                                    <li>
                                        <small>Returned: </small>
                                        <br/><span className='text-navy'>PHP {(reportsMediaBuyer.returned_shipping_cost || 0).toFixed(2)}</span>
                                    </li>
                                    <li>
                                        <small>RTS Rate: </small>
                                        <br/><span className='text-navy'>{(reportsMediaBuyer.rts || 0).toFixed(2)}%</span>
                                    </li>
                                    <li style={{"display": hideMediaBuyerProfit?"block":"none"}}>
                                        <small>Product Cost ( PHP {reportsMediaBuyer.product_set_cost} ): <a href="#" onClick={ConfigModals} data-toggle="modal" data-target="#orderdashboardconfig">Set Product</a></small>
                                        <br/><span className='text-navy'>PHP {(reportsMediaBuyer.product_cost || 0).toFixed(2)}</span>
                                    </li>
                                    <li style={{"display": hideMediaBuyerProfit?"block":"none"}}>
                                        <small>Net Profit: </small>
                                        <br/><span className='text-navy'>PHP {(reportsMediaBuyer.net_profit || 0).toFixed(2)}</span>
                                    </li>

                                    <li style={{"display": hideMediaBuyerProfit?"block":"none"}}>
                                        <small>Share(20%): </small>
                                        <br/><span className='text-navy'>PHP {(reportsMediaBuyer.share_profit || 0).toFixed(2)}</span>
                                    </li>
                                    {/* <li style={{"display": hideMediaBuyerProfit?"block":"none"}}>
                                        <button className="form-control btn btn-primary" onClick={OpenSideBar}>Pay?</button>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='row'>
            <div className='col-sm-12 m-b-xs'>
                <div className="ibox">
                    <div className="ibox-content">
                        <div className="row">
                            <div className="col-sm-3 m-b-xs">
                                <label>Date Start</label>
                                <input type="date" id="product_name" name="product_name" value={startDate} onChange={(e)=>setStartDate(e.target.value)} placeholder="Product Name" className="form-control"/>
                            </div>
                            <div className="col-sm-3 m-b-xs">
                                <label>Date End</label>
                                <input type="date" id="product_name" name="product_name" value={endDate} onChange={(e)=>setEndDate(e.target.value)} placeholder="Product Name" className="form-control"/>
                            </div>
                            <div className="col-sm-3 m-b-xs">
                                <label>Status</label>
                                <select name="status" id="status" className="form-control" onChange={(e)=>setFilterStatus(e.target.value)}>
                                    <option value="" key={-1}>All</option>
                                    {
                                        statusList.map((statusItem: any, index: number)=>{
                                            return(<option value={statusItem.order_status} key={index}>{statusItem.order_status}</option>)
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-sm-3 m-b-xs">
                                <label>&nbsp;</label>
                                <button type="button" className="form-control btn btn-primary" onClick={filterByParameters}> <i className="fa fa-check-circle"></i> Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="row">
            <div className="col-lg-12">
                <div className="ibox float-e-margins">
                    <div className="ibox-title">
                        <h5>Orders </h5>
                        <span className="text-muted pull-right">
                            <button className='btn btn-info btn-xs' data-toggle="modal" data-target="#CustomColumnModal" onClick={SyncDeclare.Modals.CustomColumn}>
                                <i className="fa fa-cogs"></i>&nbsp;
                                Custom Column
                            </button>
                        </span>
                        {/* <div className="ibox-tools">
                            <a className="dropdown-toggle" data-toggle="dropdown" href="#">
                                <i className="fa fa-wrench"></i>
                            </a>
                            <ul className="dropdown-menu dropdown-user">
                                <li><a href="#">Config option 1</a>
                                </li>
                                <li><a href="#">Config option 2</a>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                    <div className={`ibox-content ${uploadState}`}>
                        <div className="sk-spinner sk-spinner-wave">
                            <div className="sk-rect1"></div>
                            <div className="sk-rect2"></div>
                            <div className="sk-rect3"></div>
                            <div className="sk-rect4"></div>
                            <div className="sk-rect5"></div>
                            Loading
                        </div>
                        <div className="row">
                            <div className="col-md-2 col-sm-3">
                                <div className="form-group">
                                    <select name="status" id="status" className="form-control input-sm" onChange={SearchByProduct}>
                                        <option value="" key={-1}>All</option>
                                        {
                                            itemList.map((productItem: any, index: number)=>{
                                                return(<option value={productItem.item_name} key={index}>{productItem.item_name}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-3">
                                <div className="form-group">
                                    <select name="status" id="status" className="form-control input-sm" onChange={SearchProductByUser}>
                                        <option value="" key={-1}>All</option>
                                        {
                                            teamListMember.map((member: any, index: number)=>{
                                                return(<option value={index} key={index}>{member.lastname} {member.firstname}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-2"></div>
                            <div className="col-md-4 col-sm-4">
                                <div className="input-group">
                                    <span className="input-group-btn">
                                        <select name="status" id="status" className="form-control input-sm" onChange={(e)=>setSearchBy(e.target.value)} style={{width: "140px", marginTop: "-5px"}}>
                                            <option value="">All</option>
                                            <option value="waybill_number">Waybill Number</option>
                                            <option value="receiver">Receiver</option>
                                            <option value="receiver_cellphone">Receiver Cellphone</option>
                                        </select>
                                    </span>
                                    {/* <input type="text" placeholder="Search" className="input-sm form-control" onChange={(e)=>setSearch(e.target.value)}/> */}
                                    <textarea className="input-sm form-control" onBlur={(e)=>setSearch((e.target.value || '').replace(/\n/g,'|'))} style={{"height":"30px","resize": "none"}}></textarea>
                                    <span className="input-group-btn">
                                        <button type="button" className="btn btn-sm btn-primary" onClick={LoadOrdersByWaybill}> Go!</button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                            Showing {(pageNumber*maxRows)+1} to {(pageNumber*maxRows)+OrderList.length} of {totalList} entries
                            <span className="text-muted pull-right">Date Uploaded: {uploadDate}</span>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                <tr key={-1}>
                                    <th key={-1}>#</th>
                                    {
                                        OrderColumns.map((col:string, index:any)=>{
                                            return(<th key={index}>{col}</th>)
                                        })
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        OrderList.map((order:any, index:any)=>{
                                            return(
                                                <tr key={index}>
                                                    <td>{(index+1)+(pageNumber*maxRows)}</td>
                                                    {
                                                        formatTD(order).map((object:any, i:any)=>{
                                                            return(<td data-toggle="tooltip" data-placement="left" title={object.column} key={i} data-placeholder={object.value}>{object.value}</td>)
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 m-b-xs">
                                <div className="btn-group pull-right">
                                    <button className="btn btn-white" type="button" onClick={()=>pagination('prev')}>Prev</button>
                                    <select className="btn btn-white" value={pageNumber} onChange={(e)=>pagination('selected', e.target.value)}>
                                        {
                                            paginationList.map((page:any, pageIndex:any)=>{
                                                return(<option value={page}>{(page+1)}</option>)
                                            })
                                        }
                                    </select>
                                    <button className="btn btn-white" type="button" onClick={()=>pagination('next')}>Next</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>




    </div>
  );
}

export default OrdersDashboard;
