import { Requests } from "../pages/module/Request";

export default class UpsellService{
    static add(shopid:Number,name:string){
        return Requests.Post('/upsell/add', {id: shopid, name}, true);
    }
    static getList(shopid:Number){
        return Requests.Get('/upsell/list?id='+shopid, true);
    }
    static remove(shopid:Number,hash:string){
        return Requests.Post('/upsell/remove', {id: shopid, hash}, true);
    }
    static getListByUser(shopid:Number,email:string){
        return Requests.Post('/upsell/list/user', {id: shopid, email}, true);
    }
}