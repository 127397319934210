import React, { useState, useEffect} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    NavLink
} from 'react-router-dom';
import RouterList from './pages/RouterList';
import Modals from './pages/Modals';
import SideBar from './sidebar';
import Assets from './pages/module/Assets';
import UsersService from './pages/module/Users.service';
import SyncDeclare from './SyncDeclare';
import uiHelper from './pages/module/uiHelper';
import ShopModals from './modal/ShopModal';
import ProfileModals from './modal/ProfileModal';
import CustomColumnModals from './modal/CustomColumn';
import OrderController from './controller/orderController';


function RouterApp() {
    // Declare Variable
    let templateRef:any;
    let [currentPath, setCurrentPath] = useState(window.location.pathname || '');
    let [profilePic, setProfilePic] = useState(Assets.defaultProfilePic);
    let [profilename, setProfilename] = useState('');
    let [permission, setPermission] = useState('2|');
    let [showSideBar, setShowSideBar] = useState(false);
    let currentTitle:string = '';
    let dashboardTitle:string = '';
    // Declare Functions
    function setActiveMenu(path:string, title:string){
        if(currentPath === path){
            currentTitle = "Tumia | "+title;
            dashboardTitle = title;
            return 'active';
        }else{
            return ''
        }
    }
    async function loadUser(){
        let users:any = await UsersService.getMe();
        setProfilename(users.firstname+' '+users.lastname);
        setPermission(users.permissionid);
        setProfilePic(uiHelper.assets.getProfilePic(users.profilePic,Assets.profileIcon));
        SyncDeclare.Users = users;
    }
    function checkPermission(PermissionNumber: Number){
        return permission.includes(PermissionNumber.toString()+'|');
    }
    function DoesHaveSub(list:any){
        return (list || []).length > 0;
    }
    function BackToShop(){
        localStorage.removeItem('shop-id');
        window.location.reload();
    }
    function Logout(){
        localStorage.removeItem('shop-id');
        localStorage.removeItem('token');
        window.location.reload();
    }
    function GoTo(path: string){
        window.location.href = path;
    }
    // Declare Hooks
    useEffect(() => {
        document.title = currentTitle;
        SyncDeclare.TemplateDom = templateRef;
        const ShopVariable:any = localStorage.getItem('shop-id');
        SyncDeclare.ShopVariable = JSON.parse(ShopVariable || '{}');
        loadUser();
    });
    // Template
    return(
    <Router>
        {/* wrapper */}
        <div id="wrapper" ref={(node:any)=> templateRef = node}>
            {/* navbar-default  */}
                <nav className="navbar-default navbar-static-side" role="navigation">
                    <div className="sidebar-collapse">
                        <ul className="nav metismenu" id="side-menu">
                            <li className="nav-header">
                                <div className="dropdown profile-element" onClick={()=>GoTo('/profile')}>
                                    <span>
                                        <img alt="Profile Picture" className="img-circle" src={profilePic} style={{"width":"100px"}}/>
                                    </span>
                                    <a data-toggle="dropdown" className="dropdown-toggle" href="#" aria-expanded="false">
                                        <span className="clear">
                                            <span className="block m-t-xs">
                                                <strong className="font-bold">{profilename}</strong>
                                            </span>
                                            <span className="text-muted text-xs block">Media Buyer</span>
                                        </span>
                                    </a>
                                </div>
                                <div className="logo-element">

                                </div>
                            </li>
                            {RouterList.map((route, index) => {
                                if(!route.hide && checkPermission(route.permission)){
                                    return <li className={setActiveMenu(route.path, route.title)} onClick={()=>setCurrentPath(window.location.pathname || '')} key={index}>
                                        <Link to={route.path}>
                                            <i className={route.icon}></i><span className="nav-label">{route.title}</span>
                                        </Link>
                                    </li>
                                }
                            })}
                        </ul>

                    </div>
                </nav>
                {/* end of navbar-default  */}

                {/* page wrapper */}
                <div id="page-wrapper" className="gray-bg activities">
                    <div className="row border-bottom">
                        <nav className="navbar navbar-static-top" role="navigation" style={{marginBottom: 0}}>
                            <div className="navbar-header" >
                                <a className="navbar-minimalize minimalize-styl-2 btn btn-info" href="#"><i className="fa fa-bars"></i> </a>
                            </div>
                            <ul className="nav navbar-top-links navbar-right">

                                <li className="dropdown">
                                    <a className="dropdown-toggle count-info" data-toggle="dropdown" href="#" aria-expanded="false">
                                        <i className="fa fa-bell"></i>  <span className="label label-primary">3</span>
                                    </a>
                                    <ul className="dropdown-menu dropdown-alerts">
                                        <li>
                                            <a href="#">
                                                <div>
                                                    <i className="fa fa-envelope fa-fw"></i> Added Multiple Search on Search Bar<br/>You can add multiple waybill by separating into newline.
                                                    <span className="pull-right text-muted small">Nov 24, 2022</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="divider"></li>
                                        <li>
                                            <a href="/profile">
                                                <div>
                                                    <i className="fa fa-envelope fa-fw"></i> You're now able to change password<br/>Click the grid icon & click your name or Click here
                                                    <span className="pull-right text-muted small">Nov 24, 2022</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="divider"></li>
                                        <li>
                                            <a href="#">
                                                <div>
                                                    <i className="fa fa-envelope fa-fw"></i> Added Custom Header<br/>You can now exclude a header for the Order List
                                                    <span className="pull-right text-muted small">Nov 24, 2022</span>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#" className="" onClick={BackToShop}>
                                        <i className="fa fa-shopping-cart"></i> Select Shop
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="logoutbtn" onClick={Logout}>
                                        <i className="fa fa-sign-out"></i> Log out
                                    </a>
                                </li>
                            </ul>

                        </nav>
                    </div>
                    <div className="row wrapper border-bottom white-bg page-heading" id="header-main">
                        <div className="col-lg-9">
                            <h2>{dashboardTitle} <span id="header-title"></span></h2>
                            <p id="header-description"></p>
                        </div>
                    </div>
                    <div className="wrapper wrapper-content">
                    <Switch>
                        {RouterList.map((route, index) => {
                            if(checkPermission(route.permission)){
                                return <Route key={index} exact path={route.path} component={route.source}></Route>
                            }
                        })}
                    </Switch>
                    </div>


                </div>
                {/* End of page-wrapper */}

                {/* Side bar */}
                <SideBar/>
                {/* End of Side Bar */}
        </div>
        {/* end of wrapper  */}
        {/* Modal Service */}
        <Modals/>
        <ShopModals/>
        <ProfileModals/>
        <CustomColumnModals/>
        {/* Modal Service End */}
    </Router>
    );
};
export default RouterApp;