import React, { useState, useEffect } from 'react';

// Static CSS
import '../App.css';

// Declare Modules
import Assets from './module/Assets';

import uiHelper from './module/uiHelper';
import SyncDeclare from '../SyncDeclare';
import ShopsService from './module/Shops.service';
import TeamsService from './module/Teams.service';
import OrdersService from './module/Orders.service';

function CompareDashboard() {
  // Static Assets
  const logo = Assets.logo;
  const Shop = SyncDeclare.ShopVariable;
  const skipColumn = ['id','shopid','creator_code'];
  const maxRows = 1000;
  const mediaBuyerShare = 20/100;
  const Pagination:any = {
    Index: 2,
    List: []
  };

  // Declare Variable
  let [OrderList,setOrderList]:any[] = useState([]);
  let [uploadState,setUploadState]:any = useState('');
  let [Store1List, setStore1List]:any = useState([]);
  let [Store2List, setStore2List]:any = useState([]);
  let mainRef:any;

  async function LoadOrders(shopid: Number, phoneNumbers:any[], itemname: string, datestart: string, dateend: string){
    setUploadState('sk-loading');

    setUploadState('');
  }
  function checkDuplicates(e:any){
    setUploadState('sk-loading');
    const dom:any = document.querySelector('#CompareDashboard');
    if(dom){
        let rawData1:any = dom.querySelector('textarea#store_1').value;
        let rawData2:any = dom.querySelector('textarea#store_2').value;
        let formatDataStore1:any = rawData1.split('\n');
        let formatDataStore2:any = rawData2.split('\n');
        if(formatDataStore1.length>0 && formatDataStore2.length>0){
            console.log(formatDataStore2,formatDataStore1)
            let dups1 = formatDuplicates(trimMultiplePhoneNumbers(formatDataStore2), trimMultiplePhoneNumbers(formatDataStore1));
            let dups2 = formatDuplicates(trimMultiplePhoneNumbers(formatDataStore1), trimMultiplePhoneNumbers(formatDataStore2));
            setStore1List(dups1);
            setStore2List(dups2);
        }
    }
    setUploadState('');
  }
  function formatDuplicates(store1:any, store2:any){
    let format = [];
    for(let store of store2){
        let dups:any = getDuplicates(store1, store);
        if(dups.length>=0){
            format.push({
                phone: store,
                duplicates: dups
            });
        }else{
            format.push({
                phone: store,
                duplicates: dups
            });
        }
    }
    return format;
  }
  function getDuplicates(store1:any, check:any){
    let duplicates = [];
    for(let store of store1){
        if(store === check){
            duplicates.push(store);
        }
    }
    return duplicates.length;
  }
  function trimMultiplePhoneNumbers(phones:any){
    let trim = []
    for(let phone of phones){
        trim.push(trimPhoneNumber(phone));
    }
    return trim;
  }
  function trimPhoneNumber(phone:any){
    return phone.replace(/\+63|\+0|\+| +/g,'');
  }
  function checkDuplicatesInStore1(){
    setUploadState('sk-loading');
    const dom:any = document.querySelector('#CompareDashboard');
    if(dom){
        let rawData1:any = dom.querySelector('textarea#store_1').value;
        let formatDataStore1:any = rawData1.split('\n');
        if(formatDataStore1.length>0){
            console.log(formatDataStore1);
            let dups1 = formatDuplicates(trimMultiplePhoneNumbers(formatDataStore1), trimMultiplePhoneNumbers(formatDataStore1));
            let getDups = [];
            let getDupsPhone = [];
            for(let row of dups1){
                if(row.duplicates>1 && getDupsPhone.indexOf(row.phone)<0){
                    getDups.push(row);
                    getDupsPhone.push(row.phone);
                }
            }
            setStore1List(getDups);
            setStore2List([]);
        }
    }
    setUploadState('');
  }
  // Declare Hooks
  useEffect(() => {
    // Load Function Once
    if(SyncDeclare.ShopVariable.shopid==0){
    //   window.location.href="/orders";
    }
    setTimeout(()=>{
        SyncDeclare.TemplateHeader('', '', false);
    },100)
  }, []);
  // Template
  return (
    <div className="" id="CompareDashboard" ref={(node:any)=> mainRef = node}>
        <div className='row'>
            <div className='col-sm-12 m-b-xs'>
                <div className="ibox">
                    <div className="ibox-content">
                        <div className='row'>
                            <div className='col-sm-12'>
                            <h2>Compare 2 Stores</h2>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-4' style={{height: "280px"}}>
                                <label>Store 1</label>
                                <textarea className='form-control' id="store_1" style={{"height": "90%"}}></textarea>
                            </div>
                            <div className='col-sm-4' style={{height: "280px"}}>
                                <label>Store 2</label>
                                <textarea className='form-control'id="store_2"  style={{"height": "90%"}}></textarea>
                            </div>


                            <div className="col-sm-4">
                                <div className="form-group">
                                    <br/>
                                    <p>Add your phone number in the <span className='text-warning'>text box</span>.
                                    <br/>Compare Store 1 to Store 2 by click Compare Button.
                                    <br/>You can do <span className='text-warning'>multiple</span> at once by adding the new phone number on the <span className='text-warning'>next line</span>.
                                    </p>
                                </div>
                                <button className='btn btn-primary' onClick={checkDuplicates}>Compare</button>
                                <div className="form-group">
                                    <p>
                                    <br/>Check duplicates in Store 1 text box.
                                    <br/>You can do <span className='text-warning'>multiple</span> at once by adding the new phone number on the <span className='text-warning'>next line</span>.
                                    </p>
                                </div>
                                <button className='btn btn-primary' onClick={checkDuplicatesInStore1}>Check Duplicates</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-6">
                <div className="ibox float-e-margins">
                    <div className="ibox-title">
                        <h5>Store 1</h5>
                    </div>
                    <div className={`ibox-content ${uploadState}`}>
                        <div className="sk-spinner sk-spinner-wave">
                            <div className="sk-rect1"></div>
                            <div className="sk-rect2"></div>
                            <div className="sk-rect3"></div>
                            <div className="sk-rect4"></div>
                            <div className="sk-rect5"></div>
                            Loading
                        </div>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                <tr key={-1}>
                                    <th key={0}>#</th>
                                    <th key={1}>Phone</th>
                                    <th key={2}>Instance(s)</th>
                                    <th key={3}>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        Store1List.map((store:any,storekey:any)=>{
                                            return(
                                                <tr key={storekey}>
                                                    <td style={{"color": "#9af381;!important"}}>{storekey+1}</td>
                                                    <td>{store.phone}</td>
                                                    <td>{store.duplicates}</td>
                                                    <td>{store.duplicates>0 ? <span className='duplicates'><i className="fa fa-check-circle"></i>&nbsp;Duplicates</span> : ""}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
            <div className="col-sm-6">
                <div className="ibox float-e-margins">
                    <div className="ibox-title">
                        <h5>Store 2</h5>
                    </div>
                    <div className={`ibox-content ${uploadState}`}>
                        <div className="sk-spinner sk-spinner-wave">
                            <div className="sk-rect1"></div>
                            <div className="sk-rect2"></div>
                            <div className="sk-rect3"></div>
                            <div className="sk-rect4"></div>
                            <div className="sk-rect5"></div>
                            Loading
                        </div>
                        <div className="table-responsive ">
                            <table className="table table-striped">
                                <thead>
                                <tr key={-1}>
                                    <th key={0}>#</th>
                                    <th key={1}>Phone</th>
                                    <th key={2}>Instance(s)</th>
                                    <th key={3}>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        Store2List.map((store:any,storekey:any)=>{
                                            return(
                                                <tr key={storekey}>
                                                    <td>{storekey+1}</td>
                                                    <td>{store.phone}</td>
                                                    <td>{store.duplicates}</td>
                                                    <td>{store.duplicates>0 ? <span className='duplicates'><i className="fa fa-check-circle"></i>&nbsp;Duplicates</span> : ""}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>




    </div>
  );
}

export default CompareDashboard;
