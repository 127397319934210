import React, { useState, useEffect } from 'react';

// Static CSS
import '../App.css';

// Declare Modules
import Assets from './module/Assets';

import SyncDeclare from '../SyncDeclare';
import uiHelper from './module/uiHelper';
import UsersService from './module/Users.service';
import ShopsService from './module/Shops.service';
import OrdersService from './module/Orders.service';
import PayoutService from './module/Payout.service';
import ShopAppService from '../services/shop-dashboard.service';
import { skipColumn } from '../utils/constant';
import OrderController from '../controller/orderController';

function Dashboard() {
  // Static Assets
  const logo = Assets.logo;
  const MaxRows = 1000;

  // Declare Variable
  let [TotalList, setTotalList]:any = useState(0);
  let [PageNumber, setPageNumber]:any = useState(0);
  let [UploadState, setUploadState]:any = useState('');
  let [UploadDate, setUploadDate]:any = useState('');
  let [PaginationList, setPaginationList]:any[] = useState([]);
  let [SearchBy, setSearchBy]:any = useState('');
  let [Search, setSearch]:any = useState('');
  let [SearchProduct, setSearchProduct]:any = useState('');
  let [FilterStatus, setFilterStatus]:any = useState('');
  let [StartDate, setStartDate]:any = useState('');
  let [EndDate, setEndDate]:any = useState('');
  let [Shop, setShop]:any = useState(SyncDeclare.ShopVariable);
  let [Data,SetData]:any[] = useState([]);
  let [ShopList,setShopList]:any[] = useState([]);
  let [ProductList,setProductList]:any[] = useState([]);
  let [StatusList,setStatusList]:any[] = useState([]);
  let [OrderList,setOrderList]:any[] = useState([]);
  let [mainDom, setMainDom]:any = useState();
  let [downloadLink,setDownloadLink]:any[] = useState('');
  let mainRef:any;
  let [TriggerOnce,SetTriggerOnce] = useState(true);

  function OpenSideBar(id:string){
    let scholar = Data.filter(function(s:any){return s.id === id});
    SyncDeclare.SideBar.LoadData(scholar[0]);
    // mainRef.
  }
  async function LoadUser(){
    // const users = new Users();
    // const users = await UsersService.getMe();
    await LoadCustomColumn();
    LoadShops();
  }
  async function LoadShops(){
    setUploadState('sk-loading');
    SyncDeclare.TemplateHeader('', '',false);
    Promise.allSettled([
        LoadProductList(Shop.shopid),
        LoadOrdersList(0,Shop.shopid),
        LoadReports(Shop,'',''),
        TotalOrders(Shop.shopid),
        getLastUploadDate(Shop.shopid),
    ]).then(([productList, orderList,loadReports,totals, uploaddate]) => {
        setUploadState('');
    }).catch((err) => {
        uiHelper.toastr.error(err.message);
    });
  }
  async function LoadReports(shop:any,startDate:any, endDate:any){
    // const total:any = await OrdersService.getReports(shop.shopid, '', startDate, endDate);
    // const reportsTotal:any = await OrdersService.getReportsTotal(shop.shopid,'','', '');
    // const reports:any = await PayoutService.getReportsWithPaidByUser(shop.shopid,'',startDate, endDate);
    Promise.all([
        OrdersService.getReports(shop.shopid, SearchProduct, startDate, endDate),
        OrdersService.getReportsTotal(shop.shopid,SearchProduct,startDate, endDate),
        PayoutService.getReportsWithPaidByUser(shop.shopid,SearchProduct,startDate, endDate)
    ]).then(([total, reportsTotal, reports]:any) => {
        setUploadState('');
        let labels = [];
        let values = [];
        for(let obj of reportsTotal){
            if(obj.order_status){
                labels.push(obj.order_status);
                values.push(obj.order_total);
            }
        }
        setStatusList(labels);
        let dom:any = document;
        dom.querySelector('#dashboard_delivered_value').innerHTML = (total.delivered_value || 0).toFixed(2);
        dom.querySelector('#dashboard_delivered_shipping_cost').innerHTML = (total.delivered_shipping_cost || 0).toFixed(2);
        dom.querySelector('#dashboard_cod_fee').innerHTML = (total.cod_fee || 0).toFixed(2);
        dom.querySelector('#dashboard_vat_fee').innerHTML = (total.vat_fee || 0).toFixed(2);
        dom.querySelector('#dashboard_returned_shipping_cost').innerHTML = (total.returned_shipping_cost || 0).toFixed(2);

        let Total_Data = reports[0] || [];
        let Paid_Data = reports[1] || [];
        let Raw_Delivered_Total = 0, Raw_Returned_Total = 0,Paid_Delivered_Total = 0, Paid_Returned_Total = 0;
        for(let rawTotal of Total_Data){
            if(rawTotal.order_status === 'Delivered'){
                Raw_Delivered_Total = rawTotal.order_total;
            }else if(['For Return','Returned'].indexOf(rawTotal.order_status)>=0){
                Raw_Returned_Total += rawTotal.order_total;
            }
        }
        for(let rawTotal of Paid_Data){
            if(rawTotal.order_status === 'Delivered'){
                Paid_Delivered_Total = rawTotal.order_total;
            }else if(['For Return','Returned'].indexOf(rawTotal.order_status)>=0){
                Paid_Returned_Total += rawTotal.order_total;
            }
        }
        let Delivered_Except_For_Paid = Raw_Delivered_Total - Paid_Delivered_Total;
        let Returned_Except_For_Paid = Raw_Returned_Total - Paid_Returned_Total;
        let RTS_Except_For_Paid = (Returned_Except_For_Paid / (Delivered_Except_For_Paid+Returned_Except_For_Paid)) * 100;
        dom.querySelector('#dashboard_rts').innerHTML = (total.rts || 0).toFixed(2) + "%";
        dom.querySelector('#dashboard_rts_1').innerHTML = (RTS_Except_For_Paid || 0).toFixed(2) + "%";
        LoadBarChart(reports[0],reports[1]);
    }).catch((err) => {
        uiHelper.toastr.error(err.message);
    });
    // Promise.all([
    //     ShopAppService.dashboardClient(shop.shopid, '', startDate, endDate)
    // ]).then(([client]:any) => {
    //     console.log(client);
    //     setUploadState('');
    //     let reportsTotal = client.order;
    //     let total = client.report;
    //     let reports = client.paid;
    //     let uploadDate = client.shop.uploadDttm;
    //     let labels = [];
    //     let values = [];
    //     for(let obj of reportsTotal){
    //         if(obj.order_status){
    //             labels.push(obj.order_status);
    //             values.push(obj.order_total);
    //         }
    //     }
    //     setStatusList(labels);
    //     if(uploadDate.length>0){
    //         setUploadDate(uiHelper.format.fullDateWithTime(uploadDate));
    //       }else{
    //         setUploadDate('');
    //       }
    //     let dom:any = document;
    //     dom.querySelector('#dashboard_delivered_value').innerHTML = (total.delivered_value || 0).toFixed(2);
    //     dom.querySelector('#dashboard_delivered_shipping_cost').innerHTML = (total.delivered_shipping_cost || 0).toFixed(2);
    //     dom.querySelector('#dashboard_cod_fee').innerHTML = (total.cod_fee || 0).toFixed(2);
    //     dom.querySelector('#dashboard_vat_fee').innerHTML = (total.vat_fee || 0).toFixed(2);
    //     dom.querySelector('#dashboard_returned_shipping_cost').innerHTML = (total.returned_shipping_cost || 0).toFixed(2);
    //     LoadBarChart(reports[0],reports[1]);
    // }).catch((err) => {
    //     uiHelper.toastr.error(err.message);
    // });
    // uiHelper.barChart(dom.querySelector('#dashboard_barChart'), labels, values);
  }
  async function LoadOrdersList(pageIndex:Number, shopid:Number){
    setOrderList([])
    const orders:any = await OrdersService.listByUser(SearchProduct, FilterStatus, SearchBy, Search, shopid, pageIndex, MaxRows, StartDate, EndDate, 'true');
    if(orders.length>0){
      setOrderList(orders);
    }
    return true;
  }
  async function LoadProductList(shopid:Number){
    const items:any = await OrdersService.listItemsByUser(shopid);
    setProductList(items);
  }
  async function getLastUploadDate(shopid:Number){
    const uploadDate:any = await ShopsService.getUploadDate(shopid);
    if(uploadDate.length>0){
      setUploadDate(uiHelper.format.fullDateWithTime(uploadDate));
    }else{
      setUploadDate('');
    }
  }
  async function FilteredSearch(e:any){
    setUploadState('sk-loading');
    setPageNumber(0);
    const dom:any = document;
    const startDate = dom.getElementById('filter_startdate').value || '';
    const endDate = dom.getElementById('filter_enddate').value || '';
    if(startDate.length>0 || endDate.length>0){
        Promise.all([
            LoadReports(Shop,startDate,endDate),
            LoadOrdersList(0,Shop.shopid),
            TotalOrders(Shop.shopid),
        ]).then(([total, reportsTotal, overAll]:any) => {
            setUploadState('');
        }).catch((err) => {
            uiHelper.toastr.error(err.message);
        });
    }else{
        Promise.allSettled([
            LoadOrdersList(0,Shop.shopid),
            TotalOrders(Shop.shopid),
            LoadReports(Shop,startDate,endDate),
        ]).then(([reportsTotal, overAll,reports]:any) => {
            setUploadState('');
        }).catch((err) => {
            uiHelper.toastr.error(err.message);
        });
    }
  }
  async function FilteredSearchByMulti(e:any){
    setUploadState('sk-loading');
    setPageNumber(0);
    await LoadOrdersList(0,Shop.shopid);
    await TotalOrders(Shop.shopid);
    setUploadState('');
  }
  async function TotalOrders(shopid:Number){
    const total:any = await OrdersService.listTotalByUser('',SearchProduct, FilterStatus, SearchBy, Search, shopid, StartDate, EndDate);
    setTotalList(total);
    let List = [];
    let pages = (Number(total)/MaxRows);
    for(let page = 0; page<(pages);page++){
        List.push(page);
    }
    setPaginationList(List);
    return true;
  }
  async function Pagination(status:string, value?:any){
    setUploadState('sk-loading');
    let page = 0;
    if(status=='next'){
        page = parseInt(PageNumber) + 1;
        if(page<PaginationList.length){
            setPageNumber(page);
        }else{
            page = PageNumber;
        }
        await LoadOrdersList(page*MaxRows,Shop.shopid);
    }else if(status=='prev'){
        page = 0;
        if(PageNumber>=0){
            page = PageNumber - 1;
            setPageNumber(page);
        }
        await LoadOrdersList(page*MaxRows,Shop.shopid);
    }else if(status=='selected'){
        setPageNumber(value);
        page = parseInt(value);
        await LoadOrdersList(page*MaxRows,Shop.shopid);
    }
    setUploadState('');
  }
  function FormatRow(row:any){
    let formatRow = [];
    let CustomHeader:any[] = SyncDeclare.CustomHeader;
    for(const column in row){
        if(skipColumn.indexOf(column)<0 && !CustomHeader.includes(column)){
            if(['createdAt','updatedAt'].indexOf(column)>=0){
                formatRow.push({column, value: row[column] != null ? uiHelper.format.fullDateWithTime(row[column]) : ""});
            }else{
                formatRow.push({column, value: row[column]});
            }
        }
    }
    return formatRow;
  }
  function FormatHeaders(row:any){
    let formatRow = [];
    let CustomHeader:any[] = SyncDeclare.CustomHeader;
    if(row.length>0){
        let row1 = row[0]
        for(const column in row1){
            if(skipColumn.indexOf(column)<0 && !CustomHeader.includes(column)){
                formatRow.push(column);
            }
        }
    }
    return formatRow;
  }
  function LoadBarChart(reports1:any, reports2:any){
    let format1 = PrepareValueForBarChart(reports1);
    let format2 = PrepareValueForBarChart(reports2);
    let format2New = [];
    for(let data of format1.labels){
        let column = format2.labels;
        let values = format2.values;
        let index = column.indexOf(data);
        if(index>=0){
            format2New.push(values[index]);
        }else{
            format2New.push(0);
        }
    }
    let dom:any = document.querySelector('#Dashboard');
    uiHelper.barChart2(dom.querySelector('#dashboard_barChart'), format1.labels, format1.values, format2New);
  }
  function PrepareValueForBarChart(reports:any[]){
    let labels = [];
    let values = [];
    for(let obj of reports){
        if(obj.order_status){
            labels.push(obj.order_status);
            values.push(obj.order_total);
        }
    }
    return {labels,values};
}
async function LoadCustomColumn(){
    if(SyncDeclare.CustomHeader.length>0) return; 
    const response:any = await OrderController.getCustomHeader();
    if(response.custom_column){
        const format = JSON.parse(response.custom_column);
        SyncDeclare.CustomHeader = format;
    }
}
async function exportToXLSX(e:any){
    e.preventDefault();
    setDownloadLink('');
    setUploadState('sk-loading');
    const dom:any = document;
    const Users:any = SyncDeclare.Users || {};
    const startDate = dom.getElementById('filter_startdate').value || '';
    const endDate = dom.getElementById('filter_enddate').value || '';
    if(startDate.length>0 || endDate.length>0){
        let exportResponse:any = await ShopsService.exportOrder(Shop.shopid,FilterStatus,Users.email || '',SearchProduct,0,startDate,endDate);
        if(exportResponse.status == 'success'){
          uiHelper.toastr.success('File Created.');
          setDownloadLink('https://admin.tumia.shop/client/exports/'+exportResponse.file);
          console.log('File Link: https://admin.tumia.shop/client/exports/'+exportResponse.file);
        }else{
          uiHelper.toastr.error('Unable to create the file. '+exportResponse.message)
        }
    }else{
      uiHelper.toastr.info('Unable to export. Must fill the requirements.');
    }
    setUploadState('');
  }
  // Declare Hooks
  useEffect(() => {
    // Load Function Once
    // setMainDom(document.getElementById('Dashboard'));
    // LoadData();
    LoadUser();
  }, []);
  // Template
  return (
    <div className="" id="Dashboard" ref={(node:any)=> mainRef = node}>
      <div className='row'>
        <div className='col-sm-12 m-b-xs'>
            <div className="ibox">
                <div className={`ibox-content ${UploadState}`}>
                    <div className="sk-spinner sk-spinner-wave">
                        <div className="sk-rect1"></div>
                        <div className="sk-rect2"></div>
                        <div className="sk-rect3"></div>
                        <div className="sk-rect4"></div>
                        <div className="sk-rect5"></div>
                        Loading
                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                        <h2>Shop Reports - {Shop.name}</h2>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-8' style={{height: "200px"}}>
                            <canvas id="dashboard_barChart" height="100"></canvas>
                        </div>
                        <div className="col-sm-2">
                            <ul className="stat-list">
                                <li>
                                    <small>Delivered Value: </small>
                                    <br/><span className='text-navy' id="dashboard_delivered_value">PHP 0.00</span>
                                </li>
                                <li>
                                    <small>Total Shipping Cost: </small>
                                    <br/><span className='text-navy' id="dashboard_delivered_shipping_cost">PHP 0.00</span>
                                </li>
                                <li>
                                    <small>COD Fee: </small>
                                    <br/><span className='text-navy' id="dashboard_cod_fee">PHP 0.00</span>
                                </li>
                                <li>
                                    <small>VAT Fee: </small>
                                    <br/><span className='text-navy' id="dashboard_vat_fee">PHP 0.00</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-2">
                            <ul className="stat-list">
                                <li>
                                    <small>Returned: </small>
                                    <br/><span className='text-navy' id="dashboard_returned_shipping_cost">PHP 0.00</span>
                                </li>
                                <li>
                                    <small>RTS Rate: </small>
                                    <br/><span className='text-navy' id="dashboard_rts">0%</span>
                                </li>
                                <li>
                                    <small>RTS Rate (Without Paid): </small>
                                    <br/><span className='text-navy' id="dashboard_rts_1">0%</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div className='row' style={{"display":"block"}}>
            <div className='col-sm-12 m-b-xs'>
                <div className="ibox">
                    <div className="ibox-content">
                        <div className="row">
                            
                            <div className="col-sm-2 m-b-xs">
                                <label>Product</label>
                                <select name="product" id="filter_product" className="form-control input-sm" onChange={(e)=>setSearchProduct(e.target.value)}>
                                <option value="" key={-1}>All</option>
                                  {
                                      ProductList.map((shop: any, shopIndex: number)=>{
                                          return(<option value={shop.item_name} key={shopIndex}>{shop.item_name}</option>)
                                      })
                                  }
                                  </select>
                            </div>
                            <div className="col-sm-2 m-b-xs">
                                <label>Date Start</label>
                                <input type="date" id="filter_startdate" name="filter_startdate" onChange={(e)=>setStartDate(e.target.value)} placeholder="Start Date" className="form-control"/>
                            </div>
                            <div className="col-sm-2 m-b-xs">
                                <label>Date End</label>
                                <input type="date" id="filter_enddate" name="filter_enddate" onChange={(e)=>setEndDate(e.target.value)} placeholder="End Date" className="form-control"/>
                            </div>
                            <div className="col-sm-2 m-b-xs">
                                <label>Status</label>
                                <select name="status" id="filter_status" className="form-control input-sm" onChange={(e)=>setFilterStatus(e.target.value)}>
                                  <option value="" key="">All</option>
                                  {
                                      StatusList.map((status: any, statusIndex: number)=>{
                                          return(<option value={status} key={statusIndex}>{status}</option>)
                                      })
                                  }
                                  </select>
                            </div>
                            <div className="col-sm-2 m-b-xs">
                                <label>&nbsp;</label>
                                <button type="button" className="form-control btn btn-primary" onClick={FilteredSearch}> <i className="fa fa-check-circle"></i> Filter</button>
                            </div>
                            <div className="col-sm-2 m-b-xs text-center">
                                <label>&nbsp;</label>
                                <button type="button" className="form-control btn btn-outline btn-info" onClick={exportToXLSX}> <i className="fa fa-cloud-download"></i> Export</button><br/>
                                {
                                    downloadLink.length>0 ? 
                                    <a href={downloadLink} target="blank">Download Export</a> : ''
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                  <h5>Orders </h5>
                  <span className="text-muted pull-right">
                        <button className='btn btn-info btn-xs' data-toggle="modal" data-target="#CustomColumnModal" onClick={SyncDeclare.Modals.CustomColumn}>
                            <i className="fa fa-cogs"></i>&nbsp;
                            Custom Column
                        </button>
                    </span>
              </div>
              <div className={`ibox-content ${UploadState}`}>
                <div className="sk-spinner sk-spinner-wave">
                    <div className="sk-rect1"></div>
                    <div className="sk-rect2"></div>
                    <div className="sk-rect3"></div>
                    <div className="sk-rect4"></div>
                    <div className="sk-rect5"></div>
                    Loading
                </div>
                <div className='row'>
                  <div className="col-md-4 col-sm-4"></div>
                  <div className="col-md-4 col-sm-4"></div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-group">
                        <span className="input-group-btn">
                            <select name="status" className="form-control input-sm" onChange={(e)=>setSearchBy(e.target.value)} style={{width: "140px", marginTop: "-5px"}}>
                                <option value="">All</option>
                                <option value="waybill_number">Waybill Number</option>
                                <option value="receiver">Receiver</option>
                                <option value="receiver_cellphone">Receiver Cellphone</option>
                            </select>
                        </span>
                        {/* <input type="text" placeholder="Search" className="input-sm form-control" onChange={(e)=>setSearch(e.target.value)}/> */}
                        {/* <textarea placeholder="Search" className="input-sm form-control multi-input"></textarea> */}
                        <textarea className="input-sm form-control" onBlur={(e)=>setSearch((e.target.value || '').replace(/\n/g,'|'))} style={{"height":"30px","resize": "none"}}></textarea>
                        <span className="input-group-btn">
                            <button type="button" className="btn btn-sm btn-primary" onClick={FilteredSearchByMulti}> Go!</button>
                        </span>
                    </div>
                </div>
                </div>
                <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                    Showing {(PageNumber*MaxRows)+1} to {(PageNumber*MaxRows)+OrderList.length} of {TotalList} entries
                  <span className="text-muted pull-right">Date Uploaded: {UploadDate}</span>
                </div>
                {/* Start */}
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className="table-responsive custom-table">
                        <table className="table table-striped">
                            <thead>
                            <tr key={-1}>
                                <th key={-1}>#</th>
                                {
                                  FormatHeaders(OrderList).map((col:string, index:any)=>{
                                      return(<th key={index}>{col}</th>)
                                  })
                              }
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    OrderList.map((order:any, index:any)=>{
                                        return(
                                            <tr key={index}>
                                                <td>{(index+1)+(PageNumber*MaxRows)}</td>
                                                {
                                                    FormatRow(order).map((object:any, i:any)=>{
                                                        return(<td data-toggle="tooltip" data-placement="left" title={object.column} key={i} data-placeholder={object.value}>
                                                            {object.column === 'waybill_number' ? <a href={"https://www.jtexpress.ph/trajectoryQuery?flag=1&waybillNo="+object.value} target="_blank">{object.value}</a> : object.value}
                                                        </td>)
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                  </div>
                </div>
                {/* End */}
                {/* Start */}
                <div className="row">
                    <div className="col-sm-12 m-b-xs">
                        <div className="btn-group pull-right">
                            <button className="btn btn-white" type="button" onClick={()=>Pagination('prev')}>Prev</button>
                            <select className="btn btn-white" value={PageNumber} onChange={(e)=>Pagination('selected', e.target.value)}>
                                {
                                    PaginationList.map((page:any, pageIndex:any)=>{
                                        return(<option value={page}>{(page+1)}</option>)
                                    })
                                }
                            </select>
                            <button className="btn btn-white" type="button" onClick={()=>Pagination('next')}>Next</button>
                        </div>
                    </div>
                </div>
                {/* End */}
              </div>
            </div>
          </div>
        </div>




    </div>
  );
}

export default Dashboard;
