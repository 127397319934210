const logo = 'assets/img/main-logo.png';
const defaultProfilePic = 'assets/img/profile_small_custom.jpg';
const defaultProfilePicBig = 'assets/img/profile_big_custom.jpg';
const SLPLogo = 'assets/img/slp.png';
const ManIcon = 'assets/img/man.png';
const profileIcon = 'assets/img/profile-man.png';
const teamIcon = 'assets/img/team.png';

const Assets = {logo,defaultProfilePic,SLPLogo,defaultProfilePicBig,ManIcon,profileIcon,teamIcon};

export default Assets;