import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Shop_App from './shop/dashboard';

function ShopScreen(){
  const show = localStorage.getItem('shop-id');
  return show ? true : false;
}
ReactDOM.render(
  <React.StrictMode>
    {
      ShopScreen() ?
      <App /> :
      <Shop_App />
    }
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
