

// Static CSS
import { useEffect, useState } from 'react';
import '../App.css';
import SyncDeclare from '../SyncDeclare';
import ShopsService from '../pages/module/Shops.service';
import uiHelper from '../pages/module/uiHelper';
import UsersService from '../pages/module/Users.service';
import OrderController from '../controller/orderController';
import { skipColumn } from '../utils/constant';

function CustomColumnModals() {
    // Set Variables
    const Shop = SyncDeclare.ShopVariable;
    let User = {};
    let [Headers,setHeaders]:any = useState([]);
    let [UserHeaders,setUserHeaders]:any = useState([]);
    // Static Assets

    async function CustomColumn(){
        User = SyncDeclare.Users;
        LoadCustomColumn();
        if(Headers.length===0){
            setHeaders(await OrderController.getHeader());
        }
    }
    async function LoadCustomColumn(){
        if(SyncDeclare.CustomHeader.length>0) {
            setUserHeaders(SyncDeclare.CustomHeader);
            return;
        } 
        const response:any = await OrderController.getCustomHeader();
        if(response.custom_column){
            const format = JSON.parse(response.custom_column);
            setUserHeaders(format);
            SyncDeclare.CustomHeader = format;
        }
    }
    async function addHeader(e:any){
        const dom:any = document.getElementById('CustomColumnModal');
        let getSkipColumn = [];
        for(let list of Headers){
            if(dom.querySelector('#custom_column_'+list+':checked')){
                getSkipColumn.push(list);
            }
        }
        if(getSkipColumn.length>0){
            const response:any = await OrderController.addHeader(getSkipColumn);
            if(response === 0){
                uiHelper.toastr.error('Something went wrong.');
            }
            else{
                setUserHeaders(getSkipColumn);
                uiHelper.toastr.success('Custom Headers has been saved.');
            }
        }else{
            uiHelper.toastr.error('Something went wrong.');
        }
    }
    SyncDeclare.Modals.CustomColumn = CustomColumn;
    // Declare Hooks
    useEffect(() => {
        // Load Function Once
        setTimeout(()=>{

        },100)
    },[]);
  // Template
  return (
    <div id="modalCustomColumn">
        <div className="modal inmodal fade" id="CustomColumnModal" role="dialog"  aria-hidden="true">
            <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                            <h2>Custom Column</h2>
                        </div>
                        <div className="modal-body">
                            <h4>Order Fields to exclude</h4>
                            <div className='row'>
                                {
                                    Headers.map((header:any,headerKey:any)=>{
                                        if(!skipColumn.includes(header))
                                        return(
                                            <div className='col-sm-4' key={headerKey}>
                                                    <label><input type="checkbox" id={"custom_column_"+header} defaultChecked={UserHeaders.includes(header)}/>&nbsp;{header}</label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-white" data-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-success" onClick={addHeader}>Confirm</button>
                        </div>
                    </div>
            </div>
        </div>
    </div>
  );
}

export default CustomColumnModals;
