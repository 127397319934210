import React, { useState, useEffect } from 'react';

// Static CSS
import '../App.css';

// Declare Modules
import Assets from './module/Assets';

import uiHelper from './module/uiHelper';
import SyncDeclare from '../SyncDeclare';
import { useHistory } from 'react-router-dom';

function TemplateDashboard() {
  // Static Assets
  const forms = [
    {
        name: 'FDA LTO v1',
        file: 'fda.v0.html',
        fields: ['lto_number','label_text','type_text','establishment','address','owner','company_website','authorized','date_valid','date_issued','receipt_number','amount']
    },
    {
        name: 'FDA LTO v2',
        file: 'fda.v1.html',
        fields: ['lto_number','label_text','address','license_number','owner','date_valid']
    },
    {
        name: 'DTI LTO v1',
        file: 'dti.v0.html',
        fields: ['product_name','municipality','owner','address','date_valid','date_issued','certificate_number','trn_number']
    },
    {
        name: 'Permit v1',
        file: 'permit.v0.html',
        fields: ['permit_number','name','business_plate_no','trade_name','address_establishment','pin','bin','business_name','business_amount','date_expires','date_issued']
    },
  ];
  const history = useHistory();

  // Declare Variable
  let [loading, setLoading]:any = useState('sk-loading');
  let [TemplateBody, setTemplateBody]:any[] = useState('');
  let [InputFields, setInputFields]:any[] = useState([]);
  let mainRef:any;

  function currencyFormat(num:any) {
    return 'PHP ' + (Number(num || 0).toFixed(2)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  function GenerateTemplate(e:any){
    setLoading('sk-loading');
    let dom = e.target.parentNode.parentNode;
    if(dom && InputFields.length>0){
        console.log(dom,InputFields)
        let format_body:any = {};
        for(let field of InputFields){
            format_body[field] = GetInputValue(dom,'[name="'+field+'"]');
        }
        let template_key = GetInputValue(dom,'#template_body');
        let filename = forms[template_key].file;
        setTemplateBody(filename);
        localStorage.setItem('template_data',JSON.stringify(format_body));
        let templateDom:any = document.getElementById('template-fda');
        templateDom.src = "https://admin.tumia.shop/client/template/"+filename;
        // templateDom.contentWindow.location.reload();
    }
    setLoading('');
  }
  function ViewOnTab(){
    console.log('https://admin.tumia.shop/client/template/'+TemplateBody);
    var winPrint:any = window.open('https://admin.tumia.shop/client/template/'+TemplateBody, '');
    winPrint.print();
    // winPrint.close();
  }
  function GetInputValue(dom:any, id:string){
    return dom.querySelector(id).value
  }
  function FormTypeChange(e:any){
    setLoading('sk-loading');
    const key = e.target.value;
    if(key>=0){
        setInputFields(forms[key].fields);
    }else{
        uiHelper.toastr.info('Please select a form.')
    }
  }
  // Declare Hooks
  useEffect(() => {
    // verify id
    if(SyncDeclare.ShopVariable.shopid==0){
    //   window.location.href="/payout";
    }
    // Load Function Once
  }, []);
  // Template
  return (
    <div className="" id="PayoutDashboard" ref={(node:any)=> mainRef = node}>
      {/* start */}
      <div className="row">
        <div className="col-sm-4">
            {/* start ibox */}
            <div className="ibox float-e-margins">
                <div className="ibox-title">
                    <h5>Template Information</h5>
                </div>
                <div className="ibox-content">

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label>Template</label>
                                <select className='form-control' id="template_body" onChange={FormTypeChange}>
                                    <option value="-1">Select a Form</option>
                                    {
                                        forms.map((form,formKey)=>{
                                            return(<option value={formKey} key={formKey}>{form.name}</option>)
                                        })
                                    }
                                </select>
                            </div>
                            {
                                InputFields.map((field:any, fieldKey:any)=>{
                                    return(
                                        field.includes('date') ?
                                        <div className="form-group" key={fieldKey}>
                                            <label>{field}</label>
                                            <input type="date" name={field} className="form-control"/>
                                        </div>
                                        : <div className="form-group" key={fieldKey}>
                                            <label>{field}</label>
                                            <input type="text" name={field} className="form-control"/>
                                        </div>
                                    )
                                })

                            }
                            <div className="form-group">
                                <label>&nbsp;</label>
                                <button type="button" onClick={GenerateTemplate} className="btn btn-primary"> <i className="fa fa-check-circle"></i> Generate</button>&nbsp;
                                <button type="button" onClick={ViewOnTab} className="btn btn-info"> <i className="fa fa-print"></i> Print</button>
                            </div>
                        </div>
                </div>
                </div>
            </div>
            {/* end ibox */}
        </div>
        <div className="col-sm-8">
            <div className="ibox float-e-margins">
                <div className="ibox-title">
                    <h5>Template View</h5>
                </div>
                <div className={`ibox-content ${loading}`}>
                    <div className="sk-spinner sk-spinner-wave">
                        <div className="sk-rect1"></div>
                        <div className="sk-rect2"></div>
                        <div className="sk-rect3"></div>
                        <div className="sk-rect4"></div>
                        <div className="sk-rect5"></div>
                        Loading
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <iframe id="template-fda" src="" style={{"width":"100%","height": "100vh"}}></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      {/* end */}
    </div>
  );
}

export default TemplateDashboard;
